import { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { File, getContentsUrl } from "src/lib/file";
import { Box, useMediaQuery } from "@mui/system";
import { renderAsync } from "docx-preview";
import { theme } from "src/lib/theme";

type MailFileDialogProps = {
  file: File;
  open: boolean;
  onClose: () => void;
};

async function getDocx(file: File) {
  const response = await fetch(getContentsUrl(file));
  return await response.blob();
}

export function DocxViewerDialog(props: MailFileDialogProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getDocx(props.file).then((blob) => {
      renderAsync(blob, containerRef.current!);
    });
  }, [props.file, containerRef.current]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullScreen={isMobile}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          md: { height: "calc(100% - 100px)" },
          sx: isMobile
            ? {
                borderRadius: 0,
              }
            : {},
        }}
        scroll="paper"
      >
        <DialogTitle
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            bgcolor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          {props.file.name}
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              color: "primary.contrastText",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            overflow: "scroll",
            display: "flex",
            justifyContent: "center",
            bgcolor: "gray",
          }}
        >
          <div style={{ display: "grrid", width: "100%" }} ref={containerRef} />
        </DialogContent>
      </Dialog>
    </>
  );
}
