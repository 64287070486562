import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { FilesQueryKey } from "./useFiles";

type useUpdateFileSharesOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseUpdateFileSharesParams = {
  id: number;
  users: Array<{
    id: number;
  }>;
};

export function useUpdateFileShares(options: useUpdateFileSharesOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...params }: UseUpdateFileSharesParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/${id}/shares`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: JSON.stringify(snakecaseKeys(params, { deep: true })),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update file shares.");
      }

      // NOTE: This endpoint does not return anything
      // const data = await response.json();
      return camelcaseKeys({}, { deep: true });
    },
    {
      ...options,
      onSuccess: (data) => {
        queryClient.invalidateQueries(FilesQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
