type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
};

export function ConditionalWrapper(props: ConditionalWrapperProps) {
  return props.condition ? (
    props.wrapper(props.children)
  ) : (
    <>{props.children}</>
  );
}
