import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { UsersQueryKey } from "./useUsers";
import { UserRole } from "src/lib/user";
import { UserQueryKey } from "./useUser";

type UseUpdateUserOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseCreateUserParams = { id: number } & Partial<{
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  role: UserRole;
}>;

export function useUpdateUser(options: UseUpdateUserOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation<UseCreateUserParams, Error, UseCreateUserParams>(
    async ({ id, ...params }: UseCreateUserParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(snakecaseKeys(params, { deep: true })),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update user.");
      }

      const data = await response.json();
      return camelcaseKeys(data.user, { deep: true });
    },
    {
      ...options,
      onSuccess: (data) => {
        queryClient.invalidateQueries(UsersQueryKey);
        queryClient.invalidateQueries([UserQueryKey, data.id]);
        options.onSuccess?.();
      },
    }
  );
}
