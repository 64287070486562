import { useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";

export const WorksiteStatisticsQueryKey = "worksiteStatistics";

type WorksiteStatistics = {
  total: number;
  year: number;
};

async function getWorksiteStatistics(worksiteId: number) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/worksites/${worksiteId}/statistics`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("failed to fetch worksite statistics.");
  }

  return camelcaseKeys(await response.json(), { deep: true });
}

export function useWorksiteStatistics(worksiteId: number) {
  return useQuery<WorksiteStatistics[]>(
    [WorksiteStatisticsQueryKey, worksiteId],
    () => getWorksiteStatistics(worksiteId)
  );
}
