import {
  DocumentMimeTypes,
  EmailMimeTypes,
  ImageMimeTypes,
} from "./mime-types";

export type File = {
  id: number;
  name: string;
  url?: string;
  mimeType: string | null;
  color?: string;
  createdAt: Date;
  isFolder: boolean;
  parentId?: number;
};

export type FileWithShares = File & {
  shares: Array<{ userId: number }>;
};

export const FileType = {
  FILE: "FILE",
  FOLDER: "FOLDER",
  URL: "URL",
} as const;

export const colors = [
  "#706b6b",
  "#c13129",
  "#36911f",
  "#045ac4",
  "#830caa",
  "#df981e",
  "#0bbfbc",
];

export function isFile(file: Pick<File, "mimeType">) {
  return !!file.mimeType;
}

export function isFolder(file: Pick<File, "mimeType" | "url">) {
  return !file.url && !file.mimeType;
}

export function isLink(file: Pick<File, "url">) {
  return !!file.url;
}

export function isPDF(file: Pick<File, "mimeType">) {
  return file.mimeType === "application/pdf";
}

export function isVideo(file: Pick<File, "mimeType">) {
  return (
    file.mimeType &&
    file.mimeType.startsWith("video/") &&
    !file.mimeType.startsWith("video/x-msvideo")
  );
}

export function isImage(file: Pick<File, "mimeType">) {
  return (
    file.mimeType &&
    ImageMimeTypes.includes(file.mimeType as (typeof ImageMimeTypes)[number])
  );
}

export function isEmail(file: Pick<File, "mimeType">) {
  return (
    file.mimeType &&
    EmailMimeTypes.includes(file.mimeType as (typeof EmailMimeTypes)[number])
  );
}

export function isDocument(file: Pick<File, "mimeType">) {
  return (
    file.mimeType &&
    DocumentMimeTypes.includes(
      file.mimeType as (typeof DocumentMimeTypes)[number]
    )
  );
}

export function getDownloadUrl(
  file: Pick<File, "id" | "name" | "url" | "mimeType">
) {
  return `${process.env.REACT_APP_API_URL}/files/${
    file.id
  }/download/${encodeURIComponent(file.name)}`;
}

export function getContentsUrl(file: Pick<File, "id" | "name" | "mimeType">) {
  return `${process.env.REACT_APP_API_URL}/files/${file.id}/contents/${file.name}`;
}
