import { useMemo } from "react";
import {
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  getISOWeek,
  getWeek,
  getYear,
  isFriday,
  isWeekend,
  setYear,
  startOfWeek,
} from "date-fns";

type UseWeekDaysOptions = {
  /**
   * The number of week offset from the current week. Can be negative
   */
  weekOffset?: number;
  weekIndex?: number;
  year?: number;
};

/**
 *  Get the dates of a given week
 */
export function useWeekDays(opts: UseWeekDaysOptions = { weekOffset: 0 }) {
  const { days, startDate, endDate, weekIndex } = useMemo(() => {
    const today = new Date();
    const currentWeekIndex = getISOWeek(today);
    const givenWeekIndex = opts?.weekIndex ?? 0;
    const weekOffset = opts.weekOffset ?? givenWeekIndex - currentWeekIndex;

    const date = setYear(
      addWeeks(new Date(), weekOffset),
      opts.year ?? getYear(today)
    );

    const startDate = startOfWeek(date, { weekStartsOn: 1 });
    startDate.setDate(startDate.getDate());
    const endDate = endOfWeek(date, { weekStartsOn: 1 });
    // Remove saturday and sunday from the week
    endDate.setDate(endDate.getDate() - 2);

    const days = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    return {
      days,
      startDate,
      endDate,
      weekIndex: getWeek(startDate, { weekStartsOn: 1 }),
    };
  }, [opts.weekIndex, opts.weekOffset, opts.year]);

  return {
    days,
    startDate,
    endDate,
    weekIndex,
  };
}

/**
 * Either returns the given week day, or return the last friday
 */
export const getWeekday = (date: Date) => {
  const day = new Date(date);

  if (isWeekend(day)) {
    while (!isFriday(day)) {
      day.setDate(day.getDate() - 1);
    }

    return day;
  }

  return day;
};
