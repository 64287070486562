import {
  PopoverPosition,
  PopoverReference,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { NestedMenuItem as MuiNestedMenuItem } from "mui-nested-menu";

export type MenuItem = {
  label: string;
  icon: React.ReactNode;
  items?: NestedMenuItem[];
  disabled?: boolean;
  onClick?: () => void;
} | null;

type NestedMenuItem = Partial<MenuItem> & {
  render?: (props: { onClose: () => void }) => JSX.Element;
};

export type MenuProps<T> = T & {
  menuProps: {
    anchorEl: HTMLElement | null;
    anchorReference?: PopoverReference;
    anchorPosition?: PopoverPosition;
    open: boolean;
    onClick: (e: React.MouseEvent) => void;
    onContextMenu: (e: React.MouseEvent) => void;
    onClose: () => void;
  };
};

export function Menu<T>(props: MenuProps<T> & { items: MenuItem[] }) {
  return (
    <MuiMenu
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      anchorReference={props.menuProps.anchorReference}
      anchorPosition={props.menuProps.anchorPosition}
      anchorEl={props.menuProps.anchorEl}
      open={props.menuProps.open}
      onClick={props.menuProps.onClick}
      onContextMenu={props.menuProps.onContextMenu}
      onClose={props.menuProps.onClose}
      disableScrollLock
      disableAutoFocusItem
    >
      {props.items
        .filter((item) => item)
        .map(
          (item, i) =>
            item && (
              <NestedItem
                key={i}
                item={item}
                onClose={props.menuProps.onClose}
              />
            )
        )}
    </MuiMenu>
  );
}

function NestedItem(
  props: Omit<
    NestedMenuItem & { item: NonNullable<NestedMenuItem>; onClose: () => void },
    "render"
  >
) {
  // If the item still has items, we continue to render a NestedMenuItem.
  if (props.item.items) {
    return (
      <MuiNestedMenuItem
        parentMenuOpen={true}
        sx={{ px: 2 }}
        leftIcon={
          <ListItemIcon
            sx={{
              "&.MuiListItemIcon-root": {
                minWidth: "24px",
              },
            }}
          >
            {props.item.icon}
          </ListItemIcon>
        }
        label={props.item.label}
      >
        {props.item.items.map(
          (item, i) =>
            item && <NestedItem key={i} item={item} onClose={props.onClose} />
        )}
      </MuiNestedMenuItem>
    );
  }

  // If the item has a custom render function, we use it to render the item.
  if (props.item.render) {
    return props.item.render({
      onClose: props.onClose,
    });
  }

  // Otherwise, we render a simple MenuItem.
  return (
    <MuiMenuItem
      disabled={props.item.disabled}
      onClick={(e) => {
        e.stopPropagation();
        props.onClose();
        props.item.onClick && props.item.onClick();
      }}
    >
      <ListItemIcon>{props.item.icon}</ListItemIcon>
      <ListItemText primary={props.item.label} />
    </MuiMenuItem>
  );
}
