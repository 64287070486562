import { Menu, MenuProps } from "src/ui/Menu";

import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

type CreateJobMenuProps = {
  onCreate: () => void;
};

export function CreateJobMenu(props: MenuProps<CreateJobMenuProps>) {
  return (
    <Menu
      menuProps={props.menuProps}
      disableAutoFocusItem
      items={[
        {
          label: "Créer un enregistrement",
          icon: <ReceiptLongIcon />,
          onClick: () => {
            props.onCreate();
          },
        },
      ]}
    />
  );
}
