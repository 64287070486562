import { useEffect, useRef, useState, MouseEvent } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, ClickAwayListener, Divider } from "@mui/material";
import { FileWithPath } from "react-dropzone";
import { isMobile } from "react-device-detect";

import { DropZone } from "src/components/DropZone";
import { FileTable } from "src/components/FileTable";
import { useToast } from "src/contexts/ToastContext";
import { useContextMenu } from "src/hooks/useContextMenu";
import {
  getDownloadUrl,
  FileWithShares,
  isImage,
  getContentsUrl,
  isVideo,
  isPDF,
  isLink,
  isFolder,
  isEmail,
  isDocument,
} from "src/lib/file";
import { CreateFolderDialog } from "src/components/CreateFolderDialog";
import { CreateLinkDialog } from "src/components/CreateLinkDialog";
import { EditFileDialog } from "src/components/EditFileDialog";
import { EditFileSharesDialog } from "src/components/EditFileSharesDialog";
import { ConfirmDialog } from "src/components/ConfirmDialog";
import { TextFileDialog } from "src/components/TextFileDialog";
import { useAuth } from "src/contexts/AuthContext";
import { CreateFileMenu } from "src/components/CreateFileMenu";
import { useFiles } from "src/queries/useFiles";
import { useDisplayWeekSelector } from "src/hooks/useDisplayWeekSelector";
import { useTitle } from "src/hooks/useTitle";
import { useUpdateFile } from "src/queries/useUpdateFile";
import { useCreateFolder } from "src/queries/useCreateFolder";
import { useUploadFiles } from "src/queries/useUploadFiles";
import { useCreateLink } from "src/queries/useCreateLink";
import { useDeleteFile } from "src/queries/useDeleteFile";
import { useUpdateFileShares } from "src/queries/useUpdateFileShares";
import { ImageSwiper } from "src/components/ImageSwiper/ImageSwiper";
import { FilesBreadcrumbs } from "src/components/FilesBreadcrumbs";
import { getComparator, Order } from "src/lib/table";
import { Data, Filter } from "src/components/FileTable/FileTable";
import { Breadcrumb } from "src/ui/Breadcrumb";
import { useMoveFiles } from "src/queries/useMoveFiles";
import { EmptyContent } from "src/components/FileTable/EmptyContent";
import { Role } from "src/lib/user";
import { Head } from "src/ui/Head";
import { MailFileDialog } from "src/components/MailFileDialog";
import { DocxViewerDialog } from "src/components/DocxViewerDialog";

export function FilesRoute() {
  useDisplayWeekSelector(false);

  const downloadRef = useRef<HTMLAnchorElement>(null);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [createLinkOpen, setCreateLinkOpen] = useState(false);
  const [updateFileOpen, setUpdateFileOpen] = useState(false);
  const [updateFileSharesOpen, setUpdateFileSharesOpen] = useState(false);
  const [deleteFileOpen, setDeleteFileOpen] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<FileWithShares[]>([]);

  /**
   * The file that is currently "opened" on this route. This could be a text file
   * opened in a dialog or an image opened in the swiper
   */
  const [openedFile, setOpenedFile] = useState<FileWithShares | null>(null);

  // FilesTable hooks
  const [order, setOrder] = useState<Order>("desc");
  const [filter, setFilter] = useState<Filter>({
    name: {},
    mimeType: {},
    createdAt: {},
  });
  const [orderBy, setOrderBy] = useState<keyof Data>("name");

  const { showToast } = useToast();
  const contextMenu = useContextMenu();
  const routeParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();

  const { data } = useFiles({ parentId: Number(routeParams.id) });
  const files = data!.files.sort(getComparator(order, orderBy));
  const breadcrumbs = data!.breadcrumbs;

  // reset filters on route change
  useEffect(() => {
    setFilter({
      name: {},
      mimeType: {},
      createdAt: {},
    });
  }, [routeParams]);

  const uploadFilesMutation = useUploadFiles({
    onSuccess(params) {
      if (params.files.length > 1) {
        showToast("Les fichiers ont bien été téléchargés");
      } else {
        showToast("Le fichier a bien été téléchargé");
      }
    },
    onError() {
      showToast("Une erreur est survenue lors du téléchargement");
    },
  });

  const createLinkMutation = useCreateLink({
    onSuccess() {
      showToast("Le lien a bien été créé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const createFolderMutation = useCreateFolder({
    onSuccess() {
      showToast("Le dossier a été créé avec succès");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const moveFileMutation = useMoveFiles({
    onSuccess() {
      showToast("Le fichier a été déplacé avec succès");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateFileMutation = useUpdateFile({
    onSuccess() {
      showToast("Le fichier a été modifié avec succès");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateFileSharesMutation = useUpdateFileShares({
    onSuccess() {
      showToast("Les droits d'accès ont été modifiés avec succès");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const deleteFileMutation = useDeleteFile({
    onSuccess() {
      showToast("Le fichier a été supprimé avec succès");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const clickAction = (file: FileWithShares) => {
    if (isVideo(file)) {
      window.open(
        `${process.env.REACT_APP_API_URL}/files/${file.id}/contents/${file.name}`
      );
    } else if (isPDF(file)) {
      window.open(
        `/pdfjs/web/viewer.html?file=${process.env.REACT_APP_API_URL}/files/${file.id}/contents/${file.name}`
      );
    } else if (isLink(file)) {
      window.open(file.url);
    } else if (isFolder(file)) {
      navigate(`/files/${file.id}`);
    } else if (
      file.mimeType === "text/plain" ||
      isImage(file) ||
      isEmail(file) ||
      isDocument(file)
    ) {
      setOpenedFile(file);
    } else {
      window.open(getDownloadUrl(file));
    }
  };

  const handleRowClick = (file: FileWithShares, e: MouseEvent<HTMLElement>) => {
    /**
     * Users cannot edit files, their only action is to open them.
     * On mobile devices, clicking on a row opens the content
     */
    if (auth.user?.role === Role.USER || isMobile) {
      clickAction(file);
      return;
    }

    if (e.ctrlKey) {
      setSelectedFiles((files) => {
        if (files.includes(file)) {
          return files.filter((f) => f !== file);
        } else {
          return [...files, file];
        }
      });

      return;
    }

    if (e.shiftKey) {
      const lastSelectedFile = selectedFiles[selectedFiles.length - 1];
      if (lastSelectedFile) {
        const lastSelectedFileIndex = files.findIndex(
          (f) => f.id === lastSelectedFile.id
        );
        const clickedFileIndex = files.findIndex((f) => f.id === file.id);

        const start = Math.min(lastSelectedFileIndex, clickedFileIndex);
        const end = Math.max(lastSelectedFileIndex, clickedFileIndex);

        setSelectedFiles(files.slice(start, end + 1));
        return;
      }
    }

    setSelectedFiles([file]);
  };

  // Reset the selected files when changing route
  useEffect(() => {
    setSelectedFiles([]);
  }, [location.pathname]);

  const parentName = breadcrumbs.at(-1)?.name;
  const parentId = routeParams.id ? Number(routeParams.id) : null;
  const title = parentName ?? "Fichiers";

  useTitle(title);

  return (
    <>
      <Head title={title} />
      <Box
        sx={{ display: "flex", flexFlow: "column nowrap", flex: 1, padding: 2 }}
      >
        <FilesBreadcrumbs>
          <Breadcrumb
            to="/files"
            sx={{ color: "inherit" }}
            onDrop={() => {
              moveFileMutation.mutate({
                ids: selectedFiles.map((f) => f.id),
                parentId: null,
              });
            }}
          >
            Fichiers
          </Breadcrumb>
          {breadcrumbs.map((file) => (
            <Breadcrumb
              key={file.id}
              to={`/files/${file.id}`}
              sx={{ color: file.color ?? "inherit" }}
              onDrop={() => {
                moveFileMutation.mutate({
                  ids: selectedFiles.map((f) => f.id),
                  parentId: file.id,
                });
              }}
            >
              {file.name}
            </Breadcrumb>
          ))}
        </FilesBreadcrumbs>
        <Divider />
        {selectedFiles[0] && (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            style={{ display: "none" }}
            ref={downloadRef}
            href={getDownloadUrl(selectedFiles[0])}
            download={selectedFiles[0].name}
          />
        )}
        <DropZone
          onDrop={(files) =>
            uploadFilesMutation.mutate({
              parentId,
              files,
            })
          }
          onContextMenu={contextMenu.onContextMenu}
        >
          {files.length > 0 && (
            <ClickAwayListener
              onClickAway={() => {
                if (!updateFileOpen && !updateFileSharesOpen) {
                  setSelectedFiles([]);
                }
              }}
            >
              <FileTable
                files={files}
                filter={filter}
                setFilter={setFilter}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                onRowClick={handleRowClick}
                onSelect={(file) => setSelectedFiles([file])}
                selectedFiles={selectedFiles}
                onRowDragStart={(file) => {
                  if (!selectedFiles.find((f) => f.id === file.id)) {
                    setSelectedFiles([file]);
                  }
                }}
                onRowDrop={(sourceIds, destId) => {
                  moveFileMutation.mutate({
                    ids: sourceIds,
                    parentId: destId,
                  });
                }}
                onRowDoubleClick={clickAction}
                onUpdate={() => setUpdateFileOpen(true)}
                onDownload={() => {
                  downloadRef.current?.click();
                }}
                onUpdateShares={() => setUpdateFileSharesOpen(true)}
                onUpdateColor={(id, color) =>
                  updateFileMutation.mutate({ id, color })
                }
                onDelete={() => setDeleteFileOpen(true)}
              />
            </ClickAwayListener>
          )}
          {files.length === 0 && (
            <EmptyContent onContextMenu={contextMenu.onContextMenu} />
          )}
        </DropZone>
      </Box>
      <>
        <CreateFileMenu
          menuProps={contextMenu.getMenuProps()}
          onCreateFolder={() => setCreateFolderOpen(true)}
          onCreateLink={() => setCreateLinkOpen(true)}
          onUploadFiles={() => uploadRef.current?.click()}
        />
        <input
          type="file"
          ref={uploadRef}
          style={{ display: "none" }}
          multiple
          onChange={(e) => {
            uploadFilesMutation.mutate({
              parentId,
              files: Array.from(e.target.files as ArrayLike<FileWithPath>),
            });
          }}
        />
        <CreateFolderDialog
          open={createFolderOpen}
          onClose={() => setCreateFolderOpen(false)}
          onConfirm={({ name }) => {
            createFolderMutation.mutate({
              name,
              parentId,
            });
          }}
        />
        <CreateLinkDialog
          open={createLinkOpen}
          onClose={() => setCreateLinkOpen(false)}
          onConfirm={({ name, url }) => {
            createLinkMutation.mutate({
              name,
              parentId,
              url,
            });
          }}
        />
      </>
      {openedFile && isImage(openedFile) && (
        <ImageSwiper
          images={files
            .filter((file) => isImage(file))
            .map((file) => ({
              url: getContentsUrl(file),
              mimeType: file.mimeType!,
            }))}
          initialSlide={
            files
              .filter((file) => isImage(file))
              .findIndex((file) => file.id === openedFile?.id) ?? 0
          }
          onClose={() => {
            setOpenedFile(null);
          }}
        />
      )}
      {openedFile && isEmail(openedFile) && (
        <MailFileDialog
          file={openedFile}
          open
          onClose={() => setOpenedFile(null)}
        />
      )}
      {openedFile && isDocument(openedFile) && (
        <DocxViewerDialog
          file={openedFile}
          open
          onClose={() => setOpenedFile(null)}
        />
      )}
      {selectedFiles[0] && updateFileOpen && (
        <EditFileDialog
          open={updateFileOpen}
          file={selectedFiles[0]}
          onClose={() => setUpdateFileOpen(false)}
          onConfirm={updateFileMutation.mutate}
        />
      )}
      {selectedFiles[0] && updateFileSharesOpen && (
        <EditFileSharesDialog
          open={updateFileSharesOpen}
          file={selectedFiles[0]}
          onClose={() => setUpdateFileSharesOpen(false)}
          onConfirm={updateFileSharesMutation.mutate}
        />
      )}
      {selectedFiles[0] && deleteFileOpen && (
        <ConfirmDialog
          title="Supprimer un fichier"
          content="Voulez-vous vraiment supprimer ce fichier ?"
          cancelText="Annuler"
          confirmText="Supprimer"
          open={deleteFileOpen}
          onClose={() => setDeleteFileOpen(false)}
          onConfirm={() => deleteFileMutation.mutate(selectedFiles[0].id)}
        />
      )}
      {openedFile?.mimeType === "text/plain" && (
        <TextFileDialog
          open={true}
          onClose={() => {
            // TODO
          }}
          file={openedFile}
        />
      )}
    </>
  );
}
