import { MouseEventHandler, useRef, useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { format } from "date-fns/format";

import { FileWithShares } from "src/lib/file";
import { theme } from "src/lib/theme";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FileIcon } from "../FileIcon";

type FileRowProps = {
  selected?: boolean;
  file: FileWithShares;
  onContextMenu: MouseEventHandler<HTMLElement>;
  onMoreClick: MouseEventHandler<HTMLButtonElement>;
  onClick: MouseEventHandler<HTMLElement>;
  onDoubleClick: MouseEventHandler<HTMLElement>;
  onDrop: (dest: number) => void;
  onDragStart: (file: FileWithShares) => void;
};

export function FileRow(props: FileRowProps) {
  const ref = useRef<HTMLTableRowElement>(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleDragStart = (e: React.DragEvent<HTMLElement>) => {
    e.dataTransfer.setData("fileId", props.file.id.toString());

    props.onDragStart(props.file);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLElement>) => {
    e.dataTransfer.clearData();
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    setIsDraggedOver(false);

    const dataTransferFileId = e.dataTransfer.getData("fileId");
    const fileId = parseInt(dataTransferFileId, 10) ?? null;

    if (!fileId) {
      return;
    }

    if (!props.file.isFolder) {
      return;
    }

    if (fileId === props.file.id) {
      return;
    }

    props.onDrop(props.file.id);
  };

  return (
    <TableRow
      hover
      draggable
      component="div"
      ref={ref}
      sx={{
        cursor: "pointer",
        userSelect: "none",
        "&:dragging": {
          cursor: "grabbing",
        },
        backgroundColor: isDraggedOver ? "secondary.light" : "inherit",
      }}
      onContextMenu={props.onContextMenu}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
      onDragEnter={() => setIsDraggedOver(true)}
      onDragLeave={(e) => {
        if (!ref.current?.contains(e.relatedTarget as Node)) {
          setIsDraggedOver(false);
        }
      }}
      selected={props.selected}
      onDoubleClick={props.onDoubleClick}
      onClick={props.onClick}
    >
      <TableCell
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
        component="div"
      >
        {<FileIcon file={props.file} />}
        <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
          {props.file.name}
        </Typography>
      </TableCell>
      {isDesktop && (
        <TableCell component="div">
          {format(props.file.createdAt, "dd MMM. yyyy")}
        </TableCell>
      )}
      <TableCell
        padding="none"
        sx={{ px: 2, textAlign: "right" }}
        component="div"
      >
        <IconButton onClick={props.onMoreClick}>
          <MoreVertIcon fontSize="medium" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
