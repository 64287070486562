import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { createTheme } from "@mui/material";

import { frFR } from "@mui/material/locale";
import { orange } from "@mui/material/colors";

export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme(
  {
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            paddingTop: "env(safe-area-inset-top)",
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            paddingTop: `env(safe-area-inset-top)`,
            paddingBottom: `env(safe-area-inset-bottom)`,
            paddingLeft: `env(safe-area-inset-left)`,
          },
        },
      },
      MuiSpeedDialAction: {
        styleOverrides: {
          staticTooltipLabel: {
            width: "max-content",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 30,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "none",
          },
        },
      },
      MuiListItem: {
        defaultProps: {
          disablePadding: true,
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: ({ theme }) => ({
            textDecoration: "none",
            borderRadius: 30,
            ":hover": {
              backgroundColor: theme.palette.action.hover,
              ".MuiTouchRipple-root": {
                color: theme.palette.secondary.contrastText,
              },
            },
            "&.Mui-selected": {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.secondary.contrastText,
              fontWeight: "bold",
              ":hover": {
                backgroundColor: theme.palette.secondary.light,
              },
              ".MuiListItemIcon-root": {
                color: theme.palette.secondary.contrastText,
              },
            },
          }),
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            textWrap: "nowrap",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            textDecoration: "none",
            borderRadius: 30,
            ":hover": {
              backgroundColor: theme.palette.action.hover,
              ".MuiTouchRipple-root": {
                color: theme.palette.secondary.contrastText,
              },
            },
            "&.Mui-selected": {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.secondary.contrastText,
              fontWeight: "bold",
              ":hover": {
                backgroundColor: theme.palette.secondary.light,
              },
              ".MuiListItemIcon-root": {
                color: theme.palette.secondary.contrastText,
              },
            },
          }),
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.23)",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 16,
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            margin: 0,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: 8,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            // ".MuiList-root": {
            //   padding: 0,
            // },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            userSelect: "none",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 12,
            padding: "4px 6px",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 11,
          },
        },
      },
    },
    typography: {
      fontSize: 13,
    },
    palette: {
      mode: "light",
      primary: { ...orange, contrastText: "white" },
      secondary: {
        main: "#cdcef7",
        light: "#e2e4f5",
        contrastText: "#383bcb",
      },
      action: {
        hover: "#f4f5fc",
      },
      text: {
        primary: "#333",
        secondary: "#666",
      },
    },
  },
  // frCH doesn't exist
  frFR
);
