import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { WorksiteQueryKey } from "./useWorksite";
import { WorksitesQueryKey } from "./useWorksites";

type UseUpdateWorksiteOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseUpdateWorksiteParams = { id: number } & Partial<{
  number: string;
  duration: number;
  description: string;
}>;

export function useUpdateWorksite(options: UseUpdateWorksiteOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation<UseUpdateWorksiteParams, Error, UseUpdateWorksiteParams>(
    async ({ id, ...params }: UseUpdateWorksiteParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/worksites/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: JSON.stringify(snakecaseKeys(params, { deep: true })),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update worksite.");
      }

      const data = await response.json();
      return camelcaseKeys(data.worksite, { deep: true });
    },
    {
      ...options,
      onSuccess: (data) => {
        queryClient.invalidateQueries(WorksitesQueryKey);
        queryClient.invalidateQueries([WorksiteQueryKey, data.id]);
        options.onSuccess?.();
      },
    }
  );
}
