import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useContextMenu } from "src/hooks/useContextMenu";
import { JobTypeWorksite } from "src/lib/worksite";
import { EditJobTypeMenu } from "../EditJobTypeMenu";
import { TableLink } from "../TableLink";
import { durationFormatter } from "src/lib/intl";

type JobTypeRowProps = {
  selected?: boolean;
  jobType: JobTypeWorksite;
  onSelect: (jobType: JobTypeWorksite | null) => void;
  onUpdate: () => void;
  onDelete: () => void;
};

export function JobTypeRow(props: JobTypeRowProps) {
  const contextMenu = useContextMenu();

  return (
    <TableRow
      hover
      sx={{ cursor: "pointer" }}
      component={TableLink}
      href={`/job-types/${props.jobType.id}`}
      onContextMenu={(e) => {
        props.onSelect(props.jobType);
        contextMenu.onContextMenu(e);
      }}
      selected={props.selected}
    >
      <TableCell component="div" role="cell">
        {props.jobType.number}
      </TableCell>
      <TableCell component="div" role="cell">
        {props.jobType.name}
      </TableCell>
      <TableCell component="div" role="cell">
        <Checkbox checked={props.jobType.isVisible} disabled />
      </TableCell>
      <TableCell component="div" role="cell">
        <Checkbox checked={props.jobType.isDescriptionRequired} disabled />
      </TableCell>
      <TableCell component="div" role="cell">
        {durationFormatter.format(props.jobType.totalTimeYear ?? 0)}
      </TableCell>
      <TableCell align="right" component="div" role="cell">
        <IconButton {...contextMenu.getButtonProps()}>
          <MoreVertIcon fontSize="medium" />
        </IconButton>
        <EditJobTypeMenu
          menuProps={contextMenu.getMenuProps()}
          jobType={props.jobType}
          onSelect={props.onSelect}
          onUpdate={props.onUpdate}
          onDelete={props.onDelete}
        />
      </TableCell>
    </TableRow>
  );
}
