import { useForm } from "react-hook-form";
import { DialogContentText, TextField } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";

import { UpdateUserSchema, updateUserSchema } from "src/schema/user.schema";
import { User } from "src/lib/user";
import { Dialog } from "src/ui/Dialog";

type EditUserPasswordDialogProps = {
  user: User;
  open: boolean;
  onClose: () => void;
  onConfirm: (data: UpdateUserSchema) => void;
};

export function EditUserPasswordDialog(props: EditUserPasswordDialogProps) {
  const { register, handleSubmit } = useForm<UpdateUserSchema>({
    resolver: zodResolver(updateUserSchema),
    defaultValues: {
      id: props.user.id,
    },
  });

  const handleClose = () => {
    props.onClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: UpdateUserSchema) => {
    handleClose();
    props.onConfirm(data);
  };

  return (
    <Dialog
      open={props.open}
      title="Modifier le mot de passe"
      cancelText="Annuler"
      confirmText="Modifier"
      onClose={handleClose}
      onCancel={handleCancel}
      onSubmit={handleSubmit(onSubmit)}
      form
    >
      <DialogContentText>
        Veuillez entrer le nouveau mot de passe.
      </DialogContentText>
      <TextField
        autoFocus
        margin="normal"
        id="password"
        label="Nouveau mot de passe"
        type="password"
        fullWidth
        variant="outlined"
        inputProps={register("password", { required: true })}
      />
    </Dialog>
  );
}
