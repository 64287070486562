import React from "react";
import { DialogContentText } from "@mui/material";

import { Dialog } from "src/ui/Dialog";

type ConfirmDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  open: boolean;
};

export function ConfirmDialog(props: ConfirmDialogProps) {
  const handleClose = () => {
    props.onClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleConfirm = () => {
    handleClose();
    props.onConfirm();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      title={props.title}
      cancelText={props.cancelText}
      confirmText={props.confirmText}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      <DialogContentText>{props.content}</DialogContentText>
    </Dialog>
  );
}
