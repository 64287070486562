import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Dialog } from "src/ui/Dialog";

type CreateFolderDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (file: z.infer<typeof CreateLinkSchema>) => void;
};

const CreateLinkSchema = z.object({
  name: z.string().min(1, "Saisir un nom"),
  url: z.string().url("Saisir une URL valide"),
});

export function CreateLinkDialog({
  open,
  onClose,
  onConfirm,
}: CreateFolderDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof CreateLinkSchema>>({
    resolver: zodResolver(CreateLinkSchema),
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: z.infer<typeof CreateLinkSchema>) => {
    handleClose();

    onConfirm(data);
  };

  return (
    <Dialog
      open={open}
      title="Créer un lien"
      cancelText="Annuler"
      confirmText="Créer"
      onCancel={handleCancel}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      form
    >
      <TextField
        id="name"
        autoFocus
        label="Nom"
        fullWidth
        margin="dense"
        variant="outlined"
        inputProps={register("name", { required: true })}
        error={!!errors.name}
        helperText={errors.name?.message ?? " "}
      />
      <TextField
        id="url"
        autoFocus
        label="URL"
        fullWidth
        margin="dense"
        variant="outlined"
        inputProps={register("url", { required: true })}
        error={!!errors.url}
        helperText={errors.url?.message ?? " "}
      />
    </Dialog>
  );
}
