import { useState } from "react";
import { Box, TableContainer, Typography } from "@mui/material";

import HandymanOutlined from "@mui/icons-material/HandymanOutlined";

import { ConfirmDialog } from "src/components/ConfirmDialog";
import { CreateJobTypeDialog } from "src/components/CreateJobTypeDialog";
import { useTitle } from "src/hooks/useTitle";
import { JobTypeWorksite, WorksiteType } from "src/lib/worksite";
import { useWorksites } from "src/queries/useWorksites";
import { useContextMenu } from "src/hooks/useContextMenu";
import { useToast } from "src/contexts/ToastContext";
import { JobTypeTable } from "src/components/JobTypeTable";
import { EditJobTypeDialog } from "src/components/EditJobTypeDialog";
import { CreateJobTypeMenu } from "src/components/CreateJobTypeMenu";
import { useCreateWorksite } from "src/queries/useCreateWorksite";
import { useUpdateWorksite } from "src/queries/useUpdateWorksite";
import { useDeleteWorksite } from "src/queries/useDeleteWorksite";
import { Head } from "src/ui/Head";
import { useDisplayWeekSelector } from "src/hooks/useDisplayWeekSelector";

type JobTypesRouteProps = {
  title: string;
};

export function JobTypesRoute({ title }: JobTypesRouteProps) {
  useTitle(title);
  useDisplayWeekSelector(false);

  const [createJobTypeOpen, setCreateJobTypeOpen] = useState(false);
  const [updateJobTypeOpen, setUpdateJobTypeOpen] = useState(false);
  const [deleteJobTypeOpen, setDeleteJobTypeOpen] = useState(false);
  const [selectedJobType, setSelectedJobType] =
    useState<JobTypeWorksite | null>(null);

  const contextMenu = useContextMenu();
  const { showToast } = useToast();

  const { data: worksites } = useWorksites({ type: WorksiteType.ACTIVITY });

  const createWorksiteMutation = useCreateWorksite({
    onSuccess() {
      showToast("Le type de tâche a été créé avec succès");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateWorksiteMutation = useUpdateWorksite({
    onSuccess() {
      showToast("Le type de tâche a bien été mis à jour");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const deleteWorksiteMutation = useDeleteWorksite({
    onSuccess() {
      showToast("Le type de tâche a bien été supprimé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  if (!worksites) return null;

  return (
    <>
      <Head title={title} />
      <Box
        onContextMenu={contextMenu.onContextMenu}
        sx={{ display: "flex", flex: 1 }}
      >
        {worksites.length > 0 && (
          <TableContainer sx={{ height: "calc(100vh - 64px)" }}>
            <JobTypeTable
              jobTypes={worksites}
              onSelect={setSelectedJobType}
              selectedJobType={selectedJobType}
              onUpdate={() => setUpdateJobTypeOpen(true)}
              onDelete={() => setDeleteJobTypeOpen(true)}
            />
          </TableContainer>
        )}
        {worksites.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexFlow: "column nowrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HandymanOutlined style={{ marginTop: -64 }} />
            <Typography variant="h6" color="text.primary">
              Aucun type de tâches
            </Typography>
          </Box>
        )}
      </Box>
      <>
        <CreateJobTypeMenu
          menuProps={contextMenu.getMenuProps()}
          onCreate={() => setCreateJobTypeOpen(true)}
        />
        <CreateJobTypeDialog
          open={createJobTypeOpen}
          onClose={() => setCreateJobTypeOpen(false)}
          onConfirm={createWorksiteMutation.mutate}
        />
        {selectedJobType && updateJobTypeOpen && (
          <EditJobTypeDialog
            open={updateJobTypeOpen}
            jobType={selectedJobType}
            onClose={() => setUpdateJobTypeOpen(false)}
            onConfirm={updateWorksiteMutation.mutate}
          />
        )}
        {selectedJobType && deleteJobTypeOpen && (
          <ConfirmDialog
            open={deleteJobTypeOpen}
            title="Supprimer le type de tâche"
            content="Êtes-vous sûr de vouloir supprimer ce type de tâche ?"
            onClose={() => setDeleteJobTypeOpen(false)}
            onConfirm={() => deleteWorksiteMutation.mutate(selectedJobType.id)}
          />
        )}
      </>
    </>
  );
}
