import React, { useMemo, useState } from "react";
import {
  Box,
  Divider,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperType from "swiper";
import { isSameDay, format, isFuture } from "date-fns";
import { frCH } from "date-fns/locale/fr-CH";

import "swiper/css";
import "swiper/css/pagination";

import { Job } from "src/lib/job";
import { durationFormatter } from "src/lib/intl";
import { useContextMenu } from "src/hooks/useContextMenu";
import { CreateJobMenu } from "./CreateJobMenu";

type TimeTrackingTableProps = {
  defaultSelectedDate: Date;
  jobs: Job[];
  days: Date[];
  singleDayMode?: boolean;
  renderDate: (date: Date) => React.ReactNode;
  onDateChange?: (date: Date) => void;
  onCreateJob?: (date: Date) => void;
};

export const TimeTrackingTable = (props: TimeTrackingTableProps) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  const today = new Date();

  const theme = useTheme();
  const isDesktop = true;
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const singleDayMode = useMemo(
    () => props.singleDayMode || !isDesktop,
    [props.singleDayMode, isDesktop]
  );

  const possibleTabIndex = props.days.findIndex((day) =>
    isSameDay(new Date(day), props.defaultSelectedDate)
  );
  const [tabIndex, setTabIndex] = useState(
    possibleTabIndex !== -1 ? possibleTabIndex : 4
  );

  const contextMenu = useContextMenu();
  const [selectedDate, setSelectedDate] = useState(props.days[tabIndex]);

  const handleChange = (index: number) => {
    setTabIndex(index);

    swiper?.slideTo(index);

    props.onDateChange?.(props.days[index]);
  };

  return (
    <>
      <Tabs
        variant="fullWidth"
        value={singleDayMode ? tabIndex : false}
        onChange={(_, index) => singleDayMode && handleChange(index)}
      >
        {props.days.map((day, i) => {
          const date = new Date(day);

          return (
            <Tab
              key={day.toString()}
              disabled={date > today || !singleDayMode}
              title={format(date, "EEEE dd.MM", { locale: frCH })}
              label={
                isLarge
                  ? format(date, "EEEE dd MMM", { locale: frCH })
                  : format(date, "EEE dd.MM", { locale: frCH })
              }
              value={i}
              sx={{
                pb: "1rem",
                "&.Mui-disabled": {
                  color: {
                    // On desktop the tabs are disabled, but must remain visible
                    md: "text.secondary",
                  },
                },
              }}
            />
          );
        })}
      </Tabs>
      <Divider />
      <Swiper
        slidesPerView={singleDayMode ? 1 : 5}
        tabIndex={tabIndex}
        initialSlide={tabIndex}
        onSlideChange={(swiper) => handleChange(swiper.activeIndex)}
        simulateTouch={false}
        onSwiper={setSwiper}
        style={{ height: "100%", width: "100%" }}
      >
        {props.days
          // We do not render the future days on mobile to prevent the user from
          // swipping to a future day
          .filter((day) => !singleDayMode || !isFuture(new Date(day)))
          .map((date) => (
            <SwiperSlide
              key={date.toString()}
              style={{
                display: "flex",
                flexDirection: "column",
                borderRight: singleDayMode
                  ? 0
                  : `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box
                onContextMenu={(e) => {
                  // Prevent the context menu from opening on future days
                  if (isFuture(new Date(date))) {
                    return;
                  }

                  setSelectedDate(date);
                  contextMenu.onContextMenu(e);
                }}
                sx={{
                  flex: 1,
                  // TODO: Handle x overflow
                }}
              >
                {props.renderDate(date)}
              </Box>
              <Box
                title="Total journalier"
                sx={{
                  p: 1.5,
                  borderTop: singleDayMode
                    ? 0
                    : `1px solid ${theme.palette.divider}`,
                }}
              >
                Total:{" "}
                <span style={{ fontWeight: 600 }}>
                  {durationFormatter.format(
                    props.jobs
                      .filter((job) => isSameDay(job.date, new Date(date)))
                      .reduce((acc, job) => acc + job.duration, 0) ?? 0
                  )}
                </span>
              </Box>
            </SwiperSlide>
          ))}
      </Swiper>
      <CreateJobMenu
        menuProps={contextMenu.getMenuProps()}
        onCreate={() => props.onCreateJob?.(new Date(selectedDate))}
      />
    </>
  );
};
