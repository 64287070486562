import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { EmployeeRow } from "./EmployeeRow";
import { Column, getComparator, Order } from "src/lib/table";

import { User } from "src/lib/user";
import { TableFilterPopover } from "src/ui/TableFilterPopover";

export interface Data {
  lastName: string;
  firstName: string;
  username: string;
  disabledAt?: Date;
}

type EmployeeTableProps = {
  users: User[];
  onSelect: (user: User | null) => void;
  selectedUser: User | null;
  onUpdate: () => void;
  onUpdatePassword: () => void;
  onDisable: () => void;
  onActivate: () => void;
};

const columns: Column<Data>[] = [
  {
    id: "lastName",
    label: "Nom",
  },
  {
    id: "firstName",
    label: "Prénom",
  },
  {
    id: "username",
    label: "Nom d'utilisateur",
  },
  {
    id: "disabledAt",
    label: "Activé",
    filters: [
      {
        type: "checkbox",
        options: [
          {
            label: "Désactivé",
            value: "disabled",
          },
          {
            label: "Activé",
            value: "enabled",
          },
        ],
      },
    ],
  },
];

export function EmployeeTable(props: EmployeeTableProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("lastName");
  const [filter, setFilter] = useState({
    state: {
      disabled: false,
      enabled: true,
    },
  });

  const createSortHandler = (property: keyof Data) => () => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
              key={column.id}
              sortDirection={orderBy === column.id ? order : false}
              align={column.align}
              style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
              {column.filters &&
                column.filters.map((columnFilter, i) => (
                  <TableFilterPopover
                    key={i}
                    options={columnFilter.options}
                    filter={filter.state}
                    onChange={(value, checked) =>
                      setFilter({
                        state: {
                          ...filter.state,
                          [value]: checked,
                        },
                      })
                    }
                  />
                ))}
            </TableCell>
          ))}
          <TableCell
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          ></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.users
          .slice()
          .sort(getComparator(order, orderBy))
          .filter(
            (user) =>
              (filter.state.disabled || !user.disabledAt) &&
              (filter.state.enabled || !!user.disabledAt)
          )
          .map((user) => (
            <EmployeeRow
              key={user.id}
              user={user}
              onSelect={props.onSelect}
              onUpdate={props.onUpdate}
              onUpdatePassword={props.onUpdatePassword}
              onDisable={props.onDisable}
              onActivate={props.onActivate}
              selected={props.selectedUser === user}
            />
          ))}
      </TableBody>
    </Table>
  );
}
