import { useRef } from "react";
import { Zoom, Pagination } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { SwiperImage as Image } from "./SwiperImage";
import { theme } from "src/lib/theme";

import CloseIcon from "@mui/icons-material/Close";

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";

import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";

type Image = {
  url: string;
  mimeType: string;
};

type ImageSwiperProps = {
  images: Image[];
  initialSlide: number;
  onClose: () => void;
};

const buttonStyles = {
  position: "absolute",
  color: "primary.contrastText",
  zIndex: 10000,
  borderRadius: "50%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
};

export function ImageSwiper(props: ImageSwiperProps) {
  const sliderRef = useRef<SwiperRef>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const shouldDisplayNavigation = !isMobile && props.images.length > 1;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        bgcolor: "rgba(0, 0, 0, 0.8)",
        zIndex: 10000,
      }}
    >
      {shouldDisplayNavigation && (
        // previous button
        <IconButton
          sx={{
            ...buttonStyles,
            top: "50%",
            transform: "translateY(-50%)",

            left: 12,
          }}
          onClick={() => sliderRef.current?.swiper.slidePrev()}
        >
          <ChevronLeft
            sx={{
              width: 32,
              height: 32,
            }}
          />
        </IconButton>
      )}
      <Swiper
        ref={sliderRef}
        style={
          {
            width: "100%",
            height: "100%",
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          } as any
        }
        zoom={true}
        navigation={false}
        pagination={{
          clickable: true,
        }}
        lazy={{
          loadPrevNext: true,
        }}
        initialSlide={props.initialSlide}
        spaceBetween={10}
        modules={[Zoom, Pagination]}
      >
        {props.images.map((image) => (
          <SwiperSlide key={image.url}>
            <div className="swiper-zoom-container">
              <Image image={image} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {shouldDisplayNavigation && (
        <IconButton
          sx={{
            ...buttonStyles,
            top: "50%",
            transform: "translateY(-50%)",
            right: 12,
          }}
          onClick={() => sliderRef.current?.swiper.slideNext()}
        >
          <ChevronRight
            sx={{
              width: 32,
              height: 32,
            }}
          />
        </IconButton>
      )}
      <IconButton
        sx={{
          ...buttonStyles,
          right: 12,
          top: 12,
        }}
      >
        <CloseIcon
          onClick={props.onClose}
          sx={{
            width: 32,
            height: 32,
          }}
        />
      </IconButton>
    </Box>
  );
}

ImageSwiper.defaultProps = {
  initialSlide: 0,
};
