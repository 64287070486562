import { useState } from "react";
import { format } from "date-fns";
import { frCH } from "date-fns/locale/fr-CH";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { EmployeeTimeTrackingRow } from "./EmployeeTimeTrackingRow";
import { getComparator, HeadCell, Order } from "src/lib/table";

export interface Data {
  fullName: string;
}

type EmployeeTimeTrackingTableProps = {
  onCellClick: (data: { userId: number; date: Date }) => void;
  days: Date[];
  users: {
    id: number;
    lastName: string;
    firstName: string;
    username: string;
    jobs: {
      date: Date;
      total: number;
    }[];
  }[];
};

const headCells: HeadCell<Data>[] = [
  {
    id: "fullName",
    label: "Nom",
  },
];

export function EmployeeTimeTrackingTable({
  users,
  days,
  onCellClick,
}: EmployeeTimeTrackingTableProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("fullName");

  const createSortHandler = (property: keyof Data) => () => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          {days.map((day) => (
            <TableCell
              key={day.toString()}
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {format(new Date(day), "cccc dd.MM", {
                weekStartsOn: 1,
                locale: frCH,
              })}
            </TableCell>
          ))}
          <TableCell
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            Total
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users
          .map((u) => ({
            ...u,
            fullName: `${u.lastName} ${u.firstName}`,
            total: u.jobs.reduce((acc, job) => acc + job.total, 0),
          }))
          .sort(getComparator(order, orderBy))
          .map((user) => (
            <EmployeeTimeTrackingRow
              key={user.id}
              user={user}
              onCellClick={onCellClick}
            />
          ))}
      </TableBody>
    </Table>
  );
}
