import React from "react";
import { IMaskInput } from "react-imask";

interface DurationInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
export const DurationInput = React.forwardRef<
  HTMLInputElement,
  DurationInputProps
>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  // TODO: Prevent the user from entering "00:78"
  return (
    <IMaskInput
      {...other}
      mask="00:00"
      inputRef={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  );
});
