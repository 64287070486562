export const numberFormatter = new Intl.NumberFormat("fr-CH", {});

export const durationFormatter = {
  /**
   * Format a duration in seconds to `hh:mm`, e.g. `01:30`.
   */
  format: (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  },
};

export const durationParser = {
  /**
   * Parse a duration in `hh:mm` format to seconds, e.g. `01:30` to `5400`.
   */
  parse: (duration: string) => {
    const [hours, minutes] = duration.split(":");
    return Number(hours) * 3600 + Number(minutes) * 60;
  },
};
