import { useState } from "react";
import { IconButton, Popover } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";

type PopoverButtonProps = {
  icon?: JSX.Element;
  children: React.ReactNode;
};

export function PopoverButton(props: PopoverButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleClick}>
        {props.icon ?? <MoreVertIcon fontSize="medium" />}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {props.children}
      </Popover>
    </>
  );
}
