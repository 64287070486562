import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { Menu, MenuProps } from "src/ui/Menu";

type EditJobMenuProps = {
  onUpdate: () => void;
  onDelete: () => void;
};

export function EditJobMenu(props: MenuProps<EditJobMenuProps>) {
  return (
    <Menu
      menuProps={props.menuProps}
      items={[
        {
          label: "Modifier",
          icon: <EditIcon />,
          onClick: props.onUpdate,
        },
        {
          label: "Supprimer",
          icon: <DeleteIcon />,
          onClick: props.onDelete,
        },
      ]}
    />
  );
}
