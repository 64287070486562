export const getUserData = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const user = await response.json();

  if (!user.user) {
    return null;
  }

  return user.user;
};

export const loginRequest = async (username: string, password: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to login");
  }

  const data = await response.json();

  if (!data.authorisation.token) {
    throw new Error("Failed to login");
  }

  return {
    user: data.user,
    token: data.authorisation.token,
  };
};
