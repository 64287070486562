import { MouseEvent, useState } from "react";
import { PopoverReference } from "@mui/material";

type UseContextMenu = {
  disabled: boolean;
};

/**
 * Helper hook to manage the state of a context menu.
 *
 * Can either be triggered by the `onContextMenu` event or by a button click.
 */
export function useContextMenu<H extends HTMLElement>(
  { disabled }: UseContextMenu = { disabled: false }
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const open = contextMenu !== null || !!anchorEl;

  const onClose = () => {
    setAnchorEl(null);
    setContextMenu(null);
  };

  return {
    open,
    onContextMenu: (e: MouseEvent<H>) => {
      // Prevent the default right-click menu from opening
      e.preventDefault();
      // Prevent the click event from triggering other context menu
      e.stopPropagation();

      if (disabled) {
        return;
      }

      // Prevent right-click from re-opening the menu if it's already opened.
      // This happens when the user clicks on a button, this will set the
      // anchorEl, if the user right-clicks again, the `onContextMenu` event
      // will be triggered. We just want to close the context menu instead.
      setAnchorEl(null);
      if (anchorEl) {
        return;
      }

      setContextMenu(
        contextMenu === null
          ? {
              mouseX: e.clientX,
              mouseY: e.clientY,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    },
    onClose,
    getMenuProps: () => {
      return {
        anchorEl,
        anchorReference:
          contextMenu !== null
            ? "anchorPosition"
            : ("anchorEl" as PopoverReference),
        anchorPosition:
          contextMenu !== null
            ? {
                top: contextMenu.mouseY,
                left: contextMenu.mouseX,
              }
            : undefined,
        open,
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
        },
        onContextMenu: (e: MouseEvent) => {
          // Prevent the default right-click menu from opening
          e.preventDefault();
          // Prevent the click event from triggering other context menu
          e.stopPropagation();
        },
        onClose,
      };
    },
    getButtonProps: () => {
      return {
        "aria-haspopup": "true" as const,
        "aria-expanded": open ? ("true" as const) : undefined,
        onClick: (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();

          if (disabled) {
            return;
          }

          setAnchorEl(e.currentTarget);
        },
      };
    },
  };
}
