import { useQuery, UseQueryOptions } from "react-query";
import { format } from "date-fns";
import camelcaseKeys from "camelcase-keys";

import { JobWithUser } from "src/lib/job";

export const WorksiteJobsQueryKey = "worksite-jobs";

type UseWorksiteJobsParams = {
  worksiteId: number;
  start: Date;
  end: Date;
};

async function getWorksiteJobs(params: UseWorksiteJobsParams) {
  const url = new URL(
    `${process.env.REACT_APP_API_URL}/worksites/${params.worksiteId}/jobs`
  );

  url.searchParams.append("start", format(params.start, "yyyy/MM/dd"));
  url.searchParams.append("end", format(params.end, "yyyy/MM/dd"));

  const response = await fetch(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch worksite jobs.");
  }

  const data = await response.json();
  return camelcaseKeys(
    data.jobs.map((job: any) => ({
      ...job,
      date: new Date(job.date),
    })),
    { deep: true }
  );
}

export function useWorksiteJobs(
  params: UseWorksiteJobsParams,
  options: UseQueryOptions<JobWithUser[]> = {}
) {
  return useQuery<JobWithUser[]>(
    [
      WorksiteJobsQueryKey,
      params.worksiteId,
      format(params.start, "yyyy/MM/dd"),
      format(params.end, "yyyy/MM/dd"),
    ],
    () => getWorksiteJobs(params),
    {
      suspense: true,
      keepPreviousData: true,
      ...options,
    }
  );
}
