import { z } from "zod";

export const createJobSchema = z.object({
  worksiteId: z.number().min(1, "Saisir un chantir"),
  duration: z.string().min(1, "Saisir une durée"),
  description: z.string().optional().nullable(),
  date: z.string(),
  userId: z.string(),
});

export const updateJobSchema = z
  .object({ id: z.number() })
  .merge(createJobSchema.omit({ date: true, userId: true }).partial());

export type CreateJobSchema = z.infer<typeof createJobSchema>;
export type UpdateJobSchema = z.infer<typeof updateJobSchema>;
