import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { frCH } from "date-fns/locale/fr-CH";

// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { DefaultLayout } from "./layouts/DefaultLayout/DefaultLayout";

import { ErrorRoute } from "./routes/ErrorRoute";
import { ProtectedRoute } from "./components/ProtectedRoute";

import { LoginRoute } from "./routes/LoginRoute";
import { EmployeesRoute } from "./routes/EmployeesRoute";
import { EmployeeRoute } from "./routes/EmployeeRoute";
import { WorksitesRoute } from "./routes/WorksitesRoute";
import { WorksiteRoute } from "./routes/WorksiteRoute";
import { TrackingDashboardRoute } from "./routes/TrackingDashboardRoute";
import { TimeTrackingRoute } from "./routes/TimeTrackingRoute";
import { FilesRoute } from "./routes/FilesRoute";
import { JobTypesRoute } from "./routes/JobTypesRoute";

import { AuthProvider } from "./contexts/AuthContext";
import { ToastContextProvider } from "./contexts/ToastContext";

import { WorksiteType } from "./lib/worksite";
import { theme } from "./lib/theme";

import "./index.css";
import { PlanningRoute } from "./routes/PlanningRoute";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Helmet>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
      />
      <meta name="robots" content="noindex,follow" />
    </Helmet>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frCH}>
          <ToastContextProvider>
            <BrowserRouter>
              <AuthProvider>
                <Routes>
                  <Route errorElement={<ErrorRoute />}>
                    <Route path="login" element={<LoginRoute />} />
                    <Route
                      element={
                        <ProtectedRoute>
                          <DefaultLayout />
                        </ProtectedRoute>
                      }
                    >
                      <Route path="/" element={<Navigate to="/files" />} />
                      <Route path="employees/:id" element={<EmployeeRoute />} />
                      <Route
                        path="employees"
                        element={<EmployeesRoute title="Employés" />}
                      />
                      <Route
                        path="worksites/:id"
                        element={<WorksiteRoute type={WorksiteType.WORKSITE} />}
                      />
                      <Route
                        path="worksites"
                        element={<WorksitesRoute title="Chantiers" />}
                      />
                      <Route
                        path="job-types"
                        element={<JobTypesRoute title="Types de tâches" />}
                      />
                      <Route
                        path="job-types/:id"
                        element={<WorksiteRoute type={WorksiteType.ACTIVITY} />}
                      />
                      <Route path="planning" element={<PlanningRoute />} />
                      <Route
                        path="tracking-dashboard"
                        element={
                          <TrackingDashboardRoute title="Suivi des heures" />
                        }
                      />
                      <Route
                        path="time-tracking"
                        element={<TimeTrackingRoute title="Suivi des heures" />}
                      />
                      <Route path="files" element={<FilesRoute />} />
                      <Route path="files/:id" element={<FilesRoute />} />
                    </Route>
                  </Route>
                </Routes>
              </AuthProvider>
            </BrowserRouter>
          </ToastContextProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
