import { Select, SelectProps } from "@mui/material";

import { durationFormatter } from "src/lib/intl";

export function DurationSelect(props: SelectProps) {
  // TODO: Use MUI options on desktop, native on mobile
  return (
    <Select {...props} native>
      {[...Array(48)]
        .map((_, i) => durationFormatter.format((i + 1) * 15 * 60))
        .map((duration) => (
          <option key={duration} value={duration}>
            {duration}
          </option>
        ))}
    </Select>
  );
}
