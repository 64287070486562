import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isSameDay } from "date-fns";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Stack,
  Typography,
} from "@mui/material";

import { EditWorksiteDialog } from "src/components/EditWorksiteDialog";
import { JobListItem } from "src/components/JobListItem";
import { TimeTrackingTable } from "src/components/TimeTrackingTable";
import { getWeekday, useWeekDays } from "src/lib/date";
import { getWorksiteName, Worksite, WorksiteType } from "src/lib/worksite";
import { useWorksite } from "src/queries/useWorksite";
import { useWorksiteJobs } from "src/queries/useWorksiteJobs";
import { useWorksites } from "src/queries/useWorksites";
import { useSelectedWeekStore } from "src/stores/useSelectedWeekStore";
import { useUpdateJob } from "src/queries/useUpdateJob";
import { useToast } from "src/contexts/ToastContext";
import { useDeleteJob } from "src/queries/useDeleteJob";
import { useUpdateWorksite } from "src/queries/useUpdateWorksite";
import { useDisplayWeekSelector } from "src/hooks/useDisplayWeekSelector";
import { durationFormatter } from "src/lib/intl";
import { EditJobTypeDialog } from "src/components/EditJobTypeDialog";
import { useRouteState } from "src/stores/useRouteState";
import { WorksiteStatsDialog } from "src/components/WorksiteStatsDialog";
import { Head } from "src/ui/Head";

type WorksiteRouteProps = {
  type: Worksite["type"];
};

export function WorksiteRoute(props: WorksiteRouteProps) {
  useDisplayWeekSelector(true);

  const day = getWeekday(new Date());

  const [, setDate] = useState(day);
  const [statOpen, setStatOpen] = useState(false);
  const [updateWorksiteOpen, setUpdateWorksiteOpen] = useState(false);

  const { showToast } = useToast();

  const routeParams = useParams();
  const worksiteId = Number(routeParams.id);

  const [currentWeekIndex, currentYear] = useSelectedWeekStore((state) => [
    state.currentWeekIndex,
    state.currentYear,
  ]);

  const { resource, setResource } = useRouteState();

  // Keep track of the current worksite
  useEffect(() => {
    if (resource?.id !== worksiteId) {
      setResource({
        id: worksiteId,
        type: "worksite",
      });
    }

    return () => {
      setResource(null);
    };
  }, []);

  const { startDate, endDate, days } = useWeekDays({
    weekIndex: currentWeekIndex,
    year: currentYear,
  });

  const { data: worksite } = useWorksite(worksiteId);
  const { data: jobs } = useWorksiteJobs({
    worksiteId,
    start: startDate,
    end: endDate,
  });
  const { data: worksites } = useWorksites({ type: worksite?.type });

  const updateJobMutation = useUpdateJob({
    onSuccess() {
      showToast("L'enregistrement a bien été modifié");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const deleteJobMutation = useDeleteJob({
    onSuccess() {
      showToast("L'enregistrement a bien été supprimé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateWorksiteMutation = useUpdateWorksite({
    onSuccess() {
      showToast(
        `Le ${
          props.type === WorksiteType.WORKSITE ? "chantier" : "type de tâche"
        } a bien été mis à jour`
      );
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  if (!worksite || !jobs || !worksites) return null;

  return (
    <>
      <Head title={worksite.name} />
      <Box p={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">{getWorksiteName(worksite)}</Typography>
          <ButtonGroup>
            {props.type === WorksiteType.ACTIVITY && (
              <Button onClick={() => setStatOpen(true)}>Statistiques</Button>
            )}
            <Button onClick={() => setUpdateWorksiteOpen(true)}>
              Modifier
            </Button>
          </ButtonGroup>
        </Box>
        <Stack direction="row" spacing={1} mt={1}>
          {props.type === WorksiteType.WORKSITE && (
            <>
              <Chip
                label={`Cible: ${durationFormatter.format(
                  (worksite.targetTime as number) * 3600
                )} heures`}
                title="Heures cible"
              />
              <Chip
                label={`Total: ${durationFormatter.format(
                  worksite.totalTime as number
                )} heures`}
                title="Heures réalisées au total"
              />
            </>
          )}
          {props.type === WorksiteType.ACTIVITY && (
            <Chip
              label={`Cette année: ${durationFormatter.format(
                worksite.totalTimeYear as number
              )} heures`}
              title="Heures réalisées cette année"
            />
          )}
        </Stack>
      </Box>
      <TimeTrackingTable
        defaultSelectedDate={day}
        jobs={jobs}
        onDateChange={setDate}
        days={days}
        renderDate={(date) => (
          <>
            {jobs
              .filter((job) => isSameDay(job.date, new Date(date)))
              .map((job) => (
                <JobListItem
                  key={job.id}
                  job={{
                    ...job,
                    worksite,
                  }}
                  primary={`${job.user.firstName} ${job.user.lastName}`}
                  worksites={worksites}
                  onUpdate={updateJobMutation.mutate}
                  onDelete={deleteJobMutation.mutate}
                />
              ))}
          </>
        )}
      />
      {statOpen && (
        <WorksiteStatsDialog
          worksiteId={worksite.id}
          open={statOpen}
          onClose={() => setStatOpen(false)}
        />
      )}
      {worksite.type === WorksiteType.WORKSITE && (
        <EditWorksiteDialog
          open={updateWorksiteOpen}
          worksite={worksite}
          onClose={() => setUpdateWorksiteOpen(false)}
          onConfirm={updateWorksiteMutation.mutate}
        />
      )}
      {worksite.type === WorksiteType.ACTIVITY && (
        <EditJobTypeDialog
          open={updateWorksiteOpen}
          jobType={worksite}
          onClose={() => setUpdateWorksiteOpen(false)}
          onConfirm={updateWorksiteMutation.mutate}
        />
      )}
    </>
  );
}
