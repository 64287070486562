import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { WorksiteRow } from "./WorksiteRow";
import { Column, getComparator, Order } from "src/lib/table";
import {
  getWorksiteStateText,
  Worksite,
  WorksiteState,
} from "src/lib/worksite";
import { WithRequired } from "src/lib/types";
import { TableFilterPopover } from "src/ui/TableFilterPopover";

export interface Data {
  number: string;
  name: string;
  address: string;
  location: string;
  state: string;
  targetTime: number | null;
  hours: number;
}

type WorksiteTableProps = {
  worksites: Array<
    WithRequired<
      Worksite,
      "address" | "state" | "location" | "targetTime" | "totalTime"
    > & {
      hours: number;
    }
  >;
  onSelect: (worksite: Worksite) => void;
  onUpdate: () => void;
  onDelete: () => void;
};

const columns: Column<Data>[] = [
  { id: "number", label: "N°", minWidth: 70 },
  { id: "name", label: "Nom" },
  { id: "address", label: "Adresse" },
  { id: "location", label: "Lieu" },
  {
    id: "state",
    label: "État",
    filters: [
      {
        type: "checkbox",
        options: [
          {
            label: getWorksiteStateText(WorksiteState.PLANNED),
            value: WorksiteState.PLANNED,
          },
          {
            label: getWorksiteStateText(WorksiteState.IN_PROGRESS),
            value: WorksiteState.IN_PROGRESS,
          },
          {
            label: getWorksiteStateText(WorksiteState.COMPLETED),
            value: WorksiteState.COMPLETED,
          },
        ],
      },
    ],
    minWidth: 130,
  },
  { id: "targetTime", label: "Heures cible", maxWidth: 120, align: "right" },
  { id: "hours", label: "Heures", maxWidth: 80, align: "right" },
];

export function WorksiteTable(props: WorksiteTableProps) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("number");
  const [filter, setFilter] = useState({
    [WorksiteState.PLANNED]: false,
    [WorksiteState.IN_PROGRESS]: true,
    [WorksiteState.COMPLETED]: false,
  });

  const createSortHandler = (property: keyof Data) => () => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              sortDirection={orderBy === column.id ? order : false}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
              }}
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
                sx={{ textAlign: "left" }}
              >
                {column.label}
              </TableSortLabel>
              {column.filters &&
                column.filters.map((columnFilter, i) => (
                  <TableFilterPopover
                    key={i}
                    options={columnFilter.options}
                    filter={filter}
                    onChange={(value, checked) =>
                      setFilter({
                        ...filter,
                        [value]: checked,
                      })
                    }
                  />
                ))}
            </TableCell>
          ))}
          <TableCell
            component="div"
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          ></TableCell>
        </TableRow>
      </TableHead>
      <TableBody component="div">
        {props.worksites
          .slice()
          .sort(getComparator(order, orderBy))
          .filter(
            (worksite) =>
              (filter.PLANNED && worksite.state === WorksiteState.PLANNED) ||
              (filter.IN_PROGRESS &&
                worksite.state === WorksiteState.IN_PROGRESS) ||
              (filter.COMPLETED && worksite.state === WorksiteState.COMPLETED)
          )
          .map((worksite) => (
            <WorksiteRow
              key={worksite.id}
              worksite={worksite}
              onSelect={props.onSelect}
              onUpdate={props.onUpdate}
              onDelete={props.onDelete}
            />
          ))}
      </TableBody>
    </Table>
  );
}
