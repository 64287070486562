import { z } from "zod";

import { Role } from "src/lib/user";

export const createUserSchema = z.object({
  firstName: z.string().min(1, "Saisir un prénom"),
  lastName: z.string().min(1, "Saisir un nom"),
  username: z.string().min(1, "Saisir un nom d'utilisateur"),
  password: z.string().min(1, "Saisir un mot de passe"),
  role: z.enum([Role.ADMIN, Role.USER]),
});

export const updateUserSchema = z
  .object({ id: z.number() })
  .merge(createUserSchema.partial());

export type CreateUserSchema = z.infer<typeof createUserSchema>;
export type UpdateUserSchema = z.infer<typeof updateUserSchema>;
