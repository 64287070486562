import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";

import { WorksiteAssignmentQueryKey } from "./useWorksiteAssignment";

type UseDeleteWorksiteAssignmentOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

export function UseDeleteWorksiteAssignment(
  options: UseDeleteWorksiteAssignmentOptions = {}
) {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>(
    async (id: number) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/worksite-assignments/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete worksite assigment.");
      }

      const data = await response.json();
      return camelcaseKeys(data.worksite_assignment, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(WorksiteAssignmentQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
