import { MenuItem, Select, SelectProps } from "@mui/material";
import {
  getWorksiteStateText,
  WorksiteStates,
  WorksiteState,
} from "src/lib/worksite";

export function WorksiteStateSelect(
  props: SelectProps = { defaultValue: WorksiteState.PLANNED }
) {
  return (
    <Select fullWidth {...props}>
      {WorksiteStates.map((state) => (
        <MenuItem key={state} value={state}>
          {getWorksiteStateText(state)}
        </MenuItem>
      ))}
    </Select>
  );
}
