import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";

import { FilesQueryKey } from "./useFiles";

type UseDeleteFileOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

export function useDeleteFile(options: UseDeleteFileOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>(
    async (id: number) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete file.");
      }

      const data = await response.json();
      return camelcaseKeys(data.user, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        // NOTE: We are invalidating every files queries, we might want to be
        // more specific
        queryClient.invalidateQueries(FilesQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
