import { useDropzone } from "react-dropzone";
import { alpha, Box, useTheme } from "@mui/material";

type DropZoneProps = {
  children: React.ReactNode;
  onContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDrop: (acceptedFiles: File[]) => void;
};

export function DropZone({ children, onContextMenu, onDrop }: DropZoneProps) {
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
  });

  return (
    <Box
      {...getRootProps()}
      onContextMenu={onContextMenu}
      sx={{
        display: "flex",
        flex: 1,
        border: "2px dashed",
        borderColor: isDragActive ? "primary.main" : "transparent",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: isDragActive
          ? alpha(theme.palette.primary.main, 0.1)
          : "transparent",
        outline: "none",
      }}
    >
      <input {...getInputProps()} />
      {children}
    </Box>
  );
}
