// This file contains utility functions, interfaces and types for working with
// MUI tables

function stringCompare(a: string, b: string) {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: "base",
  });
}

function baseCompare<T>(a: T, b: T) {
  if (b < a) {
    return -1;
  }

  if (b > a) {
    return 1;
  }

  return 0;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // TODO: Get this out of the comparator...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(a as any).mimeType && (b as any).mimeType) {
    return -1;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((a as any).mimeType && !(b as any).mimeType) {
    return 1;
  }

  if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
    return stringCompare(b[orderBy] as string, a[orderBy] as string);
  }

  return baseCompare(a[orderBy], b[orderBy]);
}

export type Order = "asc" | "desc";

type ComparedValue = number | string | boolean | null | Date | undefined;

export function getComparator<Key extends keyof never>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: ComparedValue },
  b: { [key in Key]: ComparedValue }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export interface HeadCell<T> {
  id: keyof T;
  label: string;
}

export interface Column<T> {
  id: keyof T;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: unknown) => string;
  isDesktop?: boolean;
  filters?: Array<{
    type: "checkbox";
    options: Array<{
      label: string;
      value: string;
    }>;
  }>;
}
