import { colors, File, isFolder, isLink } from "src/lib/file";

import EmailIcon from "@mui/icons-material/Email";
import FolderIcon from "@mui/icons-material/Folder";
import FileLinkIcon from "@mui/icons-material/Link";
import { ReactComponent as FilePdfIcon } from "src/svgs/file-pdf.svg";
import { ReactComponent as FileWordIcon } from "src/svgs/file-word.svg";
import { ReactComponent as FileExcelIcon } from "src/svgs/file-excel.svg";
import { ReactComponent as FilePowerPointIcon } from "src/svgs/file-powerpoint.svg";
import { ReactComponent as FileTxtIcon } from "src/svgs/file-txt.svg";
import { ReactComponent as FileMp4Icon } from "src/svgs/file-mp4.svg";
import { ReactComponent as FileImageIcon } from "src/svgs/file-image.svg";
import { ReactComponent as FileDWG } from "src/svgs/file-dwg.svg";
import { ReactComponent as DefaultFileIcon } from "src/svgs/file.svg";

export type FileIconProps = {
  file: File;
};

const iconProps = {
  width: 24,
  height: 24,
  // maxWidth: 24,
};

const icons = {
  "application/pdf": <FilePdfIcon {...iconProps} fill="#cd2352" />,
  "video/mp4": <FileMp4Icon {...iconProps} fill="#1036df" />,
  "image/jpeg": <FileImageIcon {...iconProps} fill="#f5a623" />,
  "image/gif": <FileImageIcon {...iconProps} fill="#f5a623" />,
  "image/svg+xml": <FileImageIcon {...iconProps} fill="#f5a623" />,
  "image/png": <FileImageIcon {...iconProps} fill="#f5a623" />,
  "image/heic": <FileImageIcon {...iconProps} fill="#f5a623" />,
  "text/plain": <FileTxtIcon {...iconProps} fill="#1d8770" />,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
    <FileWordIcon {...iconProps} fill="#2A548F" />
  ),
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
    <FileExcelIcon {...iconProps} fill="#016D35" />
  ),
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": (
    <FilePowerPointIcon {...iconProps} fill="#016D35" />
  ),
  "application/vnd.ms-outlook": (
    <EmailIcon
      {...iconProps}
      style={{
        fill: "#3276d0",
      }}
    />
  ),
  "message/rfc822": (
    <EmailIcon
      {...iconProps}
      style={{
        fill: "#3276d0",
      }}
    />
  ),
  "image/vnd.dwg": (
    <FileDWG
      {...iconProps}
      style={{
        fill: "#13488d",
      }}
    />
  ),
} as Record<string, React.ReactElement>;

export function FileIcon(props: FileIconProps) {
  if (props.file.mimeType && icons[props.file.mimeType]) {
    return icons[props.file.mimeType];
  }

  if (isLink(props.file)) {
    return <FileLinkIcon sx={{ fill: "#237ef5" }} />;
  }

  if (isFolder(props.file)) {
    return (
      <FolderIcon
        sx={{
          fill: props.file.color ?? colors[0],
        }}
      />
    );
  }

  return <DefaultFileIcon {...iconProps} fill="#646968" />;
}
