import { useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";
import { User } from "src/lib/user";
import { Worksite } from "src/lib/worksite";
import { File } from "src/lib/file";

type SearchResults = {
  users?: User[];
  worksites?: Worksite[];
  jobTypes?: Worksite[];
  files?: File[];
};

type SearchParams = {
  query: string;
};

export const SearchQueryKey = "search";

async function search(params: SearchParams) {
  if (params.query.length < 1) {
    return {};
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/search?q=${params.query}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch search.");
  }

  const data = await response.json();
  return camelcaseKeys(data, { deep: true });
}

export function useSearch(params: SearchParams) {
  return useQuery<SearchResults>(
    [SearchQueryKey, params.query],
    () => search(params),
    {
      keepPreviousData: true,
    }
  );
}
