import { useQuery } from "react-query";
import { format } from "date-fns";
import camelcaseKeys from "camelcase-keys";

import { WorksiteAssignment } from "src/lib/worksite-assignment";

type WorksiteAssignmentParams = {
  start: Date;
  end: Date;
};

export const WorksiteAssignmentQueryKey = "worksite-assignments";

async function getWorksiteAssignment(params: WorksiteAssignmentParams) {
  const searchParams = new URLSearchParams();
  searchParams.set("start", format(params.start, "yyyy/MM/dd"));
  searchParams.set("end", format(params.end, "yyyy/MM/dd"));

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/worksite-assignments?${searchParams}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch worksite assigments.");
  }

  const data = camelcaseKeys(await response.json(), { deep: true });

  return data.worksiteAssignments;
}

export function useWorksiteAssigments(params: WorksiteAssignmentParams) {
  return useQuery<WorksiteAssignment[]>(
    [WorksiteAssignmentQueryKey, params.start, params.end],
    () => getWorksiteAssignment(params),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
}
