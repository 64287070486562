import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "src/contexts/AuthContext";

export const ProtectedRoute = ({ children }: PropsWithChildren<{}>) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return (
      <Navigate
        to="/login?error=not-logged-in"
        replace
        state={{ from: location }}
      />
    );
  }

  return <>{children}</>;
};
