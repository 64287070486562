import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

type OutletContext = {
  setTitle: (title: string) => void;
};

export const useTitle = (title: string) => {
  const { setTitle } = useOutletContext<OutletContext>();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);
};
