/**
 * Pick the `keys` from `obj`.
 *
 * @param obj The object to pick from.
 * @param keys The keys to pick.
 * @returns The picked object.
 */
export function pick<T extends Record<string, unknown>>(
  obj: T,
  keys: readonly (keyof T)[]
): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => keys.includes(key))
  );
}
