import { Helmet } from "react-helmet";

type HeadProps = {
  title: string;
  children?: React.ReactNode;
};

export function Head(props: HeadProps) {
  return (
    <Helmet>
      <title>{props.title} - Bocherens Drive</title>
      {props.children}
    </Helmet>
  );
}
