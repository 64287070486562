import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import { FileWithPath } from "react-dropzone";
import axios from "axios";

import { FilesQueryKey } from "./useFiles";
import { useToast } from "src/contexts/ToastContext";

type UseUploadFileOptions = {
  onSuccess?: (params: UseUploadFileParams) => void;
  onError?: (err: unknown, params: UseUploadFileParams) => void;
};

type UseUploadFileParams = {
  parentId: number | null;
  files: FileWithPath[];
};

export function useUploadFiles(options: UseUploadFileOptions = {}) {
  const queryClient = useQueryClient();

  const { showToast } = useToast();

  return useMutation(
    async (params: UseUploadFileParams) => {
      const formData = new FormData();

      if (params.parentId) {
        formData.append("parent_id", params.parentId.toString());
      }

      const filePaths = [];

      for (let i = 0; i < params.files.length; i++) {
        const file = params.files[i];
        const newFile = new File([file], i.toString(), { type: file.type });

        formData.append("files[]", newFile, i.toString());

        filePaths.push(file.path);
      }

      formData.append("file_paths", JSON.stringify(filePaths));

      const toast = showToast({
        message: "Téléchargement en cours",
        duration: 0,
        progress: 0,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          onUploadProgress: (e) => {
            toast.update({
              progress: e.total ? (e.loaded / e.total) * 100 : 0,
            });
          },
        }
      );

      toast.hide();

      return camelcaseKeys(response.data.files, { deep: true });
    },
    {
      ...options,
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(FilesQueryKey);
        options.onSuccess?.(params);
      },
      onError: (err, params) => {
        options.onError?.(err, params);
      },
    }
  );
}
