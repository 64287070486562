import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { format } from "date-fns/format";
import { WorksiteAssignmentQueryKey } from "./useWorksiteAssignment";

type UseUpdateWorksiteAssignmentOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseUpdateWorksiteAssignmentParams = {
  worksiteId?: number;
  userId?: number;
  date: Date;
  index: number;
};

export function useUpdateWorksiteAssignment(
  options: UseUpdateWorksiteAssignmentOptions = {}
) {
  const queryClient = useQueryClient();

  return useMutation<
    UseUpdateWorksiteAssignmentParams,
    Error,
    UseUpdateWorksiteAssignmentParams
  >(
    async ({ ...params }: UseUpdateWorksiteAssignmentParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/worksite-assignments`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: JSON.stringify(
            snakecaseKeys(
              {
                ...params,
                date: format(params.date, "yyyy/MM/dd"),
              },
              { deep: true }
            )
          ),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update worksite assignment.");
      }

      const data = await response.json();
      return camelcaseKeys(data.worksite, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(WorksiteAssignmentQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
