import { Menu, MenuProps } from "src/ui/Menu";

import PersonAddIcon from "@mui/icons-material/PersonAdd";

type CreateUserMenuProps = {
  onCreate: () => void;
};

export function CreateUserMenu(props: MenuProps<CreateUserMenuProps>) {
  return (
    <Menu
      menuProps={props.menuProps}
      disableAutoFocusItem
      items={[
        {
          label: "Nouvel employé",
          icon: <PersonAddIcon />,
          onClick: () => {
            props.onCreate();
          },
        },
      ]}
    />
  );
}
