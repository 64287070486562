import { Menu, MenuProps } from "src/ui/Menu";

import HandymanOutlined from "@mui/icons-material/HandymanOutlined";

type EditJobTypeMenuProps = {
  onCreate: () => void;
};

export function CreateJobTypeMenu(props: MenuProps<EditJobTypeMenuProps>) {
  return (
    <Menu
      menuProps={props.menuProps}
      items={[
        {
          label: "Créer un type de tâche",
          icon: <HandymanOutlined />,
          onClick: props.onCreate,
        },
      ]}
    />
  );
}
