import { useState } from "react";

function parseItem(item: string | null, initialValue: any) {
  try {
    return item ? JSON.parse(item) : initialValue;
  } catch (e) {
    return initialValue;
  }
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);

      return parseItem(item, initialValue);
    } catch (error) {
      console.log(error);

      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      window.localStorage.setItem(
        key,
        typeof valueToStore === "string"
          ? valueToStore
          : JSON.stringify(valueToStore)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue] as const;
}
