import { create } from "zustand";

type ViewMode = "day" | "week";

type SelectedWeekState = {
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
};

const initialState = {
  viewMode: (localStorage.getItem("view.mode") ?? "day") as ViewMode,
};

export const useViewModeStore = create<SelectedWeekState>()((set) => ({
  viewMode: initialState.viewMode,
  setViewMode: (viewMode) => {
    set(() => {
      return { viewMode };
    });
  },
}));
