type SwiperImageProps = {
  image: {
    url: string;
    mimeType: string;
  };
};

export function SwiperImage(props: SwiperImageProps) {
  return (
    <img
      src={props.image.url}
      loading="lazy"
      alt=""
      style={{
        backgroundImage: "url(/imgs/loading.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        minWidth: 64,
        minHeight: 64,
      }}
    />
  );
}
