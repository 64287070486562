import { useState } from "react";
import { Box, TableContainer } from "@mui/material";

import { useContextMenu } from "src/hooks/useContextMenu";
import { WorksiteTable } from "src/components/WorksiteTable";
import { CreateWorksiteMenu } from "src/components/CreateWorkisteMenu";
import { CreateWorksiteDialog } from "src/components/CreateWorksiteDialog";
import { ConfirmDialog } from "src/components/ConfirmDialog";
import { useTitle } from "src/hooks/useTitle";
import { useToast } from "src/contexts/ToastContext";
import { EditWorksiteDialog } from "src/components/EditWorksiteDialog";
import { Worksite, WorksiteType } from "src/lib/worksite";
import { useWorksites } from "src/queries/useWorksites";
import { useCreateWorksite } from "src/queries/useCreateWorksite";
import { useDeleteWorksite } from "src/queries/useDeleteWorksite";
import { useUpdateWorksite } from "src/queries/useUpdateWorksite";
import { useDisplayWeekSelector } from "src/hooks/useDisplayWeekSelector";
import { WithRequired } from "src/lib/types";
import { Head } from "src/ui/Head";

type WorksitesRouteProps = {
  title: string;
};

export const WorksitesRoute = ({ title }: WorksitesRouteProps) => {
  useTitle(title);
  useDisplayWeekSelector(false);

  const [createWorksiteOpen, setCreateWorksiteOpen] = useState(false);
  const [updateWorksiteOpen, setUpdateWorksiteOpen] = useState(false);
  const [deleteWorksiteOpen, setDeleteWorksiteOpen] = useState(false);
  const [selectedWorksite, setSelectedWorksite] = useState<Worksite>();

  const contextMenu = useContextMenu();

  const { showToast } = useToast();

  const { data } = useWorksites({ type: WorksiteType.WORKSITE });
  // TODO: fix this
  const worksites = data! as unknown as Array<
    WithRequired<
      Worksite,
      "address" | "state" | "location" | "targetTime" | "totalTime"
    > & {
      hours: number;
    }
  >;

  const createWorksiteMutation = useCreateWorksite({
    onSuccess() {
      showToast("Le chantier a bien été créé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateWorksiteMutation = useUpdateWorksite({
    onSuccess() {
      showToast("Le chantier a bien été mis à jour");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const deleteWorksiteMutation = useDeleteWorksite({
    onSuccess() {
      showToast("Le chantier a bien été supprimé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  if (!worksites) return null;

  return (
    <>
      <Head title="Chantiers" />
      <Box flex={1} onContextMenu={contextMenu.onContextMenu}>
        <TableContainer sx={{ height: "calc(100vh - 64px)" }}>
          <WorksiteTable
            worksites={worksites}
            onSelect={setSelectedWorksite}
            onUpdate={() => setUpdateWorksiteOpen(true)}
            onDelete={() => setDeleteWorksiteOpen(true)}
          />
        </TableContainer>
      </Box>
      <CreateWorksiteMenu
        menuProps={contextMenu.getMenuProps()}
        onCreate={() => setCreateWorksiteOpen(true)}
      />
      <>
        <CreateWorksiteDialog
          open={createWorksiteOpen}
          onClose={() => setCreateWorksiteOpen(false)}
          onConfirm={createWorksiteMutation.mutate}
        />
        {selectedWorksite && updateWorksiteOpen && (
          <EditWorksiteDialog
            open={updateWorksiteOpen}
            worksite={selectedWorksite}
            onClose={() => setUpdateWorksiteOpen(false)}
            onConfirm={updateWorksiteMutation.mutate}
          />
        )}
        {selectedWorksite && (
          <ConfirmDialog
            open={deleteWorksiteOpen}
            title="Supprimer le chantier"
            content="Êtes-vous sûr de vouloir supprimer ce chantier ?"
            cancelText="Annuler"
            confirmText="Supprimer"
            onClose={() => setDeleteWorksiteOpen(false)}
            onConfirm={() => deleteWorksiteMutation.mutate(selectedWorksite.id)}
          />
        )}
      </>
    </>
  );
};
