import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  createWorksiteSchema,
  CreateWorksiteSchema,
} from "src/schema/worksite.schema";
import { WorksiteType } from "src/lib/worksite";
import { Dialog } from "src/ui/Dialog";

type CreateJobTypeDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (jobType: CreateWorksiteSchema) => void;
};

export function CreateJobTypeDialog(props: CreateJobTypeDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateWorksiteSchema>({
    resolver: zodResolver(createWorksiteSchema),
    defaultValues: {
      type: WorksiteType.ACTIVITY,
      isDescriptionRequired: false,
      isVisible: true,
    },
  });

  const handleClose = () => {
    props.onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: CreateWorksiteSchema) => {
    handleClose();

    props.onConfirm(data);
  };

  return (
    <Dialog
      open={props.open}
      title="Créer un type tâche"
      cancelText="Annuler"
      confirmText="Créer"
      onClose={props.onClose}
      onCancel={handleCancel}
      onSubmit={handleSubmit(onSubmit)}
      form
    >
      <TextField
        id="number"
        autoFocus
        margin="dense"
        label="N°"
        fullWidth
        variant="outlined"
        inputProps={register("number", { required: true })}
        error={!!errors.number}
        helperText={errors.number?.message ?? " "}
      />
      <TextField
        id="name"
        margin="dense"
        label="Nom"
        fullWidth
        variant="outlined"
        inputProps={register("name", { required: true })}
        error={!!errors.name}
        helperText={errors.name?.message ?? " "}
      />
      <FormGroup>
        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Controller
              name="isDescriptionRequired"
              control={control}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Descriptif requis"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Controller
              name="isVisible"
              control={control}
              render={({ field: props }) => (
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
          }
          label="Visible par les employés"
        />
      </FormGroup>
    </Dialog>
  );
}
