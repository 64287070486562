import { Dialog } from "src/ui/Dialog";

import { CalendarWeekPicker } from "./CalendarWeekPicker";

type SelectWorkWeekDialogProps = {
  open: boolean;
  weekIndex: number;
  year: number;
  badges: Date[];
  onClose: () => void;
  onMonthChange?: (month: Date) => void;
  onConfirm: (weekNumber: number, year: number) => void;
};

export function SelectWorkWeekDialog(props: SelectWorkWeekDialogProps) {
  const handleClose = () => {
    props.onClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Dialog
      open={props.open}
      title="Selectionner une semaine"
      cancelText="Annuler"
      onCancel={handleCancel}
      onClose={props.onClose}
      fullWidth={false}
      noConfirm
    >
      <CalendarWeekPicker
        weekIndex={props.weekIndex}
        year={props.year}
        onMonthChange={props.onMonthChange}
        onWeekChange={(weekIndex, year) => {
          props.onConfirm(weekIndex, year);
          props.onClose();
        }}
        badges={props.badges}
      />
    </Dialog>
  );
}
