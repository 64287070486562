import { useCallback, useRef, useState } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";

import UploadIcon from "@mui/icons-material/Upload";
import FolderIcon from "@mui/icons-material/Folder";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";

import { useToast } from "src/contexts/ToastContext";
import { CreateJobTypeDialog } from "src/components/CreateJobTypeDialog";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CreateFolderDialog } from "./CreateFolderDialog";
import { CreateUserDialog } from "./CreateUserDialog";
import { CreateWorksiteDialog } from "./CreateWorksiteDialog";
import { useCreateUser } from "src/queries/useCreateUser";
import { useCreateWorksite } from "src/queries/useCreateWorksite";
import { useCreateFolder } from "src/queries/useCreateFolder";
import { WorksiteType } from "src/lib/worksite";

type ActionsSpeedDialProps = {
  disabled?: boolean;
};

/**
 * NOTE: This component requires that the `[cuid]` parameter exists in the URL
 * for `/files` pages.
 */
export function ActionsSpeedDial(props: ActionsSpeedDialProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [createWorksiteOpen, setCreateWorksiteOpen] = useState(false);
  const [createJobTypeOpen, setCreateJobTypeOpen] = useState(false);

  const navigate = useNavigate();
  const routeParams = useParams();
  const location = useLocation();

  const { showToast } = useToast();

  const createUserMutation = useCreateUser({
    onSuccess() {
      showToast("L'employé a bien été créé");
      navigate("/employees");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const createFolderMutation = useCreateFolder({
    onSuccess() {
      showToast("Le dossier a été créé avec succès");
      // Do not navigate to the `/files` page if the user is inside a folder
      if (!location.pathname.startsWith("/files") && !routeParams.cuid) {
        navigate("/files");
      }
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const createWorksiteMutation = useCreateWorksite({
    onSuccess(params) {
      if (params.type === WorksiteType.WORKSITE) {
        showToast("Le chantier a bien été créé");
        navigate("/worksites");
      } else if (params.type === WorksiteType.ACTIVITY) {
        showToast("Le type de tâche a bien été créé");
        navigate("/job-types");
      }
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  /**
   * Either upload the file in the current folder, or at the root level
   */
  const onUploadFiles = useCallback(
    async (acceptedFiles: File[]) => {
      const formData = new FormData();
      if (routeParams.cuid) {
        formData.append("parentId", routeParams.cuid as string);
      }

      acceptedFiles.forEach((file) => {
        formData.append("files", file, file.name);
      });

      const response = await fetch(`/api/files`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        showToast("Les fichiers ont bien été téléchargés");
        // Do not navigate to the `/files` page if the user is the user is inside
        // a folder
        if (!location.pathname.startsWith("/files") && !routeParams.id) {
          navigate("/files");
        }
      } else {
        showToast("Une erreur est survenue lors du téléchargement");
      }
    },
    [location.pathname, navigate, routeParams.cuid, routeParams.id, showToast]
  );

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <SpeedDial
          ariaLabel="Actions"
          sx={{ position: "fixed", bottom: 36, right: 36 }}
          color="primary"
          icon={<SpeedDialIcon />}
          open={open}
          onClick={() => setOpen(!open)}
          FabProps={{
            disabled: props.disabled,
          }}
        >
          <SpeedDialAction
            icon={<FolderIcon />}
            tooltipTitle={"Nouveau dossier"}
            tooltipOpen
            onClick={() => setCreateFolderOpen(true)}
          />
          <SpeedDialAction
            icon={<HandymanOutlinedIcon />}
            tooltipTitle={"Nouveau type de tâche"}
            tooltipOpen
            onClick={() => setCreateJobTypeOpen(true)}
          />
          <SpeedDialAction
            icon={<HomeWorkOutlinedIcon />}
            tooltipTitle={"Nouveau chantier"}
            tooltipOpen
            onClick={() => setCreateWorksiteOpen(true)}
          />
          <SpeedDialAction
            icon={<UploadIcon />}
            tooltipTitle={"Charger un fichier"}
            tooltipOpen
            onClick={() => inputRef.current?.click()}
          />
          <SpeedDialAction
            icon={<PersonAddIcon />}
            tooltipTitle={"Nouvel employé"}
            tooltipOpen
            onClick={() => setCreateUserOpen(true)}
          />
        </SpeedDial>
      </ClickAwayListener>
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        multiple
        onChange={(e) => {
          onUploadFiles(Array.from(e.target.files as ArrayLike<File>));
        }}
      />
      <CreateUserDialog
        open={createUserOpen}
        onClose={() => setCreateUserOpen(false)}
        onConfirm={createUserMutation.mutate}
      />
      <CreateFolderDialog
        open={createFolderOpen}
        onClose={() => setCreateFolderOpen(false)}
        onConfirm={({ name }) =>
          createFolderMutation.mutate({
            name,
            parentId: routeParams.id ? Number(routeParams.id) : null,
          })
        }
      />
      <CreateWorksiteDialog
        open={createWorksiteOpen}
        onClose={() => setCreateWorksiteOpen(false)}
        onConfirm={createWorksiteMutation.mutate}
      />
      <CreateJobTypeDialog
        open={createJobTypeOpen}
        onClose={() => setCreateJobTypeOpen(false)}
        onConfirm={createWorksiteMutation.mutate}
      />
    </>
  );
}
