import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { File, getContentsUrl } from "src/lib/file";

type TextFileDialogProps = {
  file: Pick<File, "id" | "name" | "mimeType">;
  open: boolean;
  onClose: () => void;
};

export function TextFileDialog(props: TextFileDialogProps) {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{ sx: { height: "calc(100% - 200px)" } }}
        scroll="paper"
      >
        <DialogTitle
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            bgcolor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          {props.file.name}
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              color: "primary.contrastText",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: "hidden", p: 0 }}>
          <iframe
            title="Fichier texte"
            src={getContentsUrl(props.file)}
            style={{
              border: "none",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
