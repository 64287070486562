import { useState } from "react";
import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FileIcon } from "src/components/FileIcon";
import { SearchBar } from "src/components/SearchBar";
import { SearchResult } from "src/components/SearchResult";
import { useDebounce } from "src/hooks/useDebounce";
import { getWorksiteName } from "src/lib/worksite";
import { useSearch } from "src/queries/useSearch";
import { Loading } from "src/ui/Loading";

import PersonIcon from "@mui/icons-material/Person";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import HandyManOutlinedIcon from "@mui/icons-material/HandymanOutlined";

export function GlobalSearch() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const debouncedSearchQuery = useDebounce(searchQuery, 150);
  const { data, isLoading } = useSearch({
    query: debouncedSearchQuery,
  });

  if (isMobile) return null;

  return (
    <Box position="relative" width="100%" maxWidth={700}>
      <ClickAwayListener onClickAway={() => setIsSearchOpen(false)}>
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onFocus={() => setIsSearchOpen(true)}
          onBlur={() => setIsSearchOpen(false)}
        />
      </ClickAwayListener>
      {isSearchOpen && searchQuery !== "" && (
        <Box
          position="absolute"
          sx={{
            bgcolor: "background.paper",
            color: "text.primary",
            width: "100%",
            border: 1,
            borderColor: "divider",
            borderRadius: 1,
            mt: 0.5,
          }}
        >
          {!isLoading &&
            data?.users?.length === 0 &&
            data?.worksites?.length === 0 &&
            data?.jobTypes?.length === 0 &&
            data?.files?.length === 0 && (
              <Typography textAlign="center" p={2}>
                Aucun résultat ne correspond à votre recherche
              </Typography>
            )}
          {isLoading && (
            <Box px={0.5} py={1.5}>
              <Loading />
            </Box>
          )}
          {(data?.files?.length ?? 0) > 0 && (
            <>
              <List>
                {data?.files?.map((file) => (
                  <SearchResult
                    key={file.id}
                    onClick={() => setSearchQuery("")}
                    icon={<FileIcon file={file} />}
                    label={file.name}
                    href={file.parentId ? `/files/${file.parentId}` : "/files"}
                  />
                ))}
              </List>
              <Divider />
            </>
          )}
          {(data?.worksites?.length ?? 0) > 0 && (
            <>
              <List>
                {data?.worksites?.map((worksite) => (
                  <SearchResult
                    key={worksite.id}
                    onClick={() => setSearchQuery("")}
                    icon={<HomeWorkOutlinedIcon />}
                    label={getWorksiteName(worksite)}
                    href={`/worksites/${worksite.id}`}
                  />
                ))}
              </List>
              <Divider />
            </>
          )}
          {(data?.jobTypes?.length ?? 0) > 0 && (
            <>
              <List>
                {data?.jobTypes?.map((jobType) => (
                  <SearchResult
                    key={jobType.id}
                    onClick={() => setSearchQuery("")}
                    icon={<HandyManOutlinedIcon />}
                    label={getWorksiteName(jobType)}
                    href={`/job-types/${jobType.id}`}
                  />
                ))}
              </List>
              <Divider />
            </>
          )}
          {(data?.users?.length ?? 0) > 0 && (
            <List>
              {data?.users?.map((user) => (
                <SearchResult
                  key={user.id}
                  onClick={() => setSearchQuery("")}
                  icon={<PersonIcon />}
                  label={`${user.firstName} ${user.lastName}`}
                  href={`/employees/${user.id}`}
                />
              ))}
            </List>
          )}
        </Box>
      )}
    </Box>
  );
}
