import { create } from "zustand";

type ResourceType = "worksite" | "user";

type RouteState = {
  resource: {
    id: number;
    type: ResourceType;
  } | null;
  setResource: (resource: { id: number; type: ResourceType } | null) => void;
};

/**
 * This stores the current route state
 */
export const useRouteState = create<RouteState>()((set) => ({
  resource: null,
  setResource: (resource) => set({ resource }),
}));
