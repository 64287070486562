import { useQuery } from "react-query";
import { format } from "date-fns";
import camelcaseKeys from "camelcase-keys";

import { User } from "src/lib/user";

type UserWithJobsTotal = User & {
  jobs: {
    date: Date;
    total: number;
  }[];
};

type UserJobsParams = {
  start: Date;
  end: Date;
  disabled?: boolean;
};

export const JobsQueryKey = "jobs";

async function getJobs(params: UserJobsParams) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/jobs?start=${format(
      params.start,
      "yyyy/MM/dd"
    )}&end=${format(params.end, "yyyy/MM/dd")}&disabled=${params.disabled}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch jobs.");
  }

  const data = await response.json();
  return camelcaseKeys(
    data.users.map((user: any) => ({
      ...user,
      jobs: user.jobs.map((job: any) => ({
        ...job,
        date: new Date(job.date),
      })),
    })),
    { deep: true }
  );
}

export function useJobs(params: UserJobsParams) {
  return useQuery<UserWithJobsTotal[]>(
    [JobsQueryKey, params.start, params.end],
    () => getJobs(params),
    {
      keepPreviousData: true,
    }
  );
}
