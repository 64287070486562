import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { FilesQueryKey } from "./useFiles";

type UseUpdateFileOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseUpdateFileParams = { id: number } & Partial<{
  parentId: number | null;
  name: string;
  url: string | null;
  color: string;
}>;

export function useUpdateFile(options: UseUpdateFileOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation<UseUpdateFileParams, Error, UseUpdateFileParams>(
    async ({ id, ...params }: UseUpdateFileParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(snakecaseKeys(params, { deep: true })),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update file.");
      }

      const data = await response.json();
      return camelcaseKeys(data.file, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(FilesQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
