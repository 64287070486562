import { getISOWeek, getYear } from "date-fns";
import { create } from "zustand";

type SelectedWeekState = {
  currentWeekIndex: number;
  currentYear: number;
  nextWeek: () => void;
  previousWeek: () => void;
  setCurrentWeekIndex: (index: number) => void;
  resetCurrentWeekIndex: () => void;
  setCurrentYear: (year: number) => void;
};

const today = new Date();

const initialState = {
  currentWeekIndex: getISOWeek(today),
  currentYear: getYear(today),
};

export const useSelectedWeekStore = create<SelectedWeekState>()((set) => ({
  currentWeekIndex: initialState.currentWeekIndex,
  currentYear: initialState.currentYear,
  nextWeek: () =>
    set((state) => {
      const nextWeekIndex = (state.currentWeekIndex % 52) + 1;
      const currentYear = state.currentYear + (nextWeekIndex === 1 ? 1 : 0);

      return { currentWeekIndex: nextWeekIndex, currentYear };
    }),
  previousWeek: () =>
    set((state) => {
      const previousWeekIndex =
        state.currentWeekIndex === 1 ? 52 : state.currentWeekIndex - 1;
      const currentYear =
        state.currentYear - (previousWeekIndex === 52 ? 1 : 0);

      return { currentWeekIndex: previousWeekIndex, currentYear };
    }),
  setCurrentWeekIndex: (index: number) => set({ currentWeekIndex: index }),
  resetCurrentWeekIndex: () =>
    set({
      currentWeekIndex: initialState.currentWeekIndex,
      currentYear: initialState.currentYear,
    }),
  setCurrentYear: (year: number) => set({ currentYear: year }),
}));
