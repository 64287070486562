import { Link } from "react-router-dom";
import { IconButton, TableCell, TableRow } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { EditWorksiteMenu } from "src/components/EditWorksiteMenu";
import { useContextMenu } from "src/hooks/useContextMenu";
import { getWorksiteStateText, Worksite } from "src/lib/worksite";
import { durationFormatter } from "src/lib/intl";

type WorksiteRowProps = {
  worksite: Worksite & {
    totalTime: Exclude<Worksite["totalTime"], undefined>;
    state: Exclude<Worksite["state"], undefined>;
  };
  onSelect: (worksite: Worksite) => void;
  onUpdate: () => void;
  onDelete: () => void;
};

export function WorksiteRow(props: WorksiteRowProps) {
  const contextMenu = useContextMenu();

  return (
    <TableRow
      hover
      sx={{ cursor: "pointer" }}
      onContextMenu={contextMenu.onContextMenu}
      component={Link}
      to={`/worksites/${props.worksite.id}`}
    >
      <TableCell component="div">{props.worksite.number}</TableCell>
      <TableCell component="div">{props.worksite.name}</TableCell>
      <TableCell component="div">{props.worksite.address}</TableCell>
      <TableCell component="div">{props.worksite.location}</TableCell>
      <TableCell component="div">
        {getWorksiteStateText(props.worksite.state)}
      </TableCell>
      <TableCell component="div" align="right">
        {durationFormatter.format((props.worksite.targetTime ?? 0) * 3600)}
      </TableCell>
      <TableCell component="div" align="right">
        {durationFormatter.format(props.worksite.totalTime)}
      </TableCell>
      <TableCell component="div" align="right">
        <IconButton {...contextMenu.getButtonProps()}>
          <MoreVertIcon fontSize="medium" />
        </IconButton>
        <EditWorksiteMenu
          menuProps={contextMenu.getMenuProps()}
          worksite={props.worksite}
          onSelect={props.onSelect}
          onUpdate={props.onUpdate}
          onDelete={props.onDelete}
        />
      </TableCell>
    </TableRow>
  );
}
