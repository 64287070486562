import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import LockIcon from "@mui/icons-material/Lock";

import { Menu, MenuProps } from "src/ui/Menu";
import { User } from "src/lib/user";
import { useAuth } from "src/contexts/AuthContext";

type EditUserMenuProps = {
  user: User;
  onSelect: (user: User | null) => void;
  onUpdate: () => void;
  onUpdatePassword: () => void;
  onDisable: () => void;
  onActivate: () => void;
};

export const EditUserMenu = (props: MenuProps<EditUserMenuProps>) => {
  const auth = useAuth();

  return (
    <Menu
      menuProps={props.menuProps}
      items={[
        {
          label: "Modifier",
          icon: <EditIcon />,
          onClick: () => {
            props.onSelect(props.user);
            props.onUpdate();
          },
        },
        {
          label: "Changer le mot de passe",
          icon: <LockIcon />,
          onClick: () => {
            props.onSelect(props.user);
            props.onUpdatePassword();
          },
        },
        props.user.disabledAt
          ? {
              label: "Réactiver",
              icon: <PublishedWithChangesIcon />,
              onClick: () => {
                props.onSelect(props.user);
                props.onActivate();
              },
            }
          : {
              label: "Désactiver",
              icon: <BlockIcon />,
              disabled: props.user.id === auth.user?.id,
              onClick: () => {
                props.onSelect(props.user);
                props.onDisable();
              },
            },
      ]}
    />
  );
};
