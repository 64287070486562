import { Link } from "@mui/material";

type TableLinkProps = {
  href: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function TableLink(props: TableLinkProps) {
  return (
    <Link
      className={props.className}
      href={props.href}
      underline="none"
      target={props.target}
      onContextMenu={props.onContextMenu}
      onDragStart={props.onDragStart}
      onDrag={props.onDrag}
      onDragEnd={props.onDragEnd}
      onDragEnter={props.onDragEnter}
      onDragLeave={props.onDragLeave}
      onDrop={props.onDrop}
    >
      {props.children}
    </Link>
  );
}
