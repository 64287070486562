import { useState, Dispatch, SetStateAction, RefObject } from "react";
import {
  useMediaQuery,
  useTheme,
  Box,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from "@mui/material";

export interface SwipRespDrawerActions {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

export type SwipeableResponsiveDrawerProps = {
  defaultOpen?: boolean;
  actionRef?: RefObject<SwipRespDrawerActions>;
  width?: number;
  isFixed: boolean;
} & Partial<SwipeableDrawerProps>;

//drawer logic absolutly stolen from https://codesandbox.io/s/ko9vbg?file=/demo.tsx:811-835

export function SwipeableResponsiveDrawer({
  defaultOpen = false,
  width,
  actionRef,
  isFixed,
  ...props
}: SwipeableResponsiveDrawerProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  if (actionRef?.current) {
    actionRef.current.setIsOpen = setIsOpen;
  }

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: width },
        flexShrink: { sm: 0 },
      }}
    >
      <SwipeableDrawer
        open={isFixed || isOpen}
        variant={isFixed ? "permanent" : "temporary"}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width,
            boxSizing: "border-box",
          },
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        {...props}
      />
    </Box>
  );
}
