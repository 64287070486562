import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import { format } from "date-fns/format";
import { WorksiteAssignmentQueryKey } from "./useWorksiteAssignment";

type Options = {
  onSuccess?: () => void;
  onError?: () => void;
};

type Params = {
  worksiteId: number;
  start: Date;
  end: Date;
};

export function useDeleteWorksiteWorksiteAssigments(options: Options = {}) {
  const queryClient = useQueryClient();

  return useMutation<Params, Error, Params>(
    async (params: Params) => {
      const searchParams = new URLSearchParams();
      searchParams.set("start", format(params.start, "yyyy/MM/dd"));
      searchParams.set("end", format(params.end, "yyyy/MM/dd"));

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/worksites/${params.worksiteId}/worksite-assignments?${searchParams}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete worksite assignments.");
      }

      const data = await response.json();
      return camelcaseKeys(data.worksite, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(WorksiteAssignmentQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
