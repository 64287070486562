import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { durationFormatter } from "src/lib/intl";
import { useWorksiteStatistics } from "src/queries/useWorksiteStatistics";
import { Dialog } from "src/ui/Dialog";

type WorksiteStatsDialogProps = {
  open: boolean;
  onClose: () => void;
  worksiteId: number;
};

export function WorksiteStatsDialog(props: WorksiteStatsDialogProps) {
  const { data } = useWorksiteStatistics(props.worksiteId);

  return (
    <Dialog
      open={props.open}
      title="Statistiques"
      cancelText="Fermer"
      onClose={props.onClose}
      onCancel={props.onClose}
      noConfirm
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Année</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((stat) => (
              <TableRow key={stat.year}>
                <TableCell>{stat.year}</TableCell>
                <TableCell>{durationFormatter.format(stat.total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
}
