import { useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";

import {
  getWorksiteName,
  JobTypeWorksiteWithTotalTime,
  Worksite,
  WorksiteType,
} from "src/lib/worksite";

export const WorksitesQueryKey = "worksites";

type UseWorksitesParams<T> = {
  type?: T;
  state?: Worksite["state"];
};

export async function getWorksites<T extends keyof typeof WorksiteType>(
  params: UseWorksitesParams<keyof typeof WorksiteType> = {}
): Promise<TypeMap[T]> {
  const url = new URL(`${process.env.REACT_APP_API_URL}/worksites`);

  if (params.type) {
    url.searchParams.append("type", params.type);
  }

  if (params.state) {
    url.searchParams.append("state", params.state);
  }

  const response = await fetch(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch worksites.");
  }

  const data = await response.json();

  const worksites = camelcaseKeys(data.worksites, { deep: true });

  // TODO: Move this elsewhere
  worksites.sort((a: Worksite, b: Worksite) =>
    getWorksiteName(a).localeCompare(getWorksiteName(b), undefined, {
      numeric: true,
      sensitivity: "base",
    })
  );

  return worksites;
}

type TypeMap = {
  [WorksiteType.ACTIVITY]: JobTypeWorksiteWithTotalTime[];
  [WorksiteType.WORKSITE]: Worksite[];
};

export function useWorksites<T extends keyof typeof WorksiteType>(
  params: UseWorksitesParams<T> = {}
) {
  return useQuery<TypeMap[T]>(
    [WorksitesQueryKey, params.type, params.state],
    () => getWorksites(params),
    {}
  );
}
