import React from "react";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { ConditionalWrapper } from "./ConditionnalWrapper";

type DialogProps = {
  open: boolean;
  title: React.ReactNode;
  cancelText?: string;
  noConfirm?: boolean;
  confirmText?: string;
  fullWidth?: boolean;
  maxWidth?: React.ComponentProps<typeof MuiDialog>["maxWidth"];
  confirmProps?: React.ComponentProps<typeof Button>;
  children: React.ReactNode;
  form?: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm?: () => void;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
};

export function Dialog({
  title,
  open,
  children,
  confirmProps,
  onClose,
  onCancel,
  onConfirm,
  onSubmit,
  cancelText = "Annuler",
  confirmText = "Confirmer",
  maxWidth = "sm",
  fullWidth = true,
  noConfirm = false,
  form = false,
}: DialogProps) {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ConditionalWrapper
        condition={form}
        wrapper={(children) => (
          <Box component="form" onSubmit={onSubmit}>
            {children}
          </Box>
        )}
      >
        <DialogContent sx={{ pt: 0 }}>{children}</DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {cancelText}
          </Button>
          {!noConfirm && (
            <Button
              color="primary"
              type="submit"
              onClick={onConfirm}
              {...confirmProps}
            >
              {confirmText}
            </Button>
          )}
        </DialogActions>
      </ConditionalWrapper>
    </MuiDialog>
  );
}
