import { useNavigate } from "react-router-dom";
import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { EditUserMenu } from "src/components/EditUserMenu";
import { useContextMenu } from "src/hooks/useContextMenu";
import { User } from "src/lib/user";

type EmployeeRowProps = {
  user: User;
  selected?: boolean;
  onSelect: (user: User | null) => void;
  onUpdate: () => void;
  onUpdatePassword: () => void;
  onDisable: () => void;
  onActivate: () => void;
};

export function EmployeeRow(props: EmployeeRowProps) {
  const contextMenu = useContextMenu();
  const navigate = useNavigate();

  const cellColor = props.user.disabledAt ? "text.secondary" : "text.primary";

  return (
    <TableRow
      hover
      onClick={() => navigate(`/employees/${props.user.id}`)}
      sx={{
        cursor: "pointer",
      }}
      onContextMenu={(e) => {
        props.onSelect(props.user);
        contextMenu.onContextMenu(e);
      }}
      selected={props.selected}
    >
      <TableCell sx={{ color: cellColor }}>{props.user.lastName}</TableCell>
      <TableCell sx={{ color: cellColor }}>{props.user.firstName}</TableCell>
      <TableCell sx={{ color: cellColor }}>{props.user.username}</TableCell>
      <TableCell sx={{ color: cellColor }}>
        <Checkbox checked={!props.user.disabledAt} disabled />
      </TableCell>
      <TableCell align="right">
        <IconButton {...contextMenu.getButtonProps()}>
          <MoreVertIcon fontSize="medium" />
        </IconButton>
        <EditUserMenu
          menuProps={contextMenu.getMenuProps()}
          user={props.user}
          onSelect={props.onSelect}
          onUpdate={props.onUpdate}
          onUpdatePassword={props.onUpdatePassword}
          onDisable={props.onDisable}
          onActivate={props.onActivate}
        />
      </TableCell>
    </TableRow>
  );
}
