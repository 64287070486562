import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";

import { UsersQueryKey } from "./useUsers";
import { UserQueryKey } from "./useUser";

type UseDisableUserOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

export function useDisableUser(options: UseDisableUserOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation<number, Error, number>(
    async (id: number) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${id}/disable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to disable user.");
      }

      const data = await response.json();
      return camelcaseKeys(data.user, { deep: true });
    },
    {
      ...options,
      onSuccess: (id) => {
        queryClient.invalidateQueries(UsersQueryKey);
        queryClient.invalidateQueries([UserQueryKey, id]);
        options.onSuccess?.();
      },
    }
  );
}
