import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { WorksiteJobsQueryKey } from "./useWorksiteJobs";
import { UserJobsQueryKey } from "./useUserJobs";
import { JobsQueryKey } from "./useJobs";
import { durationParser } from "src/lib/intl";
import { format } from "date-fns";

type UseCreateJobOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseCreateJobParams = {
  worksiteId: number;
  description?: string;
  duration: string;
  date: Date;
  userId: number;
};

export function useCreateJob(options: UseCreateJobOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UseCreateJobParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/worksites/${params.worksiteId}/jobs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: JSON.stringify(
            snakecaseKeys(
              {
                ...params,
                date: format(params.date, "yyyy/MM/dd"),
                duration: durationParser.parse(params.duration),
              },
              { deep: true }
            )
          ),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create job.");
      }

      const data = await response.json();
      return camelcaseKeys(data.job, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(WorksiteJobsQueryKey);
        queryClient.invalidateQueries(UserJobsQueryKey);
        queryClient.invalidateQueries(JobsQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
