import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { isSameDay } from "date-fns";
import { format } from "date-fns/format";
import { frCH } from "date-fns/locale";
import { useTitle } from "src/hooks/useTitle";
import { useWeekDays } from "src/lib/date";
import { useDisplayWeekSelector } from "src/hooks/useDisplayWeekSelector";
import { useSelectedWeekStore } from "src/stores/useSelectedWeekStore";
import { Head } from "src/ui/Head";
import { useUsers } from "src/queries/useUsers";
import { useToast } from "src/contexts/ToastContext";
import { User } from "src/lib/user";
import { WorksiteAssignment } from "src/lib/worksite-assignment";
import { useWorksites } from "src/queries/useWorksites";
import { useWorksiteAssigments } from "src/queries/useWorksiteAssignment";
import { useUpdateWorksiteAssignment } from "src/queries/useUpdateWorksiteAssignment";
import { UseDeleteWorksiteAssignment } from "src/queries/useDeleteWorksiteAssignment";
import { getWorksiteName, Worksite, WorksiteType } from "src/lib/worksite";
import { useChangeWorksiteAssignmentsWorksite } from "src/queries/useChangeWorksiteAssigmentsWorksite";
import { useDeleteWorksiteWorksiteAssigments } from "src/queries/useDeleteWorksiteWorksiteAssignments";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import styled from "@emotion/styled";

const StyledTableCell = styled(TableCell)`
  padding: 4px;
`;

const StyledSelect = styled(Select)`
  min-width: unset;
`;

export function PlanningRoute() {
  useTitle("Planning");
  useDisplayWeekSelector(true);

  const today = new Date();

  const [currentWeekIndex, currentYear] = useSelectedWeekStore((state) => [
    state.currentWeekIndex,
    state.currentYear,
  ]);

  const { startDate, endDate, days } = useWeekDays({
    weekIndex: currentWeekIndex,
    year: currentYear,
  });

  const { data: users } = useUsers();
  const { data: worksites = [] } = useWorksites();
  const { data: worksiteAssignments } = useWorksiteAssigments({
    start: startDate,
    end: endDate,
  });

  const { showToast } = useToast();

  // This is used to create a local state when the user selects a new
  // worksite at the bottom of the leftmost column.
  const [selectedWorksites, setSelectedWorksites] = useState<
    WorksiteWithAssignments[]
  >([]);

  type WorksiteWithAssignments = Worksite & {
    assignments: WorksiteAssignment[];
  };

  const [currentWeekWorksites, setCurrentWeekWorksites] = useState<
    WorksiteWithAssignments[]
  >([]);

  useEffect(() => {
    if (worksiteAssignments) {
      /**
       * We build a list of worksites from the list of assigments.
       * This list only contains worksites that where found in the list
       * of assignments. As we don't want to display all worksites.
       */
      const uniqueWorksites: WorksiteWithAssignments[] = [];
      for (const assignment of worksiteAssignments) {
        const index = uniqueWorksites.findIndex(
          (w) => w.id === assignment.worksite.id
        );
        if (index === -1) {
          const worksite = {
            ...assignment.worksite,
            assignments: [assignment],
          };
          uniqueWorksites.push(worksite);
        } else {
          uniqueWorksites[index].assignments.push(assignment);
        }
      }

      // We add the local state worksites.
      for (const worksite of selectedWorksites) {
        if (!uniqueWorksites.find((w) => w.id === worksite.id)) {
          uniqueWorksites.push(worksite);
        }
      }

      // We order the worksites by their number
      uniqueWorksites.sort((a, b) => b.number.localeCompare(a.number, "fr-CH"));

      setCurrentWeekWorksites(uniqueWorksites);
    }
  }, [worksiteAssignments, selectedWorksites]);

  // const key = "planning.worksites.order";

  // function getWorksiteOrder() {
  //   const storedWorksiteOrder = JSON.parse(localStorage.getItem(key) ?? "{}");
  //   return storedWorksiteOrder[`${currentYear}-${currentWeekIndex}`];
  // }

  // const [worksiteOrder, setWorksiteOrder] = useState<number[]>(
  //   getWorksiteOrder()
  // );

  // useEffect(() => {
  //   const storedWorksiteOrder = JSON.parse(localStorage.getItem(key) ?? "{}");
  //   storedWorksiteOrder[`${currentYear}-${currentWeekIndex}`] = worksiteOrder;
  //   localStorage.setItem(key, JSON.stringify(storedWorksiteOrder));
  // }, [worksiteOrder]);

  const mutationOptions = {
    onSuccess() {
      showToast("Le planning a bien été mis à jour");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  };

  const updateMutation = useUpdateWorksiteAssignment(mutationOptions);
  const changeWorksiteMutation =
    useChangeWorksiteAssignmentsWorksite(mutationOptions);
  const deleteWorksiteMutation =
    useDeleteWorksiteWorksiteAssigments(mutationOptions);
  const deleteMutation = UseDeleteWorksiteAssignment(mutationOptions);

  if (!worksiteAssignments || !users) return null;

  function filterWorksites(worksites: Worksite[]) {
    // We don't want to filter worksites for previous weeks the same way, as some worksites will be "FINISHED".
    // We remove the planned worksites in any case.
    if (today > endDate) {
      return worksites.filter((w) => w.state !== "PLANNED");
    }

    return worksites.filter(
      (w) => w.type === WorksiteType.ACTIVITY || w.state === "IN_PROGRESS"
    );
  }

  function fitlerUsers(users: User[]) {
    if (today > endDate) {
      return users;
    }

    return users.filter((u) => u.disabledAt === null && u.role === "USER");
  }

  const filteredUsers = fitlerUsers(users);
  const filteredWorksites = filterWorksites(worksites).sort((a, b) =>
    a.number > b.number ? 1 : -1
  );

  /**
   * Group the assignments by index in order to display the worksite
   * assignment on the same row in the table.
   *
   * If we don't group the assignments by index we will have a new row
   * for each assignments.
   *
   * @param assignments The worksite assignments to group by index
   */
  function groupAssignmentsByIndex(assignments: WorksiteAssignment[]) {
    const groupedAssignments: WorksiteAssignment[][] = [];

    for (const assignment of assignments) {
      const index = groupedAssignments.findIndex((ga) =>
        ga.find((a) => a.index === assignment.index)
      );
      if (index === -1) {
        groupedAssignments.push([assignment]);
      } else {
        groupedAssignments[index].push(assignment);
      }
    }

    groupedAssignments.sort((a, b) =>
      a[0] && b[0] ? a[0].index - b[0].index : 0
    );

    const max = Math.max(...assignments.map((a) => a.index));

    // This fills the "holes" as we could have missing indices
    for (let i = 0; i < max; i++) {
      const found = groupedAssignments.find((ga) => ga.at(0)?.index === i);
      if (!found) {
        groupedAssignments.splice(i, 0, []);
      }
    }

    return groupedAssignments;
  }

  /**
   * Get the employee count for a given day.
   *
   * @param day The day on which to get the employee count
   * @param assignments The assigments on which to check the date
   * @returns The count of employees
   */
  function getEmployeeCountByDay(day: Date, assignments: WorksiteAssignment[]) {
    let count = 0;

    for (const assignment of assignments) {
      if (isSameDay(day, new Date(assignment.date))) {
        count++;
      }
    }

    return count;
  }

  function getAssigmentUser(assigments: WorksiteAssignment[] = [], day: Date) {
    const assigment = assigments.find((assignment) =>
      isSameDay(new Date(assignment.date), day)
    );

    if (assigment) {
      return users?.find((u) => u.id === assigment.userId);
    }

    return null;
  }

  return (
    <>
      <Head title={`Planning sem. ${currentWeekIndex} ${currentYear}`} />

      <TableContainer
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box flex={1}>
          <Table
            style={{
              tableLayout: "fixed",
              width: "100%",
            }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  Chantier
                </StyledTableCell>
                {days.map((day) => (
                  <StyledTableCell
                    key={day.toISOString()}
                    sx={{
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {format(day, "EEEE dd.MM", { locale: frCH })}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentWeekWorksites.map((worksite, i) => (
                <React.Fragment key={worksite.id}>
                  <TableRow>
                    <StyledTableCell sx={{ display: "flex" }} size="small">
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "-8px",
                          marginRight: "6px",
                        }}
                      >
                        <button className="arrow-btn" disabled={i === 0}>
                          <ArrowUpward sx={{ width: 12, height: 12 }} />
                        </button>
                        <button
                          className="arrow-btn"
                          disabled={i === currentWeekWorksites.length - 1}
                        >
                          <ArrowDownward sx={{ width: 12, height: 12 }} />
                        </button>
                      </div> */}
                      <StyledSelect
                        size="small"
                        // sx={{ width: "calc(100% - 26px)" }}
                        sx={{ width: "100%" }}
                        value={worksite.id}
                        onChange={(e) => {
                          const id = e.target.value
                            ? Number(e.target.value)
                            : undefined;

                          if (id) {
                            changeWorksiteMutation.mutate({
                              currentWorksiteId: worksite.id,
                              newWorksiteId: id,
                              start: startDate,
                              end: endDate,
                            });
                          } else {
                            deleteWorksiteMutation.mutate({
                              worksiteId: worksite.id,
                              start: startDate,
                              end: endDate,
                            });

                            const isEmpty = !worksiteAssignments.find(
                              (wa) => wa.worksite.id === worksite.id
                            );
                            if (isEmpty) {
                              const index = selectedWorksites.findIndex(
                                (w) => w.id === worksite.id
                              );
                              const newSelectedWorksites = [
                                ...selectedWorksites,
                              ];
                              newSelectedWorksites.splice(index, 1);
                              setSelectedWorksites(newSelectedWorksites);
                            }
                          }
                        }}
                      >
                        <MenuItem value="">AUCUN</MenuItem>
                        {filteredWorksites
                          .filter(
                            (w) =>
                              w.id === worksite.id ||
                              /**
                               *  Remove the worksites that are already
                               *  present in the planning
                               */
                              !currentWeekWorksites.find(
                                (cww) => cww.id === w.id
                              )
                          )
                          .map((worksite) => (
                            <MenuItem key={worksite.id} value={worksite.id}>
                              {getWorksiteName(worksite)}
                            </MenuItem>
                          ))}
                      </StyledSelect>
                    </StyledTableCell>
                    {days.map((day) => (
                      <StyledTableCell size="small">
                        <StyledSelect
                          size="small"
                          sx={{ width: "100%" }}
                          value={
                            groupAssignmentsByIndex(worksite.assignments)
                              .at(0)
                              ?.find((assignment) =>
                                isSameDay(new Date(assignment.date), day)
                              )?.userId ?? ""
                          }
                          onChange={(e) => {
                            const userId = e.target.value
                              ? Number(e.target.value)
                              : undefined;

                            if (!userId) {
                              const assigment = groupAssignmentsByIndex(
                                worksite.assignments
                              )[0].find((assignment) =>
                                isSameDay(new Date(assignment.date), day)
                              );

                              if (assigment) {
                                deleteMutation.mutate(assigment.id);
                              }
                            } else {
                              updateMutation.mutate({
                                date: day,
                                userId,
                                worksiteId: worksite.id,
                                index: 0,
                              });
                            }
                          }}
                        >
                          <MenuItem value="">AUCUN</MenuItem>
                          {filteredUsers.map((user) => (
                            <MenuItem
                              key={user.id}
                              value={user.id}
                              sx={{
                                color: currentWeekWorksites
                                  .map((w) => w.assignments)
                                  .flatMap((a) => a)
                                  .find(
                                    (a) =>
                                      isSameDay(new Date(a.date), day) &&
                                      a.userId === user.id
                                  )
                                  ? "#cc121c"
                                  : "inherit",
                              }}
                            >
                              {user.firstName} {user.lastName}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                  {
                    // We add an empty row at the end in order to add new employees
                    // to a worksite
                    [...groupAssignmentsByIndex(worksite.assignments), []]
                      .slice(1)
                      .map((row, i) => (
                        <TableRow>
                          <StyledTableCell size="small"></StyledTableCell>
                          {days.map((day) => (
                            <StyledTableCell size="small">
                              <StyledSelect
                                size="small"
                                sx={{ width: "100%" }}
                                value={getAssigmentUser(row, day)?.id ?? ""}
                                onChange={(e) => {
                                  const userId = e.target.value
                                    ? Number(e.target.value)
                                    : undefined;

                                  if (!userId) {
                                    const assigment = row.find((assignment) =>
                                      isSameDay(new Date(assignment.date), day)
                                    );

                                    if (assigment) {
                                      deleteMutation.mutate(assigment.id);
                                    }
                                  } else {
                                    updateMutation.mutate({
                                      date: day,
                                      userId,
                                      worksiteId: worksite.id,
                                      index: i + 1,
                                    });
                                  }
                                }}
                              >
                                <MenuItem value="">AUCUN</MenuItem>
                                {filteredUsers.map((user) => (
                                  <MenuItem
                                    key={user.id}
                                    value={user.id}
                                    sx={{
                                      color: currentWeekWorksites
                                        .map((w) => w.assignments)
                                        .flatMap((a) => a)
                                        .find(
                                          (a) =>
                                            isSameDay(new Date(a.date), day) &&
                                            a.userId === user.id
                                        )
                                        ? "#cc121c"
                                        : "inherit",
                                    }}
                                  >
                                    {user.firstName} {user.lastName}
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      ))
                  }
                  <TableRow>
                    <StyledTableCell size="small"></StyledTableCell>
                    {days.map((day) => (
                      <StyledTableCell key={day.toISOString()} size="small">
                        <Typography
                          noWrap
                          sx={{
                            border: "1px solid #cc121c",
                            borderRadius: "4px",
                            p: "4px 6px",
                            color: "#cc121c",
                            userSelect: "none",
                            fontSize: 12,
                          }}
                        >
                          {getEmployeeCountByDay(day, worksite.assignments)}{" "}
                          personne(s)
                        </Typography>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
              <TableRow>
                <StyledTableCell size="small">
                  <StyledSelect
                    size="small"
                    sx={{ width: "100%" }}
                    value=""
                    onChange={(e) => {
                      const id = Number(e.target.value);
                      const worksite = worksites.find((w) => w.id === id);

                      if (worksite) {
                        setSelectedWorksites([
                          ...selectedWorksites,
                          { ...worksite, assignments: [] },
                        ]);
                      }
                    }}
                  >
                    {filteredWorksites
                      .filter(
                        (w) =>
                          !currentWeekWorksites.find((cww) => cww.id === w.id)
                      )
                      .map((worksite) => (
                        <MenuItem key={worksite.id} value={worksite.id}>
                          {getWorksiteName(worksite)}
                        </MenuItem>
                      ))}
                  </StyledSelect>
                </StyledTableCell>
                {days.map((day) => (
                  <StyledTableCell
                    key={day.toString()}
                    size="small"
                  ></StyledTableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box
          display="flex"
          justifyContent="space-evenly"
          borderTop="1px solid #ccc"
        >
          <Box flex={1} sx={{ p: 1 }}></Box>
          {days.map((day) => (
            <Box key={day.toISOString()} flex={1} sx={{ p: 1 }}>
              <Typography
                noWrap
                sx={{
                  border: "1px solid #cc121c",
                  borderRadius: "4px",
                  p: "4px 6px",
                  color: "#cc121c",
                  userSelect: "none",
                  fontSize: 12,
                }}
              >
                {getEmployeeCountByDay(day, worksiteAssignments)} personne(s)
              </Typography>
            </Box>
          ))}
        </Box>
      </TableContainer>
    </>
  );
}
