import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Fab,
  Stack,
  Typography,
} from "@mui/material";
import { isSameDay } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueries } from "react-query";

import AddIcon from "@mui/icons-material/Add";

import { ConfirmDialog } from "src/components/ConfirmDialog";
import { EditUserDialog } from "src/components/EditUserDialog";
import { JobListItem } from "src/components/JobListItem";
import { TimeTrackingTable } from "src/components/TimeTrackingTable";
import { getWeekday, useWeekDays } from "src/lib/date";
import { useTitle } from "src/hooks/useTitle";
import { getSelectableWorksites, getWorksiteName } from "src/lib/worksite";
import { getUser } from "src/queries/useUser";
import { useSelectedWeekStore } from "src/stores/useSelectedWeekStore";
import { CreateJobDialog } from "src/components/CreateJobDialog";
import { useUpdateUser } from "src/queries/useUpdateUser";
import { useToast } from "src/contexts/ToastContext";
import { useDisableUser } from "src/queries/useDisableUser";
import { getWorksites, WorksitesQueryKey } from "src/queries/useWorksites";
import { getUserJobs, UserJobsQueryKey } from "src/queries/useUserJobs";
import { useUpdateJob } from "src/queries/useUpdateJob";
import { useDeleteJob } from "src/queries/useDeleteJob";
import { useCreateJob } from "src/queries/useCreateJob";
import { useEnableUser } from "src/queries/useEnableUser";
import { useDisplayWeekSelector } from "src/hooks/useDisplayWeekSelector";
import { UsersQueryKey } from "src/queries/useUsers";
import { durationFormatter } from "src/lib/intl";
import { useRouteState } from "src/stores/useRouteState";
import { Head } from "src/ui/Head";

export function EmployeeRoute() {
  useDisplayWeekSelector(true);

  const day = getWeekday(new Date());

  const [createJobOpen, setCreateJobOpen] = useState(false);
  const [updateUserOpen, setUpdateUserOpen] = useState(false);
  const [toggleUserOpen, setToggleUserOpen] = useState(false);
  const [date, setDate] = useState(day);

  const [currentWeekIndex, currentYear] = useSelectedWeekStore((state) => [
    state.currentWeekIndex,
    state.currentYear,
  ]);

  const { startDate, endDate, days } = useWeekDays({
    weekIndex: currentWeekIndex,
    year: currentYear,
  });

  const { showToast } = useToast();
  const routeParams = useParams();
  const userId = Number(routeParams.id);

  const { resource, setResource } = useRouteState();

  useEffect(() => {
    if (resource?.id !== userId) {
      setResource({ type: "user", id: userId });
    }

    return () => {
      setResource(null);
    };
  }, []);

  const [{ data: user }, { data: jobs = [] }, { data: worksites = [] }] =
    useQueries([
      {
        queryKey: [UsersQueryKey, userId],
        queryFn: () => getUser(userId),
      },
      {
        queryKey: [UserJobsQueryKey, userId, startDate, endDate],
        queryFn: () =>
          getUserJobs({
            userId,
            start: startDate,
            end: endDate,
          }),
      },
      {
        queryKey: WorksitesQueryKey,
        queryFn: () => getWorksites(),
      },
    ]);

  const title = user ? `${user.firstName} ${user.lastName}` : "";
  useTitle(title);

  const createJobMutation = useCreateJob({
    onSuccess() {
      showToast("L'enregistrement a bien été créé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateJobMutation = useUpdateJob({
    onSuccess() {
      showToast("L'enregistrement a bien été modifié");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateUserMutation = useUpdateUser({
    onSuccess() {
      showToast("L'employé a bien été mis à jour");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const enableUserMutation = useEnableUser({
    onSuccess() {
      showToast("Le compte a bien été réactivé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const disableUserMutation = useDisableUser({
    onSuccess() {
      showToast("Le compte a bien été désactivé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const deleteJobMutation = useDeleteJob({
    onSuccess() {
      showToast("L'enregistrement a bien été supprimé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  // The useQuery hook could return undefined if the query is disabled, we do
  // not use this feature here so we can safely assume that the data is always
  // defined.
  if (!user) return null;

  return (
    <>
      <Head title={title} />
      <Box p={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            {user.firstName} {user.lastName}
          </Typography>
          <ButtonGroup>
            <Button onClick={() => setUpdateUserOpen(true)}>Modifier</Button>
            <Button onClick={() => setToggleUserOpen(true)}>
              {user.disabledAt ? "Réactiver" : "Désactiver"}
            </Button>
          </ButtonGroup>
        </Box>
        <Stack direction="row" spacing={1} mt={1}>
          <Chip
            label={`Total semaine : ${durationFormatter.format(
              jobs.reduce((acc, curr) => acc + curr.duration, 0)
            )} heures`}
            title="Total des heures réalisées cette semaine"
          />
        </Stack>
      </Box>
      <TimeTrackingTable
        defaultSelectedDate={day}
        onDateChange={setDate}
        onCreateJob={(date) => {
          setDate(date);
          setCreateJobOpen(true);
        }}
        jobs={jobs}
        days={days}
        renderDate={(date) => {
          return (
            <>
              {jobs
                .filter((job) => isSameDay(job.date, new Date(date)))
                .map((job) => (
                  <JobListItem
                    key={job.id}
                    job={job}
                    primary={getWorksiteName(job.worksite)}
                    worksites={worksites}
                    onUpdate={updateJobMutation.mutate}
                    onDelete={deleteJobMutation.mutate}
                  />
                ))}
            </>
          );
        }}
      />
      <Fab
        sx={{ position: "fixed", bottom: 36, right: 36 }}
        color="primary"
        onClick={() => setCreateJobOpen(true)}
      >
        <AddIcon />
      </Fab>
      <CreateJobDialog
        open={createJobOpen}
        date={date}
        worksites={getSelectableWorksites(worksites)}
        onClose={() => setCreateJobOpen(false)}
        onConfirm={(data) =>
          createJobMutation.mutate({
            ...data,
            userId: user.id,
          })
        }
      />
      <EditUserDialog
        open={updateUserOpen}
        user={user}
        onClose={() => setUpdateUserOpen(false)}
        onConfirm={updateUserMutation.mutate}
      />
      <ConfirmDialog
        open={toggleUserOpen}
        title={user.disabledAt ? "Réactiver le compte" : "Désactiver le compte"}
        content={`Êtes-vous sûr de vouloir ${
          user.disabledAt ? "réactiver" : "désactiver"
        } ce compte?`}
        onClose={() => setToggleUserOpen(false)}
        onConfirm={() => {
          if (user.disabledAt) {
            enableUserMutation.mutate(user.id);
          } else {
            disableUserMutation.mutate(user.id);
          }
        }}
      />
    </>
  );
}
