import { useQuery, UseQueryOptions } from "react-query";
import { format } from "date-fns";
import camelcaseKeys from "camelcase-keys";

import { JobWithWorksite } from "src/lib/job";

export const UserJobsQueryKey = "user-jobs";

type UseUserJobsParams = {
  userId: number;
  start: Date;
  end: Date;
};

export async function getUserJobs(
  params: UseUserJobsParams
): Promise<JobWithWorksite[]> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/users/${
      params.userId
    }/jobs?start=${format(params.start, "yyyy/MM/dd")}&end=${format(
      params.end,
      "yyyy/MM/dd"
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch user jobs.");
  }

  const data = await response.json();
  return camelcaseKeys(
    data.jobs.map((job: any) => ({
      ...job,
      date: new Date(job.date),
    })),
    { deep: true }
  );
}

export function useUserJobs(
  params: UseUserJobsParams,
  options: UseQueryOptions<JobWithWorksite[]> = {}
) {
  return useQuery<JobWithWorksite[]>(
    [
      UserJobsQueryKey,
      params.userId,
      format(params.start, "yyyy/MM/dd"),
      format(params.end, "yyyy/MM/dd"),
    ],
    () => getUserJobs(params),
    {
      // Disabled otherwise the tracking-dashboard page will blink
      keepPreviousData: true,
      ...options,
    }
  );
}
