import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useAuth } from "src/contexts/AuthContext";
import { Head } from "src/ui/Head";

const LoginSchema = z.object({
  username: z.string().min(1, "Saisir le nom d'utilisateur"),
  password: z.string().min(1, "Saisir le mot de passe"),
});

export function LoginRoute() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof LoginSchema>>({
    mode: "onSubmit",
    resolver: zodResolver(LoginSchema),
  });

  const hasError = !!searchParams.get("error") || !!auth.error;
  const from = location.state?.from?.pathname ?? "/files";

  // Redirect the user once they logged in, or if they visit the login page
  useEffect(() => {
    if (auth.user) {
      navigate(from, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  const onSubmit = (data: z.infer<typeof LoginSchema>) => {
    auth.login(data);
  };

  return (
    <>
      <Head title="Connexion" />
      <Box display="flex" flex={1} flexDirection="column">
        <Box
          display="flex"
          flex={1}
          mt={8}
          mx={8}
          flexDirection="column"
          alignItems="center"
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }} />
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            mt={2.5}
          >
            <TextField
              required
              fullWidth
              id="name"
              label="Nom d'utilisateur"
              autoCapitalize="off"
              name="name"
              autoComplete="name"
              autoFocus
              inputProps={register("username", { required: true })}
              error={!!errors.username}
              helperText={errors.username?.message ?? " "}
            />
            <TextField
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              inputProps={register("password", { required: true })}
              error={!!errors.password}
              helperText={errors.password?.message ?? " "}
            />
            <Button type="submit" fullWidth variant="contained">
              Se connecter
            </Button>
            <Alert
              severity="error"
              sx={{ mt: 3, visibility: hasError ? "visible" : "hidden" }}
            >
              {searchParams.get("error") === "not-logged-in"
                ? "Vous devez vous connecter pour accéder à cette page"
                : auth.error}
            </Alert>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" p={4}>
          <Typography variant="subtitle2" color="text.secondary">
            {new Date().getFullYear()} | Bocherens Toiture SA
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
