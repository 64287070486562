import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { UsersQueryKey } from "./useUsers";
import { UserRole } from "src/lib/user";

type UseCreateUserOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseCreateUserParams = {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  role: UserRole;
};

export function useCreateUser(options: UseCreateUserOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UseCreateUserParams) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
        body: JSON.stringify(snakecaseKeys(params, { deep: true })),
      });

      if (!response.ok) {
        throw new Error("Failed to create user.");
      }

      const data = await response.json();
      return camelcaseKeys(data.user, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(UsersQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
