import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { zodResolver } from "@hookform/resolvers/zod";

import { createUserSchema, CreateUserSchema } from "src/schema/user.schema";
import { Dialog } from "src/ui/Dialog";

type CreateUserDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (user: CreateUserSchema) => void;
};

export function CreateUserDialog(props: CreateUserDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateUserSchema>({
    resolver: zodResolver(createUserSchema),
  });

  const handleClose = () => {
    props.onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: CreateUserSchema) => {
    handleClose();

    props.onConfirm(data);
  };

  return (
    <Dialog
      open={props.open}
      title="Ajouter un employé"
      cancelText="Annuler"
      confirmText="Ajouter"
      onClose={props.onClose}
      onCancel={handleCancel}
      onSubmit={handleSubmit(onSubmit)}
      form
    >
      <TextField
        id="firstName"
        autoFocus
        margin="dense"
        label="Prénom"
        fullWidth
        variant="outlined"
        inputProps={register("firstName", { required: true })}
        error={!!errors.firstName}
        helperText={errors.firstName?.message ?? " "}
      />
      <TextField
        id="lastName"
        margin="dense"
        label="Nom"
        fullWidth
        variant="outlined"
        inputProps={register("lastName", { required: true })}
        error={!!errors.lastName}
        helperText={errors.lastName?.message ?? " "}
      />
      <TextField
        id="username"
        margin="dense"
        label="Nom d'utilisateur"
        fullWidth
        variant="outlined"
        inputProps={register("username", { required: true })}
        error={!!errors.username}
        helperText={errors.username?.message ?? " "}
      />
      <TextField
        id="password"
        margin="dense"
        label="Mot de passe"
        type="password"
        fullWidth
        variant="outlined"
        inputProps={register("password", { required: true })}
        error={!!errors.password}
        helperText={errors.password?.message ?? " "}
      />
      <FormControl fullWidth defaultValue="USER" margin="dense" required>
        <InputLabel id="role-label">Role</InputLabel>
        <Select
          native={isMobile}
          id="role"
          label="Role"
          labelId="role-label"
          variant="outlined"
          fullWidth
          defaultValue="USER"
          inputProps={register("role", { required: true })}
          error={!!errors.role}
        >
          {isMobile
            ? [
                <option key="ADMIN" value="ADMIN">
                  Administrateur
                </option>,
                <option key="USER" value="USER">
                  Employé
                </option>,
              ]
            : [
                <MenuItem key="ADMIN" value="ADMIN">
                  Administrateur
                </MenuItem>,
                <MenuItem key="USER" value="USER">
                  Employé
                </MenuItem>,
              ]}
        </Select>
      </FormControl>
    </Dialog>
  );
}
