import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { FilesQueryKey } from "./useFiles";

type UseMoveFilesOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseMoveFilesParams = { ids: number[]; parentId: number | null };

export function useMoveFiles(options: UseMoveFilesOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation<UseMoveFilesParams, Error, UseMoveFilesParams>(
    async (params: UseMoveFilesParams) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/files`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(
          snakecaseKeys(
            params.ids.map((id) => ({
              id,
              parentId: params.parentId,
            })),
            { deep: true }
          )
        ),
      });

      if (!response.ok) {
        throw new Error("Failed to move files.");
      }

      const data = await response.json();
      return camelcaseKeys(data.file, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(FilesQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
