import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { JobTypeRow } from "./JobTypeRow";
import { Column, getComparator, Order } from "src/lib/table";
import {
  JobTypeWorksite,
  JobTypeWorksiteWithTotalTime,
} from "src/lib/worksite";

export interface Data {
  number: string;
  name: string;
  isVisible: boolean;
  isDescriptionRequired: boolean;
  totalTime: number;
}

type JobTypeTableProps = {
  jobTypes: JobTypeWorksiteWithTotalTime[];
  onSelect: (jobType: JobTypeWorksite | null) => void;
  selectedJobType: JobTypeWorksite | null;
  onUpdate: () => void;
  onDelete: () => void;
};

const columns: Column<Data>[] = [
  { id: "number", label: "N°" },
  { id: "name", label: "Nom" },
  { id: "isVisible", label: "Visible" },
  { id: "isDescriptionRequired", label: "Description requise" },
  { id: "totalTime", label: "Heures" },
];

export function JobTypeTable(props: JobTypeTableProps) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("number");

  const createSortHandler = (property: keyof Data) => () => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Table
      stickyHeader
      sx={{ height: "min-content" }}
      component="div"
      role="table"
    >
      <TableHead component="div">
        <TableRow component="div">
          {columns.map((column) => (
            <TableCell
              key={column.id}
              sortDirection={orderBy === column.id ? order : false}
              style={{ minWidth: column.minWidth }}
              component="div"
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            component="div"
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          ></TableCell>
        </TableRow>
      </TableHead>
      <TableBody component="div">
        {props.jobTypes
          .slice()
          .sort(getComparator(order, orderBy))
          .map((jobType) => (
            <JobTypeRow
              key={jobType.id}
              jobType={jobType}
              onSelect={props.onSelect}
              onUpdate={props.onUpdate}
              onDelete={props.onDelete}
              selected={props.selectedJobType === jobType}
            />
          ))}
      </TableBody>
    </Table>
  );
}
