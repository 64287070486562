import React from "react";
import { useState } from "react";
import { Typography } from "@mui/material";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import {
  addWeeks,
  getDay,
  getISOWeek,
  getYear,
  isSameDay,
  isSameWeek,
  setYear,
} from "date-fns";

type DayProps = PickersDayProps<Date> & {
  selectedDay: Date;
  hoveredDate: Date;
  badges: Date[];
  setHoveredDate: (date: Date) => void;
};

function Day(props: DayProps) {
  const today = new Date();

  const isCurrentWeek = isSameWeek(props.day, today, {
    weekStartsOn: 1,
  });

  const isBetweenDay =
    !!props.hoveredDate &&
    isSameWeek(props.day, props.hoveredDate, {
      weekStartsOn: 1,
    });

  // We want to allow the user to click on future days that are in
  // the same week as this week.
  const isDisabled = !isCurrentWeek && props.disabled;

  return (
    <>
      {getDay(props.day) === 1 && (
        <Typography
          variant="caption"
          sx={{
            display: "flex",
            alignItems: "center",
            ml: -2,
            mr: 1.25,
            color: "text.secondary",
          }}
        >
          {getISOWeek(props.day)}
        </Typography>
      )}
      <MemoCalendarDay
        isBetweenDay={isBetweenDay}
        pickersDayProps={props}
        onMouseOver={() => props.setHoveredDate(props.day)}
        hasBadge={!!props.badges.find((badge) => isSameDay(badge, props.day))}
        isDisabled={isDisabled}
      />
    </>
  );
}

const badgeStyles = {
  content: "''",
  position: "absolute",
  top: 0,
  left: 0,
  width: 10,
  height: 10,
  borderRadius: "50%",
  backgroundColor: "primary.dark",
};

type CalendarWeekPickerProps = {
  weekIndex: number;
  year: number;
  onWeekChange: (weekIndex: number, year: number) => void;
  onMonthChange?: (month: Date) => void;
  badges: Date[];
};

export function CalendarWeekPicker(props: CalendarWeekPickerProps) {
  const today = new Date();
  const [hoveredDate, setHoveredDate] = useState<Date | null>();

  const currentWeekIndex = getISOWeek(today);
  const defaultCalendarMonth = setYear(
    addWeeks(today, props.weekIndex - currentWeekIndex),
    props.year ?? getYear(today)
  );

  const handleMonthChange = (date: Date) => {
    props.onMonthChange?.(date);
    setHoveredDate(null);
  };

  return (
    <DateCalendar
      onChange={(date) =>
        date && props.onWeekChange(getISOWeek(date), getYear(date))
      }
      disableFuture
      showDaysOutsideCurrentMonth
      referenceDate={defaultCalendarMonth}
      onMonthChange={handleMonthChange}
      views={["day"]}
      slotProps={{
        day: {
          hoveredDate,
          badges: props.badges,
          setHoveredDate,
        } as any,
      }}
      slots={{ day: Day as any }}
    />
  );
}

type CalendarDayProps = {
  isDisabled?: boolean;
  isBetweenDay: boolean;
  hasBadge: boolean;
  pickersDayProps: PickersDayProps<Date>;
  onMouseOver: () => void;
};

function CalendarDay(props: CalendarDayProps) {
  return (
    <PickersDay
      {...props.pickersDayProps}
      type="submit"
      selected={false}
      disabled={props.isDisabled}
      onMouseOver={props.onMouseOver}
      sx={{
        ...(props.isBetweenDay && !props.isDisabled
          ? {
              backgroundColor: "primary.light",
              color: "white",
              ":hover": {
                backgroundColor: "primary.light",
              },
              "&.MuiPickersDay-today": {
                backgroundColor: "primary.main",
                border: "none",
              },
            }
          : {
              ":hover": {
                backgroundColor: "white",
              },
              "&.MuiPickersDay-today": {
                backgroundColor: "secondary.light",
                border: "none",
                borderRadius: "50%",
              },
            }),
        ":before": props.hasBadge ? badgeStyles : {},
        /*
         * TODO: Make the selected week a continuous shape.
         * This will prevent hover issues with the border radius.
         * ":first-of-type": {
         *   borderTopLeftRadius: "50%",
         *   borderBottomLeftRadius: "50%",
         * },
         * ":last-of-type": {
         *   borderTopRightRadius: "50%",
         *   borderBottomRightRadius: "50%",
         * },
         */
      }}
    />
  );
}

const MemoCalendarDay = React.memo(CalendarDay);
