import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { PopoverButton } from "src/components/PopoverButton";

type TablefilterPopoverProps = {
  options: { label: string; value: string }[];
  filter: Record<string, boolean>;
  onChange: (value: string, checked: boolean) => void;
};

export function TableFilterPopover(props: TablefilterPopoverProps) {
  return (
    <PopoverButton>
      <Stack p={1} pl={2}>
        {props.options.map((option, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                checked={props.filter[option.value]}
                onChange={(_, checked) => props.onChange(option.value, checked)}
              />
            }
            label={option.label}
          />
        ))}
      </Stack>
    </PopoverButton>
  );
}
