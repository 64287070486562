import { Menu, MenuProps } from "src/ui/Menu";

import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";

type CreateWorksiteMenuProps = {
  onCreate: () => void;
};

export function CreateWorksiteMenu(props: MenuProps<CreateWorksiteMenuProps>) {
  return (
    <Menu
      menuProps={props.menuProps}
      items={[
        {
          label: "Créer un chantier",
          icon: <HomeWorkOutlinedIcon />,
          onClick: props.onCreate,
        },
      ]}
    />
  );
}
