export const ImageMimeTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/heic",
] as const;

export const WordMimeType = [
  "application/ms-word",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
] as const;

export const ExcelMimeType = [
  "application/ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
] as const;

export const PowerpointMimeType = [
  "application/ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
] as const;

export const DocumentMimeTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/plain",
] as const;

export const VideoMimeTypes = ["video/mp4"] as const;

export const AudioMimeTypes = ["audio/mp3"] as const;

export const EmailMimeTypes = [
  "message/rfc822", // .eml
  "application/vnd.ms-outlook", // .msg
] as const;

export const AllMimeTypes = [
  ...ImageMimeTypes,
  ...DocumentMimeTypes,
  ...VideoMimeTypes,
  ...AudioMimeTypes,
  ...EmailMimeTypes,
] as const;
