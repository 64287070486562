import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import camelcaseKeys from "camelcase-keys";

import { User, UserRole } from "src/lib/user";

export const UsersQueryKey = "users";

type UseUsersParams = {
  role?: UserRole;
};

type UseUsersOptions = Omit<
  UseQueryOptions<User[], unknown, User[], QueryKey>,
  "queryKey" | "queryFn"
>;

async function getUsers(params: UseUsersParams) {
  const url = new URL(`${process.env.REACT_APP_API_URL}/users`);

  if (params.role) {
    url.searchParams.append("role", params.role);
  }

  const response = await fetch(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch users.");
  }

  const data = await response.json();
  return camelcaseKeys(data.users, { deep: true });
}

export function useUsers(
  params: UseUsersParams = {},
  options: UseUsersOptions = {}
) {
  return useQuery<User[]>(
    [UsersQueryKey, params.role],
    () => getUsers(params),
    {
      suspense: true,
      ...options,
    }
  );
}
