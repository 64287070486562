import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "src/hooks/useLocalStorage";
import { loginRequest } from "src/lib/auth";
import { User } from "src/lib/user";
import { useCurrentUser } from "src/queries/useCurrentUser";
import { Loading } from "src/ui/Loading";

type AuthContextType = {
  user: any;
  error: string | null;
  login: (credentials: Credentials) => Promise<void>;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

type AuthProviderProps = {
  children: React.ReactNode;
};

type Credentials = {
  username: string;
  password: string;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [, setToken] = useLocalStorage("token", null);
  const [isLoading, setIsLoading] = useState(true);

  const { data } = useCurrentUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setUser(data);
    } else {
      setUser(null);
      navigate("/login", { replace: true });
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const login = async ({ username, password }: Credentials) => {
    setError(null);

    try {
      const { user, token } = await loginRequest(username, password);

      setUser(user);
      setToken(token);
      setIsLoading(false);
    } catch (e) {
      setError("Vos données de connexion sont erronées. Veuillez réessayer.");
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
  };

  const value = useMemo(
    () => ({
      user,
      error,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, error]
  );

  if (isLoading) {
    return <Loading />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
