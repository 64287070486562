import { useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";

import { File, FileWithShares } from "src/lib/file";

export const FilesQueryKey = "files";

type UseFilesParams = {
  parentId?: number;
};

async function getFiles(params: UseFilesParams) {
  const url = new URL(`${process.env.REACT_APP_API_URL}/files`);

  if (params.parentId) {
    url.searchParams.append("parent_id", params.parentId.toString());
  }

  const response = await fetch(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch files.");
  }

  const data = camelcaseKeys(await response.json(), { deep: true });

  return {
    files: data.files.map((file: any) => ({
      ...file,
      deletedAt: new Date(file.deletedAt),
      createdAt: new Date(file.createdAt),
      updatedAt: new Date(file.updatedAt),
    })),
    breadcrumbs: data.parents,
  };
}

export function useFiles(params: UseFilesParams) {
  return useQuery<{
    files: FileWithShares[];
    breadcrumbs: File[];
  }>([FilesQueryKey, params.parentId], () => getFiles(params), {
    initialData: {
      files: [],
      breadcrumbs: [],
    },
  });
}
