import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

type OutletContext = {
  setDisplayWeekSelector: (display: boolean) => void;
};

export const useDisplayWeekSelector = (display: boolean) => {
  const { setDisplayWeekSelector } = useOutletContext<OutletContext>();

  useEffect(() => {
    setDisplayWeekSelector(display);
  }, [setDisplayWeekSelector, display]);
};
