import { Menu, MenuProps } from "src/ui/Menu";

import UploadIcon from "@mui/icons-material/Upload";
import FolderIcon from "@mui/icons-material/Folder";
import LinkIcon from "@mui/icons-material/Link";

type CreateFileMenuProps = {
  file?: {
    cuid: string;
    name: string;
    mimeType: string | null;
  };
  onCreateFolder: () => void;
  onCreateLink: () => void;
  onUploadFiles: () => void;
};

export function CreateFileMenu(props: MenuProps<CreateFileMenuProps>) {
  return (
    <Menu
      menuProps={props.menuProps}
      items={[
        {
          label: "Charger un fichier",
          icon: <UploadIcon />,
          onClick: props.onUploadFiles,
        },
        {
          label: "Nouveau dossier",
          icon: <FolderIcon />,
          onClick: props.onCreateFolder,
        },
        {
          label: "Nouveau lien",
          icon: <LinkIcon />,
          onClick: props.onCreateLink,
        },
      ]}
    />
  );
}
