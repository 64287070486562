import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { WorksitesQueryKey } from "./useWorksites";
import { Worksite } from "src/lib/worksite";

type UseCreateWorksiteOptions = {
  onSuccess?: (params: UseCreateWorksiteParams) => void;
  onError?: () => void;
};

type UseCreateWorksiteParams = Pick<
  Worksite,
  | "number"
  | "name"
  | "address"
  | "targetTime"
  | "location"
  | "address"
  | "isDescriptionRequired"
  | "isVisible"
  | "state"
  | "type"
>;

export function useCreateWorksite(options: UseCreateWorksiteOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UseCreateWorksiteParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/worksites`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(snakecaseKeys(params, { deep: true })),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create worksite.");
      }

      const data = await response.json();
      return camelcaseKeys(data.user, { deep: true });
    },
    {
      ...options,
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(WorksitesQueryKey);
        options.onSuccess?.(params);
      },
    }
  );
}
