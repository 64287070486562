import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { JobTypeWorksite } from "src/lib/worksite";
import { Menu, MenuProps } from "src/ui/Menu";

type EditJobTypeMenuProps = {
  jobType: JobTypeWorksite;
  onSelect: (jobType: JobTypeWorksite | null) => void;
  onUpdate: () => void;
  onDelete: () => void;
};

export function EditJobTypeMenu(props: MenuProps<EditJobTypeMenuProps>) {
  return (
    <Menu
      menuProps={props.menuProps}
      items={[
        {
          label: "Modifier",
          icon: <EditIcon />,
          onClick: () => {
            props.onSelect(props.jobType);
            props.onUpdate();
          },
        },
        {
          label: "Supprimer",
          icon: <DeleteIcon />,
          onClick: () => {
            props.onSelect(props.jobType);
            props.onDelete();
          },
        },
      ]}
    />
  );
}
