import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";

export type SearchResultProps = {
  icon: JSX.Element;
  label: string;
  href: string;
  onClick?: () => void;
};

export function SearchResult(props: SearchResultProps) {
  return (
    <ListItem>
      <Link
        href={props.href}
        underline="none"
        sx={{
          display: "block",
          width: "100%",
          color: "inherit",
        }}
      >
        <ListItemButton sx={{ borderRadius: 0 }} onClick={props.onClick}>
          <ListItemIcon>{props.icon}</ListItemIcon>
          <ListItemText
            primary={props.label}
            primaryTypographyProps={{
              variant: "body2",
            }}
          />
        </ListItemButton>
      </Link>
    </ListItem>
  );
}
