import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";

import { zodResolver } from "@hookform/resolvers/zod";

import { updateJobSchema, UpdateJobSchema } from "src/schema/job.schema";
import { getWorksiteName, Worksite } from "src/lib/worksite";
import { JobWithWorksite } from "src/lib/job";
import { pick } from "src/lib/util";
import { durationFormatter } from "src/lib/intl";
import { DurationInput } from "./DurationInput";
import { DurationSelect } from "./DurationSelect";
import { Role } from "src/lib/user";
import { useAuth } from "src/contexts/AuthContext";
import { Dialog } from "src/ui/Dialog";

type EditJobDialogProps = {
  job: JobWithWorksite;
  worksites: Array<
    Pick<Worksite, "id" | "name" | "number" | "isDescriptionRequired">
  >;
  open: boolean;
  onClose: () => void;
  onConfirm: (job: UpdateJobSchema) => void;
};

const FormValues = ["id", "worksiteId", "description"] as const;

export function EditJobDialog(props: EditJobDialogProps) {
  const [selectedWorksiteIndex, setSelectedWorksiteIndex] = useState(0);
  const [exactDuration, setExactDuration] = useState(
    (props.job.duration / 60) % 15 !== 0
  );

  const selectedWorksite = props.worksites[selectedWorksiteIndex];

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, defaultValues = {} },
  } = useForm<UpdateJobSchema>({
    resolver: zodResolver(updateJobSchema),
    defaultValues: useMemo(
      () => ({
        ...pick(props.job, FormValues),
        duration: durationFormatter.format(props.job.duration),
      }),
      [props.job]
    ),
  });

  const auth = useAuth();

  useEffect(() => {
    reset({
      ...pick(props.job, FormValues),
      duration: durationFormatter.format(props.job.duration),
    });
  }, [props.job, reset]);

  const handleClose = () => {
    props.onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: UpdateJobSchema) => {
    handleClose();

    // Remove the description if the worksite doesn't require it
    if (!selectedWorksite.isDescriptionRequired) {
      data.description = "";
    }

    props.onConfirm(data);
  };

  const selectedWorksiteId = Number(watch("worksiteId"));
  useEffect(() => {
    if (selectedWorksiteId) {
      setSelectedWorksiteIndex(
        props.worksites.findIndex(
          (worksite) => worksite.id === selectedWorksiteId
        )
      );
    }
  }, [selectedWorksiteId, props.worksites]);

  return (
    <Dialog
      open={props.open}
      title="Modifier l'enregistrement"
      onClose={handleClose}
      onCancel={handleCancel}
      onSubmit={handleSubmit(onSubmit)}
      cancelText="Annuler"
      confirmText="Modifier"
      form
    >
      <FormControl margin="normal" fullWidth>
        <InputLabel id="worksite-label">Chantier</InputLabel>
        <Select
          native
          label="Chantier"
          labelId="worksite-label"
          id="worksite"
          fullWidth
          defaultValue={defaultValues.worksiteId}
          inputProps={register("worksiteId", {
            required: true,
            // This is required as we use the selected worksite id to find the
            // worksite object. If we do not set this attribute react-hook-form
            // returns a string
            valueAsNumber: true,
          })}
          error={!!errors.worksiteId}
        >
          {props.worksites.map((worksite) => (
            <option key={worksite.id} value={worksite.id}>
              {getWorksiteName(worksite)}
            </option>
          ))}
        </Select>
      </FormControl>
      {selectedWorksite?.isDescriptionRequired && (
        <TextField
          id="description"
          margin="normal"
          label="Description"
          fullWidth
          variant="outlined"
          defaultValue={defaultValues.description}
          inputProps={register("description", { required: true })}
          error={!!errors.description}
          helperText={errors.description?.message ?? ""}
        />
      )}
      <Box display="flex" gap={2}>
        {auth.user.role === Role.ADMIN && (
          <FormControlLabel
            control={
              <Checkbox
                checked={exactDuration}
                onChange={(e) => setExactDuration(e.target.checked)}
              />
            }
            label="Éxacte"
          />
        )}
        {exactDuration ? (
          <Controller
            name="duration"
            control={control}
            render={({ field }) => (
              <TextField
                label="Durée"
                margin="normal"
                id="duration"
                {...field}
                fullWidth
                defaultValue={defaultValues.duration}
                InputProps={{
                  inputComponent: DurationInput as any,
                }}
                variant="outlined"
                error={!!errors.duration}
                helperText={errors.duration?.message ?? ""}
              />
            )}
          />
        ) : (
          <FormControl margin="normal" fullWidth>
            <InputLabel id="duration-label" disableAnimation>
              Durée
            </InputLabel>
            <DurationSelect
              label="Durée"
              labelId="duration-label"
              id="duration"
              fullWidth
              defaultValue={defaultValues.duration}
              inputProps={register("duration", { required: true })}
              error={!!errors.duration}
            />
          </FormControl>
        )}
      </Box>
    </Dialog>
  );
}
