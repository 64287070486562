import { useEffect } from "react";
import { FormControl, InputLabel, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { pick } from "src/lib/util";
import { WorksiteStateSelect } from "src/components/WorksiteStateSelect";
import {
  updateWorksiteSchema,
  UpdateWorksiteSchema,
} from "src/schema/worksite.schema";
import { Worksite } from "src/lib/worksite";
import { Dialog } from "src/ui/Dialog";

type EditWorksiteDialogProps = {
  worksite: Worksite;
  open: boolean;
  onClose: () => void;
  onConfirm: (worksite: UpdateWorksiteSchema) => void;
};

const FormValues = [
  "id",
  "number",
  "name",
  "address",
  "state",
  "location",
] as const;

export function EditWorksiteDialog(props: EditWorksiteDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateWorksiteSchema>({
    resolver: zodResolver(updateWorksiteSchema),
    defaultValues: {
      ...pick(props.worksite, FormValues),
      targetTime: props.worksite.targetTime
        ? props.worksite.targetTime
        : undefined,
    },
  });

  useEffect(() => {
    reset(pick(props.worksite, FormValues));
  }, [reset, props.worksite]);

  const handleClose = () => {
    props.onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: UpdateWorksiteSchema) => {
    handleClose();

    props.onConfirm(data);
  };

  return (
    <Dialog
      open={props.open}
      title="Modifier un chantier"
      cancelText="Annuler"
      confirmText="Modifier"
      onClose={handleClose}
      onCancel={handleCancel}
      onSubmit={handleSubmit(onSubmit)}
      form
    >
      <TextField
        id="number"
        autoFocus
        margin="dense"
        label="N°"
        fullWidth
        variant="outlined"
        defaultValue={props.worksite.number}
        inputProps={register("number", { required: true })}
        error={!!errors.number}
        helperText={errors.number?.message ?? " "}
      />
      <TextField
        id="name"
        margin="dense"
        label="Nom"
        fullWidth
        variant="outlined"
        defaultValue={props.worksite.name}
        inputProps={register("name", { required: true })}
        error={!!errors.name}
        helperText={errors.name?.message ?? " "}
      />
      <TextField
        id="address"
        margin="dense"
        label="Adresse"
        fullWidth
        variant="outlined"
        defaultValue={props.worksite.address}
        inputProps={register("address", { required: true })}
        error={!!errors.address}
        helperText={errors.address?.message ?? " "}
      />
      <TextField
        id="location"
        margin="dense"
        label="Lieu"
        fullWidth
        variant="outlined"
        defaultValue={props.worksite.location}
        inputProps={register("location", { required: true })}
        error={!!errors.location}
        helperText={errors.location?.message ?? " "}
      />
      <TextField
        id="targetTime"
        margin="dense"
        label="Heures cibles"
        fullWidth
        variant="outlined"
        defaultValue={
          props.worksite.targetTime ? props.worksite.targetTime : undefined
        }
        inputProps={{
          ...register("targetTime", { valueAsNumber: true }),
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        error={!!errors.targetTime}
        helperText={errors.targetTime?.message ?? " "}
      />
      <FormControl margin="dense" fullWidth>
        <InputLabel id="state-label">État</InputLabel>
        <WorksiteStateSelect
          label="État"
          id="state"
          labelId="state-label"
          defaultValue={props.worksite.state}
          inputProps={register("state", { required: true })}
          error={!!errors.state}
        />
      </FormControl>
    </Dialog>
  );
}
