import { Box } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";

import { colors, FileWithShares, isFolder } from "src/lib/file";
import { Menu, MenuProps } from "src/ui/Menu";
import { Role } from "src/lib/user";
import { useAuth } from "src/contexts/AuthContext";

type UpdateFileParams = Partial<{
  id: number;
  name: string;
  color: string;
  shares: Array<{ id: number }>;
}>;

type PartialFile = Pick<
  FileWithShares,
  | "id"
  | "name"
  | "mimeType"
  | "url"
  | "color"
  | "createdAt"
  | "shares"
  | "isFolder"
>;

type EditFileMenuProps = {
  file: PartialFile;
  onSelect: (file: PartialFile) => void;
  onUpdate: (data?: UpdateFileParams) => void;
  onDownload: () => void;
  onUpdateShares: () => void;
  onUpdateColor: (id: number, color: string) => void;
  onDelete: () => void;
};

export function EditFileMenu(props: MenuProps<EditFileMenuProps>) {
  const auth = useAuth();

  const isAdmin = auth.user.role === Role.ADMIN;
  const canChangeColor = isAdmin && isFolder(props.file);
  const canDownload = !isFolder(props.file);

  return (
    <Menu
      menuProps={props.menuProps}
      items={[
        isAdmin
          ? {
              label: "Renommer",
              icon: <EditIcon />,
              onClick: props.onUpdate,
            }
          : null,
        canDownload
          ? {
              label: "Télécharger",
              icon: <DownloadIcon />,
              onClick: props.onDownload,
            }
          : null,
        isAdmin
          ? {
              label: "Droits d'accès",
              icon: <ShareIcon />,
              onClick: props.onUpdateShares,
            }
          : null,
        canChangeColor
          ? {
              label: "Changer la couleur",
              icon: <ColorLensIcon />,
              items: colors.map((color) => ({
                render: (menuProps) => (
                  <Box
                    sx={{
                      bgcolor: color,
                      cursor: "pointer",
                      display: "inline-flex",
                      p: 1.75,
                      mx: 0.5,
                      borderRadius: 1.5,
                    }}
                    onClick={() => {
                      props.onUpdateColor(props.file.id, color);
                      menuProps.onClose();
                    }}
                  />
                ),
                onClick: () => props.onUpdateColor(props.file.id, color),
              })),
            }
          : null,
        isAdmin
          ? {
              label: "Supprimer",
              icon: <DeleteIcon />,
              onClick: props.onDelete,
            }
          : null,
      ]}
    />
  );
}
