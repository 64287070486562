import { FormControl, InputLabel, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { WorksiteStateSelect } from "src/components/WorksiteStateSelect";
import {
  createWorksiteSchema,
  CreateWorksiteSchema,
} from "src/schema/worksite.schema";
import { WorksiteState, WorksiteType } from "src/lib/worksite";
import { Dialog } from "src/ui/Dialog";

type CreateWorksiteDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (worksite: CreateWorksiteSchema) => void;
};

export function CreateWorksiteDialog(props: CreateWorksiteDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateWorksiteSchema>({
    resolver: zodResolver(createWorksiteSchema),
    defaultValues: {
      type: WorksiteType.WORKSITE,
      state: WorksiteState.PLANNED,
    },
  });

  const handleClose = () => {
    props.onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: CreateWorksiteSchema) => {
    handleClose();

    props.onConfirm(data);
  };

  return (
    <Dialog
      open={props.open}
      title="Créer un chantier"
      cancelText="Annuler"
      confirmText="Créer"
      onClose={props.onClose}
      onCancel={handleCancel}
      onSubmit={handleSubmit(onSubmit)}
      form
    >
      <TextField
        id="number"
        autoFocus
        margin="dense"
        label="N°"
        fullWidth
        variant="outlined"
        inputProps={register("number", { required: true })}
        error={!!errors.number}
        helperText={errors.number?.message ?? " "}
      />
      <TextField
        id="name"
        margin="dense"
        label="Nom"
        fullWidth
        variant="outlined"
        inputProps={register("name", { required: true })}
        error={!!errors.name}
        helperText={errors.name?.message ?? " "}
      />
      <TextField
        id="address"
        margin="dense"
        label="Adresse"
        fullWidth
        variant="outlined"
        inputProps={register("address", { required: true })}
        error={!!errors.name}
        helperText={errors.name?.message ?? " "}
      />
      <TextField
        id="location"
        margin="dense"
        label="Lieu"
        fullWidth
        variant="outlined"
        inputProps={register("location", { required: true })}
        error={!!errors.location}
        helperText={errors.location?.message ?? " "}
      />
      <TextField
        id="targetTime"
        margin="dense"
        label="Heures cibles"
        fullWidth
        variant="outlined"
        defaultValue={0}
        inputProps={{
          ...register("targetTime", { valueAsNumber: true }),
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        error={!!errors.targetTime}
        helperText={errors.targetTime?.message ?? " "}
      />
      <FormControl margin="normal" fullWidth>
        <InputLabel id="state-label">État</InputLabel>
        <WorksiteStateSelect
          label="État"
          id="state"
          labelId="state-label"
          inputProps={register("state", { required: true })}
          error={!!errors.state}
        />
      </FormControl>
    </Dialog>
  );
}
