import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import camelcaseKeys from "camelcase-keys";

import { User } from "src/lib/user";

export const UserQueryKey = "user";

type UseUserOptions = Omit<
  UseQueryOptions<User, unknown, User, QueryKey>,
  "queryKey" | "queryFn"
>;

export async function getUser(id: number) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user.");
    }

    const data = await response.json();
    return camelcaseKeys(data.user, { deep: true });
  } catch (e) {
    return null;
  }
}

export function useUser(id: number, options: UseUserOptions = {}) {
  return useQuery<User>([UserQueryKey, id], () => getUser(id), {
    suspense: true,
    ...options,
  });
}
