import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { FilesQueryKey } from "./useFiles";

type UseCreateLinkOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

type UseCreateLinkParams = {
  name: string;
  url: string;
  parentId: number | null;
};

export function useCreateLink(options: UseCreateLinkOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UseCreateLinkParams) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/files`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(snakecaseKeys(params, { deep: true })),
      });

      if (!response.ok) {
        throw new Error("Failed to create link.");
      }

      const data = await response.json();
      return camelcaseKeys(data.file, { deep: true });
    },
    {
      ...options,
      onSuccess: (_, params) => {
        // invalidate /files/:parentId query
        queryClient.invalidateQueries([FilesQueryKey, params.parentId]);

        if (!params.parentId) {
          // invalidate /files query
          queryClient.invalidateQueries(FilesQueryKey);
        }

        options.onSuccess?.();
      },
    }
  );
}
