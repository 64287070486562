import { Suspense, useRef, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Network } from "@capacitor/network";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FolderIcon from "@mui/icons-material/Folder";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import HandyManOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import {
  SwipeableResponsiveDrawer,
  SwipRespDrawerActions,
} from "src/components/SwipeableResponsiveDrawer";
import { useToast } from "src/contexts/ToastContext";
import { Role } from "src/lib/user";
import { useAuth } from "src/contexts/AuthContext";
import { Loading } from "src/ui/Loading";
import { ActionsSpeedDial } from "src/components/ActionsSpeedDial";
import { WeekSelector } from "src/components/WeekSelector";
import { GlobalSearch } from "./GlobalSearch";
import { FirstPage, LastPage, ViewStream, ViewWeek } from "@mui/icons-material";
import { theme } from "src/lib/theme";
import { useViewModeStore } from "src/stores/useViewModeStore";

const drawerWidth = 260;
const shrankDrawerWidth = 78;

const menuItems = [
  {
    href: "/employees",
    label: "Employés",
    icon: <PeopleIcon />,
    role: [Role.ADMIN],
  },
  {
    href: "/files",
    label: "Fichiers",
    icon: <FolderIcon />,
    role: [Role.ADMIN, Role.USER],
  },
  {
    href: "/tracking-dashboard",
    label: "Suivi des heures",
    icon: <TimerOutlinedIcon />,
    role: [Role.ADMIN],
  },
  {
    href: "/time-tracking",
    label: "Suivi des heures",
    icon: <TimerOutlinedIcon />,
    role: [Role.USER],
  },
  {
    href: "/worksites",
    label: "Chantiers",
    icon: <HomeWorkOutlinedIcon />,
    role: [Role.ADMIN],
  },
  {
    href: "/job-types",
    label: "Types de tâches",
    icon: <HandyManOutlinedIcon />,
    role: [Role.ADMIN],
  },
  {
    href: "/planning",
    label: "Planning",
    icon: <CalendarMonthIcon />,
    role: [Role.ADMIN],
  },
];

export function DefaultLayout() {
  const [title, setTitle] = useState("");
  const [displayWeekSelector, setDisplayWeekSelector] = useState(false);

  const [isShrank, setIsShrank] = useState(
    localStorage.getItem("drawer.shrank") === "true"
  );

  const viewModeStore = useViewModeStore();

  const location = useLocation();

  const drawerActionRef = useRef<SwipRespDrawerActions>({});
  const { showToast } = useToast();
  const isDrawerFixed = useMediaQuery(theme.breakpoints.up("sm"));

  const auth = useAuth();
  const navigate = useNavigate();

  Network.addListener("networkStatusChange", (status) => {
    if (status.connected) {
      showToast({
        message: "Vous êtes de nouveau en ligne",
        severity: "success",
      });
    } else {
      showToast({
        message:
          "Vous êtes hors ligne, les modifications ne seront pas sauvegardées",
        severity: "warning",
        duration: 0,
      });
    }
  });

  // FIXME: Hide the offline toast when the user is back online
  const isOnline = /* useOnline({ onOnline, onOffline }) */ true;

  const currentDrawerWidth =
    isShrank && isDrawerFixed ? shrankDrawerWidth : drawerWidth;

  return (
    <Box
      sx={{ display: "flex", flex: 1, overflow: "hidden" }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar
          disableGutters
          sx={{
            pl: 2.5,
            pr: 0.5,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="Ouvrir le menu"
            edge="start"
            onClick={() => drawerActionRef.current?.setIsOpen?.(true)}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          {isDrawerFixed &&
            (isShrank ? (
              <IconButton
                color="inherit"
                aria-label="Ouvrir le menu"
                edge="start"
                onClick={() => {
                  setIsShrank(false);
                  localStorage.setItem("drawer.shrank", "false");
                }}
              >
                <LastPage />
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                aria-label="Réduire le menu"
                edge="start"
                onClick={() => {
                  setIsShrank(true);
                  localStorage.setItem("drawer.shrank", "true");
                }}
              >
                <FirstPage />
              </IconButton>
            ))}

          <Box
            display="flex"
            sx={{ flexGrow: 1, alignItems: "center", gap: 6 }}
          >
            <Typography
              noWrap
              component="div"
              sx={{
                ml: 2,
                width: "210px",
                fontWeight: "medium",
                fontSize: "1.1rem",
                display: { xs: "none", sm: "block" },
              }}
            >
              {title}
            </Typography>
            {auth.user.role === Role.ADMIN && <GlobalSearch />}
          </Box>
          {displayWeekSelector && <WeekSelector />}

          {location.pathname === "/time-tracking" ? (
            viewModeStore.viewMode === "week" ? (
              <IconButton
                color="inherit"
                aria-label="Vue jour"
                edge="start"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={() => {
                  viewModeStore.setViewMode("day");
                  localStorage.setItem("view.mode", "day");
                }}
              >
                <span style={{ fontSize: "1rem" }}>Jour</span>
                <ViewStream />
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                aria-label="Vue semaine"
                edge="start"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={() => {
                  viewModeStore.setViewMode("week");
                  localStorage.setItem("view.mode", "week");
                }}
              >
                <span style={{ fontSize: "1rem" }}>Semaine</span>
                <ViewWeek />
              </IconButton>
            )
          ) : null}
        </Toolbar>
      </AppBar>
      <SwipeableResponsiveDrawer
        width={currentDrawerWidth}
        actionRef={drawerActionRef}
        isFixed={isDrawerFixed}
      >
        <Toolbar>
          <IconButton
            onClick={() => drawerActionRef.current?.setIsOpen?.(false)}
            sx={{ mr: 2 }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexFlow: "column nowrap",
            overflowY: "auto",
            overflowX: "hidden",
            p: 1.5,
          }}
        >
          <List sx={{ flex: 1 }}>
            {menuItems
              .filter((item) => item.role.includes(auth.user.role))
              .map((item) => (
                <ListItem
                  key={item.href}
                  sx={{
                    mb: 1,
                  }}
                >
                  <NavLink to={item.href} style={{ display: "contents" }}>
                    {({ isActive }) => (
                      <ListItemButton
                        onClick={() =>
                          drawerActionRef.current?.setIsOpen?.(false)
                        }
                        sx={{ py: 1.25 }}
                        selected={isActive}
                        title={item.label}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        {(!isShrank || !isDrawerFixed) && (
                          <ListItemText sx={{ my: 0 }} primary={item.label} />
                        )}
                      </ListItemButton>
                    )}
                  </NavLink>
                </ListItem>
              ))}
          </List>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                title="Déconnexion"
                onClick={() => {
                  auth.logout();
                  navigate("/login");
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                {!isShrank && "Déconnexion"}
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </SwipeableResponsiveDrawer>
      {auth.user.role === "ADMIN" && <ActionsSpeedDial disabled={!isOnline} />}
      <Box
        component="main"
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          flexGrow: 1,
          overflow: "hidden",
          width: {
            xs: "100%",
            sm: `calc(100% - ${currentDrawerWidth}px)`,
          },
          paddingTop: "env(safe-area-inset-top)",
        }}
      >
        <Toolbar />
        <Suspense fallback={<Loading />}>
          <Outlet context={{ title, setTitle, setDisplayWeekSelector }} />
        </Suspense>
      </Box>
    </Box>
  );
}
