import { useState } from "react";
import { endOfMonth, getISOWeek, getYear, startOfMonth } from "date-fns";
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import TodayIcon from "@mui/icons-material/Today";

import { useSelectedWeekStore } from "src/stores/useSelectedWeekStore";
import { SelectWorkWeekDialog } from "./SelectWorkWeekDialog";
import { theme } from "src/lib/theme";
import { useRouteState } from "src/stores/useRouteState";
import { useWorksiteJobs } from "src/queries/useWorksiteJobs";
import { useUserJobs } from "src/queries/useUserJobs";
import { useLocation } from "react-router-dom";

export function WeekSelector() {
  const today = new Date();

  const [workWeekOpen, setWorkWeekOpen] = useState(false);
  /**
   * The month holds the currently displayed month in the SelectWorkWeekDialog.
   * It is used to fetch the jobs for the current month that will be displayed
   * in the calendar.
   */
  const [month, setMonth] = useState(today);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const selectedWeekState = useSelectedWeekStore();
  const { resource } = useRouteState();
  const location = useLocation();

  const { data: worksitejobs = [] } = useWorksiteJobs(
    {
      start: startOfMonth(month),
      end: endOfMonth(month),
      worksiteId: resource?.id ?? -1,
    },
    {
      enabled: resource?.type === "worksite",
    }
  );

  const { data: userJobs = [] } = useUserJobs(
    {
      start: startOfMonth(month),
      end: endOfMonth(month),
      userId: resource?.id ?? -1,
    },
    {
      enabled: resource?.type === "user",
    }
  );

  const jobs = resource
    ? resource.type === "worksite"
      ? worksitejobs
      : userJobs
    : [];

  const isNextWeekDisabled =
    selectedWeekState.currentWeekIndex >= getISOWeek(today) &&
    selectedWeekState.currentYear >= getYear(today) &&
    !location.pathname.startsWith("/planning");

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="end" flex="1">
        {isDesktop ? (
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={selectedWeekState.resetCurrentWeekIndex}
          >
            {"Aujourd'hui"}
          </Button>
        ) : (
          <IconButton
            color="inherit"
            onClick={selectedWeekState.resetCurrentWeekIndex}
          >
            <TodayIcon />
          </IconButton>
        )}
        <Box display="flex" sx={{ ml: 1 }}>
          <IconButton color="inherit" onClick={selectedWeekState.previousWeek}>
            <ChevronLeft />
          </IconButton>
          <ButtonBase
            sx={{ p: 2, fontSize: 15, borderRadius: "50%", width: "3.5em" }}
            onClick={() => setWorkWeekOpen(true)}
          >
            {selectedWeekState.currentWeekIndex}
          </ButtonBase>
          <IconButton
            color="inherit"
            onClick={selectedWeekState.nextWeek}
            sx={{ "&:disabled": { color: "inherit" } }}
            disabled={isNextWeekDisabled}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <SelectWorkWeekDialog
        open={workWeekOpen}
        weekIndex={selectedWeekState.currentWeekIndex}
        year={selectedWeekState.currentYear}
        badges={jobs.map((job) => job.date)}
        onMonthChange={setMonth}
        onClose={() => setWorkWeekOpen(false)}
        onConfirm={(weekIndex, year) => {
          selectedWeekState.setCurrentWeekIndex(weekIndex);
          selectedWeekState.setCurrentYear(year);
        }}
      />
    </>
  );
}
