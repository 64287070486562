import { useState } from "react";
import { Box, TableContainer } from "@mui/material";

import { ConfirmDialog } from "src/components/ConfirmDialog";
import { CreateUserDialog } from "src/components/CreateUserDialog";
import { CreateUserMenu } from "src/components/CreateUserMenu";
import { EditUserDialog } from "src/components/EditUserDialog";
import { EditUserPasswordDialog } from "src/components/EditUserPasswordDialog";
import { EmployeeTable } from "src/components/EmployeeTable";
import { useContextMenu } from "src/hooks/useContextMenu";
import { User } from "src/lib/user";
import { useTitle } from "src/hooks/useTitle";
import { useUsers } from "src/queries/useUsers";
import { useUpdateUser } from "src/queries/useUpdateUser";
import { useToast } from "src/contexts/ToastContext";
import { useEnableUser } from "src/queries/useEnableUser";
import { useDisableUser } from "src/queries/useDisableUser";
import { useCreateUser } from "src/queries/useCreateUser";
import { useDisplayWeekSelector } from "src/hooks/useDisplayWeekSelector";
import { Head } from "src/ui/Head";

type EmployeesRouteProps = {
  title: string;
};

export function EmployeesRoute({ title }: EmployeesRouteProps) {
  useTitle(title);
  useDisplayWeekSelector(false);

  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [updateUserOpen, setUpdateUserOpen] = useState(false);
  const [updateUserPasswordOpen, setUpdateUserPasswordOpen] = useState(false);
  const [disableUserOpen, setDisableUserOpen] = useState(false);
  const [activateUserOpen, setActivateUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const contextMenu = useContextMenu();
  const { showToast } = useToast();

  const { data: users } = useUsers();

  const createUserMutation = useCreateUser({
    onSuccess() {
      showToast("L'employé a bien été créé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateUserMutation = useUpdateUser({
    onSuccess() {
      showToast("L'employé a bien été mis à jour");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const enableUserMutation = useEnableUser({
    onSuccess() {
      showToast("Le compte a bien été réactivé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const disableUserMutation = useDisableUser({
    onSuccess() {
      showToast("Le compte a bien été désactivé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  if (!users) return null;

  return (
    <>
      <Head title="Employés" />
      <Box
        onContextMenu={contextMenu.onContextMenu}
        sx={{ display: "flex", flex: 1 }}
      >
        <TableContainer sx={{ height: "calc(100vh - 64px)" }}>
          <EmployeeTable
            users={users}
            onUpdate={() => setUpdateUserOpen(true)}
            onUpdatePassword={() => setUpdateUserPasswordOpen(true)}
            onDisable={() => setDisableUserOpen(true)}
            onActivate={() => setActivateUserOpen(true)}
            onSelect={setSelectedUser}
            selectedUser={selectedUser}
          />
        </TableContainer>
        <CreateUserMenu
          menuProps={contextMenu.getMenuProps()}
          onCreate={() => setCreateUserOpen(true)}
        />
        <>
          <CreateUserDialog
            open={createUserOpen}
            onClose={() => setCreateUserOpen(false)}
            onConfirm={createUserMutation.mutate}
          />
          {selectedUser && updateUserOpen && (
            <EditUserDialog
              open={updateUserOpen}
              user={selectedUser}
              onClose={() => setUpdateUserOpen(false)}
              onConfirm={updateUserMutation.mutate}
            />
          )}
          {selectedUser && updateUserPasswordOpen && (
            <EditUserPasswordDialog
              open={updateUserPasswordOpen}
              user={selectedUser}
              onClose={() => setUpdateUserPasswordOpen(false)}
              onConfirm={updateUserMutation.mutate}
            />
          )}
          {selectedUser && (
            <ConfirmDialog
              title="Désactiver le compte"
              content="Êtes-vous sûr de vouloir désactiver ce compte ?"
              open={disableUserOpen}
              onClose={() => setDisableUserOpen(false)}
              onConfirm={() => disableUserMutation.mutate(selectedUser.id)}
            />
          )}
          {selectedUser && (
            <ConfirmDialog
              title="Réactiver le compte"
              content="Êtes-vous sûr de vouloir réactiver ce compte ?"
              open={activateUserOpen}
              onClose={() => setActivateUserOpen(false)}
              onConfirm={() => enableUserMutation.mutate(selectedUser.id)}
            />
          )}
        </>
      </Box>
    </>
  );
}
