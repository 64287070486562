import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { CheckCircle, Warning } from "@mui/icons-material";

type Toast = {
  id?: string;
  message: string;
  severity?: "success" | "error" | "info" | "warning";
  duration?: number;
  progress?: number;
};

type ToastContextProps = {
  showToast: (toast: Toast | string) => {
    update: (params: { progress: number }) => void;
    hide: () => void;
  };
};

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export default ToastContext;

export function useToast() {
  return useContext(ToastContext);
}

const DEFAULT_TOAST_DURATION = 10000;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

// eslint-disable-next-line @typescript-eslint/ban-types
export function ToastContextProvider({ children }: PropsWithChildren<{}>) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const showToast = useCallback(
    (params: Omit<Toast, "id"> | string) => {
      let toast: Toast;

      const id = Math.random().toString(36).substring(7);

      if (typeof params === "string") {
        toast = { id, message: params, duration: DEFAULT_TOAST_DURATION };
      } else {
        toast = { id, duration: DEFAULT_TOAST_DURATION, ...params };
      }

      setToasts((toasts) => [...toasts, toast]);

      return {
        id,
        update: ({ progress }: { progress: number }) => {
          setToasts((toasts) =>
            toasts.map((toast) => {
              if (toast.id === id) {
                return { ...toast, progress };
              }

              return toast;
            })
          );
        },
        hide: () => {
          setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
        },
      };
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toasts.map((toast, index) => (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{
            "& .MuiSnackbarContent-root": {
              width: "100%",
            },
          }}
          key={toast.id}
          open={true}
          ClickAwayListenerProps={
            // Do not hide the toast when the user clicks away on a
            // non-disappearing toast
            toast.duration === 0
              ? undefined
              : {
                  onClickAway: noop,
                }
          }
          message={
            <>
              <Box display="flex" gap={1} alignItems="center">
                {toast.severity === "warning" && <Warning sx={{ mr: 2 }} />}
                {toast.severity === "success" && <CheckCircle sx={{ mr: 2 }} />}
                <Typography variant="body2">{toast.message}</Typography>
              </Box>
              {toast.progress && (
                <LinearProgress
                  variant="determinate"
                  value={toast.progress}
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  }}
                />
              )}
            </>
          }
          autoHideDuration={
            toast.duration === 0 ? null : toast.duration ?? 10000
          }
          onClose={() => setToasts((toasts) => toasts.slice(index + 1))}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToasts((toasts) => toasts.slice(index + 1))}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      ))}
    </ToastContext.Provider>
  );
}
