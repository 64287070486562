import { useState } from "react";
import { Link, SxProps } from "@mui/material";

type BreadcrumbProps = {
  to: string;
  sx?: SxProps;
  onDrop?: (event: React.DragEvent<HTMLElement>) => void;
  children: React.ReactNode;
};

export function Breadcrumb(props: BreadcrumbProps) {
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  return (
    <Link
      onDragEnter={() => setIsDraggedOver(true)}
      onDragLeave={() => setIsDraggedOver(false)}
      underline="none"
      href={props.to}
      sx={{
        display: "block",
        px: 1.5,
        py: 0.75,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 500,
        borderRadius: 1.5,
        backgroundColor: isDraggedOver ? "secondary.light" : "transparent",
        ":hover": {
          backgroundColor: "action.hover",
        },
        ...props.sx,
      }}
      onDrop={(e) => {
        setIsDraggedOver(false);

        if (props.onDrop) {
          props.onDrop(e);
        }
      }}
    >
      {props.children}
    </Link>
  );
}
