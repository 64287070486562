import { Menu, MenuProps } from "src/ui/Menu";
import { Worksite } from "src/lib/worksite";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

type EditWorksiteMenuProps = {
  worksite: Worksite;
  onSelect: (worksite: Worksite) => void;
  onUpdate: () => void;
  onDelete: () => void;
};

export function EditWorksiteMenu(props: MenuProps<EditWorksiteMenuProps>) {
  return (
    <Menu
      menuProps={props.menuProps}
      items={[
        {
          label: "Modifier",
          icon: <EditIcon />,
          onClick: () => {
            props.onSelect(props.worksite);
            props.onUpdate();
          },
        },
        {
          label: "Supprimer",
          icon: <DeleteIcon />,
          onClick: () => {
            props.onSelect(props.worksite);
            props.onDelete();
          },
        },
      ]}
    />
  );
}
