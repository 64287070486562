import { ReactNode } from "react";
import { Breadcrumbs } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

type FilesBreadcrumbsProps = {
  children?: ReactNode;
};

export function FilesBreadcrumbs(props: FilesBreadcrumbsProps) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      sx={{
        display: "flex",
        mb: 1,
      }}
    >
      {props.children}
    </Breadcrumbs>
  );
}
