import { forwardRef, useRef } from "react";
import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

type SearchBarProps = {
  setSearchQuery: (query: string) => void;
  searchQuery: string;
  onFocus: () => void;
  onBlur: () => void;
};

export const SearchBar = forwardRef<HTMLFormElement, SearchBarProps>(
  (props, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const clearSearch = () => {
      props.setSearchQuery("");
      inputRef.current?.blur();
      props.onBlur();
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === "Escape") {
        clearSearch();
      }
    };

    return (
      <Paper
        ref={ref}
        component="form"
        onSubmit={(e) => e.preventDefault()}
        onKeyDown={handleKeyDown}
        sx={{
          p: "2px 8px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <IconButton type="button" sx={{ p: "10px" }} aria-label="Rechercher">
          <SearchIcon />
        </IconButton>
        <InputBase
          inputRef={inputRef}
          sx={{ ml: 1.5, flex: 1 }}
          placeholder="Rechercher…"
          inputProps={{ "aria-label": "rechercher" }}
          onChange={(e) => props.setSearchQuery(e.target.value)}
          value={props.searchQuery}
          onFocus={props.onFocus}
        />
        <IconButton
          type="button"
          sx={{ visibility: props.searchQuery === "" ? "hidden" : "visible" }}
          aria-label="Effacer"
          onClick={() => clearSearch()}
        >
          <CloseIcon />
        </IconButton>
      </Paper>
    );
  }
);
