import { useMutation, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";

import { WorksiteJobsQueryKey } from "./useWorksiteJobs";
import { UserJobsQueryKey } from "./useUserJobs";
import { JobsQueryKey } from "./useJobs";

type UseDeleteJobOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

export function useDeleteJob(options: UseDeleteJobOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation<UseDeleteJobOptions, Error, number>(
    async (id: number) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/jobs/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete job.");
      }

      const data = await response.json();
      return camelcaseKeys(data.job, { deep: true });
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(WorksiteJobsQueryKey);
        queryClient.invalidateQueries(UserJobsQueryKey);
        queryClient.invalidateQueries(JobsQueryKey);
        options.onSuccess?.();
      },
    }
  );
}
