import { z } from "zod";

import { WorksiteState, WorksiteType } from "src/lib/worksite";

const worksiteStatesSchema = z.enum([
  WorksiteState.PLANNED,
  WorksiteState.IN_PROGRESS,
  WorksiteState.COMPLETED,
]);

const worksiteTypesSchema = z.enum([
  WorksiteType.WORKSITE,
  WorksiteType.ACTIVITY,
]);

export const createWorksiteSchema = z.object({
  name: z.string().min(1, "Saisir un nom"),
  number: z.string().min(1, "Saisir un numéro"),
  address: z.string().min(1, "Saisir une adresse").optional(),
  location: z.string().min(1, "Saisir un nom de lieu").optional(),
  targetTime: z
    .any()
    .transform((value) => value as number)
    .optional(),
  isDescriptionRequired: z.boolean().optional(),
  isVisible: z.boolean().optional(),
  type: worksiteTypesSchema,
  state: worksiteStatesSchema.optional(),
});

export const updateWorksiteSchema = z
  .object({ id: z.number() })
  .merge(createWorksiteSchema.partial());

export type CreateWorksiteSchema = z.infer<typeof createWorksiteSchema>;
export type UpdateWorksiteSchema = z.infer<typeof updateWorksiteSchema>;
