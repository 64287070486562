import { Job } from "./job";

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  disabledAt: string | null;
  role: keyof typeof Role;
};

export type UserWithJobs = User & {
  jobs: Job[];
};

export type UserRole = keyof typeof Role;

export const Role = {
  ADMIN: "ADMIN",
  USER: "USER",
} as const;
