import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { readEml } from "eml-parse-js";
import { format } from "date-fns/format";
import MsgReader from "@kenjiuno/msgreader";

import CloseIcon from "@mui/icons-material/Close";
import { File, getContentsUrl } from "src/lib/file";
import { Box, useMediaQuery } from "@mui/system";
import { EmailAddress } from "eml-parse-js/dist/interface";
import { formatDistanceToNow } from "date-fns";
import { frCH } from "date-fns/locale";
import parseHeaders from "parse-headers";
import { Loading } from "src/ui/Loading";
import { theme } from "src/lib/theme";

type MailFileDialogProps = {
  file: File;
  open: boolean;
  onClose: () => void;
};

type Email = {
  from: string;
  to: string;
  date?: string;
  subject: string;
  html: string;
};

async function getEml(file: File) {
  const response = await fetch(getContentsUrl(file));
  const text = await response.text();

  return new Promise((resolve, reject) => {
    readEml(text, (err, data) => {
      if (err) {
        reject(err);
        return;
      }

      if (!data) {
        reject(new Error("invalid data"));
        return;
      }

      const email: Email = {
        from: data.from ? addresses(data.from) : "",
        to: data.to ? addresses(data.to) : "",
        date: data.date as string,
        subject: data.subject,
        html: data.html ?? "",
      };

      resolve(email);
    });
  });
}

async function getMsg(file: File) {
  const response = await fetch(getContentsUrl(file));
  const arrayBuffer = await response.arrayBuffer();

  const data = new MsgReader(arrayBuffer).getFileData();
  const html = data.body?.replaceAll("\r\n\r\n", "\n");
  const headers = parseHeaders(data.headers ?? "");

  const email: Email = {
    from: (headers["from"] as string).replaceAll(
      /=\?iso-8859-1\?Q\?.*\?=/g,
      ""
    ),
    to: headers["to"] as string,
    date: headers["date"] as string,
    subject: data.subject ?? "",
    html: html ?? "",
  };

  return email;
}

function addresses(addresses: EmailAddress | EmailAddress[]) {
  if (Array.isArray(addresses)) {
    return addresses.map(({ name, email }) => `${name} <${email}>`).join("; ");
  }
  return `${addresses.name} <${addresses.email}>`;
}

export function MailFileDialog(props: MailFileDialogProps) {
  const [email, setEmail] = useState<Email>();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (props.file.mimeType === "message/rfc822") {
      getEml(props.file).then((eml: any) => setEmail(eml));
    } else if (props.file.mimeType === "application/vnd.ms-outlook") {
      getMsg(props.file).then((msg: any) => setEmail(msg));
    }
  }, [props.file]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullScreen={isMobile}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          md: { height: "calc(100% - 100px)" },
          sx: isMobile
            ? {
                borderRadius: 0,
              }
            : {},
        }}
        scroll="paper"
      >
        {!email && (
          <Box
            position="fixed"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <Loading />
          </Box>
        )}

        {email && (
          <>
            <DialogTitle
              sx={{
                boxShadow:
                  "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                bgcolor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              {email?.subject}
              <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                  position: "absolute",
                  right: 12,
                  top: 12,
                  color: "primary.contrastText",
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  p: 2,

                  minHeight: "400px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection={{ sm: "column", md: "row" }}
                  justifyContent="space-between"
                  gap={1}
                >
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" gap={1}>
                      <div>De:</div>
                      <div>{email?.from}</div>
                    </Box>
                    <Box display="flex" gap={1}>
                      <div>À:</div>
                      <div>{email?.to}</div>
                    </Box>
                  </Box>

                  <Box fontSize={12}>
                    <div>
                      {email?.date
                        ? `${format(email.date, "'Le' dd MMM yyyy, HH:mm", {
                            locale: frCH,
                          })} (${formatDistanceToNow(email.date, {
                            locale: frCH,
                          })})`
                        : ""}
                    </div>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    textWrap: "wrap",
                    width: "100%",
                  }}
                  dangerouslySetInnerHTML={{ __html: email?.html ?? "" }}
                />
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
}
