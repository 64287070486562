import { ButtonBase, TableCell, TableRow } from "@mui/material";
import { isFuture } from "date-fns";
import { useNavigate } from "react-router-dom";

import { useContextMenu } from "src/hooks/useContextMenu";
import { durationFormatter } from "src/lib/intl";

type EmployeeTimeTrackingRowProps = {
  user: {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    username: string;
    jobs: {
      date: Date;
      total: number;
    }[];
    total: number;
  };
  onCellClick: (data: { userId: number; date: Date }) => void;
};

export function EmployeeTimeTrackingRow({
  user,
  onCellClick,
}: EmployeeTimeTrackingRowProps) {
  const contextMenu = useContextMenu();
  const navigate = useNavigate();

  return (
    <TableRow onContextMenu={contextMenu.onContextMenu}>
      <TableCell
        onClick={() => navigate(`/employees/${user.id}`)}
        sx={{
          cursor: "pointer",
          ":hover": {
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        {user.fullName}
      </TableCell>
      {user.jobs.map((job) => {
        return (
          <TableCell
            onClick={() => {
              if (isFuture(job.date)) {
                return;
              }

              onCellClick({
                userId: user.id,
                date: job.date,
              });
            }}
            key={job.date.getDate()}
            sx={{
              p: 0,
              cursor: "pointer",
              ":hover": {
                backgroundColor: (theme) => theme.palette.secondary.main,
              },
            }}
          >
            <ButtonBase
              sx={{
                p: 2,
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              {durationFormatter.format(job.total)}
            </ButtonBase>
          </TableCell>
        );
      })}
      <TableCell>{durationFormatter.format(user.total)}</TableCell>
    </TableRow>
  );
}
