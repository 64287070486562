import { useState } from "react";

import { EmployeeTimeTrackingTable } from "src/components/EmployeeTimeTrackingTable/EmployeeTimeTrackingTable";
import { SingleDayJobDialog } from "src/components/SingleDayJobDialog";
import { useDisplayWeekSelector } from "src/hooks/useDisplayWeekSelector";
import { useTitle } from "src/hooks/useTitle";
import { useWeekDays } from "src/lib/date";
import { useJobs } from "src/queries/useJobs";
import { useSelectedWeekStore } from "src/stores/useSelectedWeekStore";
import { useCreateJob } from "src/queries/useCreateJob";
import { useUpdateJob } from "src/queries/useUpdateJob";
import { useDeleteJob } from "src/queries/useDeleteJob";
import { useToast } from "src/contexts/ToastContext";
import { Head } from "src/ui/Head";
import { TableContainer } from "@mui/material";

type TrackingDashboardRouteProps = {
  title: string;
};

export function TrackingDashboardRoute({ title }: TrackingDashboardRouteProps) {
  useTitle(title);
  useDisplayWeekSelector(true);

  const [clickedCellData, setClickedCellData] = useState<{
    userId: number;
    date: Date;
  }>();

  const { showToast } = useToast();

  const [currentWeekIndex, currentYear] = useSelectedWeekStore((state) => [
    state.currentWeekIndex,
    state.currentYear,
  ]);

  const { startDate, endDate, days } = useWeekDays({
    weekIndex: currentWeekIndex,
    year: currentYear,
  });

  const { data: users } = useJobs({
    start: startDate,
    end: endDate,
    disabled: false,
  });

  const createJobMutation = useCreateJob({
    onSuccess() {
      showToast("L'enregistrement a bien été créé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateJobMutation = useUpdateJob({
    onSuccess() {
      showToast("L'enregistrement a bien été modifié");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const deleteJobMutation = useDeleteJob({
    onSuccess() {
      showToast("L'enregistrement a bien été supprimé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  if (!users) {
    return null;
  }

  return (
    <>
      <Head title={title} />
      <TableContainer sx={{ height: "calc(100vh - 64px)" }}>
        <EmployeeTimeTrackingTable
          days={days}
          users={users}
          onCellClick={setClickedCellData}
        />
      </TableContainer>
      {clickedCellData && (
        <SingleDayJobDialog
          open={!!clickedCellData}
          onClose={() => setClickedCellData(undefined)}
          date={clickedCellData.date}
          user={users!.find((user) => user.id === clickedCellData?.userId)!}
          onCreateJob={createJobMutation.mutate}
          onUpdateJob={updateJobMutation.mutate}
          onDeleteJob={deleteJobMutation.mutate}
        />
      )}
    </>
  );
}
