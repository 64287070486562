import { MouseEventHandler } from "react";
import { Box, Typography } from "@mui/material";
import { useAuth } from "src/contexts/AuthContext";
import { Role } from "src/lib/user";

import { ReactComponent as NoDocuments } from "src/svgs/no-documents.svg";

type EmptyContentProps = {
  onContextMenu: MouseEventHandler<HTMLElement>;
};

export function EmptyContent(props: EmptyContentProps) {
  const auth = useAuth();

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexFlow: "column nowrap",
        alignItems: "center",
        justifyContent: "center",
        userSelect: "none",
      }}
      onContextMenu={props.onContextMenu}
    >
      <NoDocuments style={{ marginTop: -64 }} />
      <Typography variant="h6" color="text.primary">
        Aucun fichier
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        textAlign="center"
        marginTop={1}
        sx={{ maxWidth: 300 }}
      >
        {auth.user.role === Role.ADMIN
          ? "Glissez un document ou créer un nouveau dossier"
          : "Le dossier est vide"}
      </Typography>
    </Box>
  );
}
