import { useRouteError } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Button, Link, Typography } from "@mui/material";

export function ErrorRoute() {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <>
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography component="h1" variant="h4">
          Une erreur est survenue
        </Typography>
        <Typography variant="subtitle1" mt={2} mb={2}>
          {error.status}: {error.statusText}
        </Typography>
        <Button component={Link} href="/files" variant="contained">
          Retour à l'accueil
        </Button>
        <Box
          display="flex"
          justifyContent="space-between"
          mt={2}
          p={2}
          minWidth={"250px"}
        >
          <Typography variant="subtitle2" color="text.secondary">
            {new Date().getFullYear()} | Bocherens Toiture SA
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
