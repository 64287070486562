import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Dialog } from "src/ui/Dialog";

type CreateFolderDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (file: z.infer<typeof CreateFolderSchema>) => void;
};

const CreateFolderSchema = z.object({
  name: z.string().min(1, "Saisir un nom"),
});

export function CreateFolderDialog({
  open,
  onClose,
  onConfirm,
}: CreateFolderDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof CreateFolderSchema>>({
    resolver: zodResolver(CreateFolderSchema),
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: z.infer<typeof CreateFolderSchema>) => {
    handleClose();

    onConfirm(data);
  };

  return (
    <Dialog
      open={open}
      title="Créer un dossier"
      cancelText="Annuler"
      confirmText="Créer"
      onClose={handleClose}
      onCancel={handleCancel}
      onSubmit={handleSubmit(onSubmit)}
      form
    >
      <TextField
        id="name"
        autoFocus
        label="Nom"
        fullWidth
        margin="normal"
        variant="outlined"
        inputProps={register("name", { required: true })}
        error={!!errors.name}
        helperText={errors.name?.message}
      />
    </Dialog>
  );
}
