import { useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";

import { Worksite } from "src/lib/worksite";

export const WorksiteQueryKey = "worksite";

async function getWorksite(id: number) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/worksites/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch worksite.");
  }

  const data = await response.json();
  return camelcaseKeys(data.worksite, { deep: true });
}

export function useWorksite(id: number) {
  return useQuery<Worksite>([WorksiteQueryKey, id], () => getWorksite(id), {
    suspense: true,
    keepPreviousData: true,
  });
}
