import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { File, isFolder, isLink } from "src/lib/file";

type PartialFile = Pick<File, "id" | "name" | "mimeType" | "url" | "color">;

type EditFileDialogProps = {
  file: PartialFile;
  onConfirm: (file: z.infer<typeof EditFileSchema>) => void;
  onClose: () => void;
  open: boolean;
};

const EditFileSchema = z.object({
  id: z.number(),
  name: z.string().min(1, "Saisir un nom"),
  url: z.string().url("Saisir une URL valide").nullable(),
});

export function EditFileDialog(props: EditFileDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof EditFileSchema>>({
    resolver: zodResolver(EditFileSchema),
    defaultValues: {
      id: props.file.id,
      name: props.file.name,
      url: props.file.url,
    },
  });

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: z.infer<typeof EditFileSchema>) => {
    handleClose();

    props.onConfirm(data);
  };

  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{`Modifier le ${
          isFolder(props.file) ? "dossier" : "fichier"
        }`}</DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            autoFocus
            label="Nom"
            fullWidth
            margin="normal"
            variant="outlined"
            defaultValue={props.file.name}
            inputProps={register("name", { required: true })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          {isLink(props.file) && (
            <TextField
              id="url"
              autoFocus
              label="URL"
              fullWidth
              margin="normal"
              variant="outlined"
              defaultValue={props.file.url}
              inputProps={register("url", { required: true })}
              error={!!errors.url}
              helperText={errors.url?.message}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
          <Button color="primary" type="submit" autoFocus>
            Modifier
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
