import React, { MouseEvent, TouchEvent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
} from "@mui/material";

import { FileRow } from "./FileRow";
import { Column, Order } from "src/lib/table";
import { EditFileMenu } from "src/components/EditFileMenu";
import { useContextMenu } from "src/hooks/useContextMenu";
import { theme } from "src/lib/theme";
import { FileWithShares } from "src/lib/file";
import { TableFilterPopover } from "src/ui/TableFilterPopover";

export interface Data {
  mimeType: string | null;
  name: string;
  createdAt: Date;
}

export type Filter = Record<keyof Data, Record<string, boolean>>;

type FileTableProps = {
  files: FileWithShares[];
  filter: Filter;
  setFilter: (filter: Filter) => void;
  selectedFiles: FileWithShares[];
  order: Order;
  setOrder: (order: Order) => void;
  orderBy: keyof Data;
  setOrderBy: (orderBy: keyof Data) => void;
  onRowClick: (file: FileWithShares, e: MouseEvent<HTMLElement>) => void;
  onRowDrop: (sourceIds: number[], destId: number) => void;
  onRowDragStart: (file: FileWithShares) => void;
  onRowDoubleClick: (
    file: FileWithShares,
    e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>
  ) => void;
  onSelect: (file: FileWithShares) => void;
  onDownload: () => void;
  onUpdate: () => void;
  onUpdateShares: () => void;
  onUpdateColor: (id: number, color: string) => void;
  onDelete: () => void;
};

const titleByMimeType: Record<string, string> = {
  "": "Dossier",
  "application/pdf": "PDF",
  "image/jpeg": "Image JPEG",
  "image/jpg": "Image JPEG",
  "image/png": "Image PNG",
  "image/gif": "Image GIF",
  "image/heic": "Image HEIC",
  "text/plain": "Fichier texte",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "Document Word",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "Document Excel",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "Document PowerPoint",
  "application/vnd.ms-excel": "Document Excel",
  "application/vnd.ms-powerpoint": "Document PowerPoint",
  "application/msword": "Document Word",
  "video/mp4": "Vidéo",
  "message/rfc822": "Email",
  "application/zip": "Archive ZIP",
  url: "Lien",
};

export const FileTable = React.forwardRef<HTMLDivElement, FileTableProps>(
  (props, ref) => {
    const contextMenu = useContextMenu();

    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const mimeTypes = new Set(props.files.map((file) => file.mimeType ?? ""));

    // NOTE: Workaround as an url file has no mimeType
    if (props.files.find((file) => !!file.url)) {
      mimeTypes.add("url");
    }

    const columns: Column<Data>[] = [
      {
        id: "name",
        label: "Nom",
        minWidth: 250,
        filters: [
          {
            type: "checkbox",
            options: Array.from(mimeTypes)
              .map((mimeType) => ({
                label: titleByMimeType[mimeType] ?? mimeType,
                value: mimeType,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)),
          },
        ],
      },
      { id: "createdAt", label: "Date de création", isDesktop: true },
    ];

    const createSortHandler = (property: keyof Data) => () => {
      const isAsc = props.orderBy === property && props.order === "asc";

      props.setOrder(isAsc ? "desc" : "asc");
      props.setOrderBy(property);
    };

    const files = props.files.filter(
      (file) =>
        !Object.values(props.filter.name).find((v) => v) ||
        (props.filter.name[file.mimeType ?? ""] && !file.url) ||
        (props.filter.name["url"] && !!file.url)
    );

    return (
      <Table
        stickyHeader
        sx={{ height: "min-content" }}
        component="div"
        ref={ref}
      >
        <TableHead component="div">
          <TableRow component="div">
            {columns
              .filter((column) =>
                typeof column.isDesktop !== "undefined"
                  ? column.isDesktop === isDesktop
                  : true
              )
              .map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={
                    props.orderBy === column.id ? props.order : false
                  }
                  style={{ minWidth: column.minWidth }}
                  component="div"
                >
                  <TableSortLabel
                    active={props.orderBy === column.id}
                    direction={
                      props.orderBy === column.id ? props.order : "asc"
                    }
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                  {column.filters &&
                    column.filters.map((columnFilter, i) => (
                      <TableFilterPopover
                        key={i}
                        options={columnFilter.options}
                        filter={props.filter[column.id]}
                        onChange={(value, checked) =>
                          props.setFilter({
                            ...props.filter,
                            [column.id]: {
                              ...props.filter[column.id],
                              [value]: checked,
                            },
                          })
                        }
                      />
                    ))}
                </TableCell>
              ))}
            <TableCell component="div"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody component="div">
          {files.map((file) => (
            <FileRow
              key={file.id}
              file={file}
              selected={props.selectedFiles.includes(file)}
              onDragStart={props.onRowDragStart}
              onDrop={(destId) =>
                props.onRowDrop(
                  props.selectedFiles.map((f) => f.id),
                  destId
                )
              }
              onDoubleClick={(e) => props.onRowDoubleClick(file, e)}
              onMoreClick={(e) => {
                props.onRowClick(file, e);
                contextMenu.onContextMenu(e);
              }}
              onContextMenu={(e) => {
                props.onRowClick(file, e);
                contextMenu.onContextMenu(e);
              }}
              onClick={(e) => {
                props.onRowClick(file, e);
              }}
            />
          ))}
        </TableBody>
        {props.selectedFiles && props.selectedFiles[0] && (
          <EditFileMenu
            menuProps={contextMenu.getMenuProps()}
            file={props.selectedFiles[0]}
            onSelect={props.onSelect}
            onUpdate={props.onUpdate}
            onDownload={props.onDownload}
            onUpdateShares={props.onUpdateShares}
            onUpdateColor={props.onUpdateColor}
            onDelete={props.onDelete}
          />
        )}
      </Table>
    );
  }
);
